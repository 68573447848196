// Libs
import React from 'react';
import Modal from 'react-bootstrap/Modal';

// Services & Helpers
import DiaryService from 'services/DiaryService';
import ThermalPrinterService from 'services/ThermalPrinterService';
import CustomerService from 'services/CustomerService';
import GlobalStateService from 'services/GlobalStateService';
import EvoService from 'services/EvoService';
import OnlineBookingService from 'services/OnlineBookingService';
import BootboxHelper from 'helpers/BootboxHelper';
import TextHelpers from 'helpers/TextHelpers';

// Components
import Loader from 'components/reusable/Loader';
import PaymentPanel from './checkout/PaymentPanel';
import PosTerminalPanel from './checkout/PosTerminalPanel';

//--------------------------------------------------------------------------------------

export default class CancelApptModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            step: 1
        };
        this.paymentPanelRef = React.createRef();
    }

    show(opts) {
        return new Promise((resolve, reject) => {
            this.resolve = resolve;
            console.log(opts);
            this.setState({
                isLoading: true,
                isOpen: true,
                showCancelPanel: false,
                appointmentID: opts.appointmentID,
                type: opts.type,
                refundOption: null,
                reason: null
            }, () => {
                this.load();
            });
        });
    }

    async load() {
        this.clientInfo = GlobalStateService.getValue('clientInfo');
        const appointment = await DiaryService.getCancellationInfo(this.state.appointmentID);
        let savedCard = appointment.customer ? await CustomerService.getSavedCard(appointment.customer.customerID) : null;
        if (savedCard) {
            savedCard = savedCard.paymentProvider == this.clientInfo.paymentProvider ? savedCard : null;
        }
        const customerInfo = appointment.customer ? await CustomerService.getSummary(appointment.customer.customerID) : null;
        const paymentDevices = await OnlineBookingService.listPaymentDevices() || {};
        let defaultPaymentDevice = paymentDevices.find(pd => pd.isDefault);
        var paymentDeviceId = defaultPaymentDevice ? defaultPaymentDevice.paymentDeviceId : null;

        if (appointment) {
            let paymentMethods = await DiaryService.loadPaymentMethodsFor('', true);
            const cardPM = paymentMethods.find(pm => pm.code == 'Card');
            const paymentViaOnlineBooking = appointment.appointmentPayments.some(ap => ap.paymentMethodName == 'Online Booking');
            const paymentViaSavedCard = appointment.appointmentPayments.some(ap => ap.paymentMethodName == 'Card' && (ap.paymentProvider == 'evo' || ap.paymentProvider == 'stripe'));
            const paymentViaCard = appointment.appointmentPayments.some(ap => ap.paymentMethodName == 'Card' && !ap.paymentProvider);
            const paymentViaCardMachine = appointment.appointmentPayments.some(ap => ap.paymentMethodName == 'Card' && ap.paymentProvider == 'posDevice');
            this.setState({
                appointment,
                paymentViaOnlineBooking,
                paymentViaSavedCard,
                paymentViaCard,
                paymentViaCardMachine,
                paymentMethods,
                cardPM,
                savedCard,
                customerInfo,
                paymentDevices,
                paymentDeviceId,
                isLoading: false,
                salonPaymentProvider: this.clientInfo.paymentProvider
            });
        } else {
            this.setState({ isOpen: false });
            this.resolve();
        }
    }

    async confirm() {
        const { appointment, reason, refundOption, type, salonPaymentProvider, paymentDeviceId } = this.state;

        // Validate
        if (appointment.amountPaid > 0 && !refundOption) {
            BootboxHelper.alert('Please select a refund option');
            return;
        }
        if (!reason) {
            var typeText;
            switch (type) {
                case 'cancel':
                    typeText = 'cancellation';
                    break;
                case 'delete':
                    typeText = 'deletion';
                    break;
                default:
                    typeText = 'deletion';
                    break;
            }
            BootboxHelper.alert('Please specify a reason for ' + typeText);
            return;
        }

        if (refundOption == "Card machine" && salonPaymentProvider == 'evo') {
            if (!paymentDeviceId) {
                BootboxHelper.alert('Please select a payment device');
                return;
            } else {
                await this.checkForPosCardTransaction();
            }
        }
        else {
            await this.save();
        }
    }

    async save() {
        const { appointmentID, reason, refundOption, type, refundTransactionID } = this.state;

        // Cancel and close
        this.setState({ isLoading: true });
        try {
            const { openCashDrawer } = await DiaryService.cancelOrDeleteAppointment(appointmentID, reason, refundOption == 'Card machine' ? 'Card' : refundOption, type, refundTransactionID);
            if (openCashDrawer) {
                ThermalPrinterService.openCashDrawers(false);
            }
            this.setState({ isOpen: false });
            this.resolve();
        } catch (error) {
            BootboxHelper.alert(error);
            this.setState({ isLoading: false });
        }
    }

    async checkForPosCardTransaction() {
        const {
            appointment,
            paymentDeviceId
        } = this.state;

        let response = await DiaryService.refundTransaction(appointment.amountPaid * 100, paymentDeviceId);
        BootboxHelper.alert('Please wait while we process the payment <br/> Please do not refresh or close this screen');
        this.setState({ isLoading: true });
        this.checkTransactionCompleteInterval = setInterval(() => {
            this.checkForTransactionDetails(response.TransactionId, response.PaymentDeviceId, true);
        }, 5000);

        this.checkIfPosPendingTransactionTimeout = setTimeout(function () {
            BootboxHelper.alert('Pos is busy.Please complete the transaction on the terminal');
        }, 50000);

        this.checkIfPosIncompleteTransactionTimeout = setTimeout(() => {
            this.checkForIncompleteTransaction(response.TransactionId, response.PaymentDeviceId);
        }, 100000);
    }

    async savePayment() {
        const { appointment } = this.state;

        if (this.state.step == 3) {
            if (appointment.paymentDeviceId <= 0) {
                BootboxHelper.alert('Please make sure to select the payment device.');
                return;
            }

            let payments = appointment.newPayments.filter(np => np.paymentMethodID == this.state.cardPM.paymentMethodID && !np.id && !np.isSavedCard);
            let totalAmount = 0;

            payments.map((ap) => {
                totalAmount += ap.amount;
            });

            let response = await DiaryService.sendToCardMachine(totalAmount, appointment);
            BootboxHelper.alert('Please wait while we process your payment <br/> Please do not refresh or close this screen');

            this.setState({ isLoading: true });
            this.checkTransactionCompleteInterval = setInterval(() => {
                this.checkForTransactionDetails(response.TransactionId, response.PaymentDeviceId, false);
            }, 1000);

            //check if anything needs to be completed on Pos Device
            this.checkIfPosPendingTransactionTimeout = setTimeout(function () {
                BootboxHelper.alert('Pos is busy. Please complete the transaction on the terminal');
            }, 50000);

            this.checkIfPosIncompleteTransactionTimeout = setTimeout(() => {
                this.checkForIncompleteTransaction(response.TransactionId, response.PaymentDeviceId);
            }, 100000);

        }

        const pendingCardPayments = appointment.newPayments.find(ap => ap.paymentMethodID == this.state.cardPM.paymentMethodID
            && !ap.id && !ap.isSavedCard && !ap.txnReference);
        if (pendingCardPayments && appointment.paymentProvider == 'evo') {
            this.setState({
                step: 3
            })
        }
        else {
            this.setState({ isLoading: true });
            try {
                const { openCashDrawer } = await DiaryService.saveAppointmentCancelPayment(appointment);
                if (openCashDrawer) {
                    ThermalPrinterService.openCashDrawers(false);
                }
                await this.confirm();
                this.setState({ isLoading: false });
            } catch (error) {
                BootboxHelper.alert(error);
                this.setState({ isLoading: false });
            }
        }
    }

    canSave() {
        return PaymentPanel.canContinue(this.state.appointment);
    }

    canContinue() {
        if (this.state.appointment.paymentDeviceId > 0)
            return true;
        else
            return false;
    }

    updateButtonText() {
        return PaymentPanel.updateButtonText(this.state.appointment, 'Complete payment', this.state.paymentMethods);
    }

    setAppointmentPayments(setNewPayment) {
        const { appointment } = this.state;
        appointment.apptAmount = appointment.apptAmount > 0 ? appointment.apptAmount : 10;
        appointment.newPayments = appointment.newPayments || [];
        appointment.paymentDate = new Date();
        appointment.amountToPay = appointment.apptAmount - appointment.amountPaid - appointment.cancelAmountPaid;
        appointment.total = appointment.apptAmount;
        this.clientInfo = GlobalStateService.getValue('clientInfo');
        appointment.paymentProvider = this.clientInfo.paymentProvider;
        if (setNewPayment)
            appointment.newPayments = [];
        appointment.newPayments.push({
            amount: appointment.apptAmount - appointment.amountPaid - appointment.cancelAmountPaid
        });
        DiaryService.updateBalance(appointment);
        this.setState({
            appointment
        });
    }

    async showPanel(disablePayment) {
        if (!disablePayment) {
            //let paymentMethods = await DiaryService.loadPaymentMethodsFor('', true);
            //const cardPM = paymentMethods.find(pm => pm.code == 'Card');
            let { cardPM, paymentMethods } = this.state;
            paymentMethods = paymentMethods.filter(pm => pm.code != 'ApplePay' && pm.code != 'OnlineBookings');
            const { customerInfo } = this.state;

            this.setAppointmentPayments();
            if (customerInfo.accountBalance <= 0) {
                const index = paymentMethods.findIndex(pm => pm.code == 'Account');
                const accountIndex = paymentMethods.findIndex(pm => pm.code == 'OnAccount');

                paymentMethods.splice(index, 1);
                paymentMethods.splice(accountIndex, 1);
            }
            if (customerInfo.loyaltyPointsValue <= 0) {
                const index = paymentMethods.findIndex(pm => pm.code == 'LoyaltyPoints');
                paymentMethods.splice(index, 1);
            }

            this.setState({
                showCancelPanel: true,
                paymentMethods,
                cardPM,
                step: 2
            })
        }
    }

    checkValidPayments(newPayments) {
        var totalAmount = 0;
        var paymentAmount = 0;
        var appointment = { ...this.state.appointment };
        appointment.apptAmount = appointment.apptAmount > 0 ? appointment.apptAmount : 10;
        var cancellationAmount = appointment.apptAmount - appointment.amountPaid - appointment.cancelAmountPaid;
        if (newPayments) {
            newPayments.forEach(np => {
                totalAmount += np.amount;
                const lpPM = this.state.paymentMethods.find(pm => pm.code == 'LoyaltyPoints');
                const accountPM = this.state.paymentMethods.find(pm => pm.code == 'Account');

                if (lpPM && lpPM.paymentMethodID == np.paymentMethodID && this.state.customerInfo.loyaltyPointsValue < np.amount) {
                    BootboxHelper.alert('Please note the amount is higher than the loyal points total.');
                    np.amount = this.state.customerInfo.loyaltyPointsValue;
                }

                if (accountPM && accountPM.paymentMethodID == np.paymentMethodID && this.state.customerInfo.accountBalance < np.amount) {
                    BootboxHelper.alert('Please note the amount is higher than the account balance total.');
                    np.amount = this.state.customerInfo.accountBalance;
                }

                if (totalAmount > cancellationAmount) {
                    BootboxHelper.alert('Please note the amount is higher than the appointment total.');
                    np.amount = cancellationAmount - paymentAmount;
                }
                paymentAmount += np.amount;
                appointment.amountToPay = paymentAmount;
            })
        }
        this.setState({
            appointment
        });
    }

    async checkForTransactionDetails(transactionId, paymentDeviceId, issueRefund) {
        const transactionResponse = await DiaryService.getTransactionDetails(transactionId, paymentDeviceId);
        if (transactionResponse == EvoService.RESPONSE_CANCELLED || transactionResponse == EvoService.RESPONSE_REFUSED || transactionResponse == EvoService.RESPONSE_SUCCESS) {
            clearTimeout(this.checkIfPosPendingTransactionTimeout);
            clearInterval(this.checkTransactionCompleteInterval);
            clearTimeout(this.checkIfPosIncompleteTransactionTimeout);
            if (transactionResponse == EvoService.RESPONSE_SUCCESS) {

                if (!issueRefund) {
                    let newPayments = this.state.appointment.newPayments.filter(np => np.paymentMethodID == this.state.cardPM.paymentMethodID && !np.id && !np.isSavedCard);
                    newPayments.forEach(np => {
                        if (!np.txnReference) {
                            np.txnReference = transactionId
                        }
                    });

                    this.setState({
                        step: 4,
                        showCancelPanel: false
                    });

                    this.savePayment();
                    this.state.appointment.newPayments = [];
                    this.state.appointment.amountToPay = 0;
                }
                else {
                    this.setState({
                        refundTransactionID: transactionId
                    });
                    this.save();
                }
            }
            else if (transactionResponse == EvoService.RESPONSE_CANCELLED) {
                BootboxHelper.alert("Card transaction cancelled.Please try again.");
                if (!issueRefund) {
                    this.setState({
                        step: 2,
                        isLoading: false
                    });
                }
                else {
                    this.setState({
                        isLoading: false
                    });
                }
            }
            else if (transactionResponse == EvoService.RESPONSE_REFUSED) {
                BootboxHelper.alert("Card payment declined. Please try with other payment method.");
                if (!issueRefund) {
                    this.setState({
                        step: 2,
                        isLoading: false
                    });
                }
                else {
                    this.setState({
                        isLoading: false
                    });
                }
            }
        }
    }

    async checkForIncompleteTransaction(transactionId, paymentDeviceId) {
        const transactionResponse = await DiaryService.getTransactionDetails(transactionId, paymentDeviceId);
        if (transactionResponse == 'Fetching Details') {
            BootboxHelper.alert('Please make sure to select the correct payment device.');
            clearTimeout(this.checkIfPosPendingTransactionTimeout);
            clearInterval(this.checkTransactionCompleteInterval);
            clearTimeout(this.checkIfPosIncompleteTransactionTimeout);
        }
    }
    //---------------------------------------------------------------------

    render() {
        const {
            isOpen,
            isLoading,
            appointment,
            type
        } = this.state;

        if (!isOpen) {
            return null;
        }

        var typeTitle, typeConfirmButton, typeCancelButton;
        switch (type) {
            case 'noShow':
                typeTitle = 'Are you sure you\'d like to mark this appointment as a \'no show\' ? ';
                typeConfirmButton = 'Yes, confirm';
                typeCancelButton = 'Actually, no thanks';
                break;
            case 'cancel':
                typeTitle = 'You\'re about to cancel this appointment for ';
                typeConfirmButton = 'Yes, please cancel';
                typeCancelButton = 'Actually, don\'t cancel';
                break;
            case 'delete':
                const title = 'You\'re about to delete this appointment ';
                typeTitle = title.concat(appointment?.customer ? 'for' : '');
                typeConfirmButton = 'Yes, please delete';
                typeCancelButton = 'Actually, don\'t delete ';
                break;
            default:
                typeTitle = 'You\'re about to delete this appointment for ';
                typeConfirmButton = 'Yes, please delete';
                typeCancelButton = 'Actually, don\'t delete ';
                break;
        }

        return (
            <Modal
                show
                className="cancel-appt-modal"
                onHide={e => this.setState({ isOpen: false })}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {type == 'noShow' && <>{typeTitle}</>}
                        {!isLoading && type != 'noShow' && appointment.customer && <>
                            {typeTitle} {TextHelpers.formatName(appointment.customer.firstName, appointment.customer.lastName)}
                        </>}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.renderInner()}
                </Modal.Body>
                <Modal.Footer>
                    {!isLoading && <>
                        <button type="button" className="button button-secondary mr-auto" onClick={e => this.setState({ isOpen: false })}>
                            {typeCancelButton}
                        </button>
                        <button type="button" className={"button button-primary ml-auto " + (!this.canSave() ? '' : ' disabled')} onClick={e => this.confirm()}>
                            {typeConfirmButton}
                        </button>
                    </>}
                </Modal.Footer>
            </Modal>
        );
    }

    renderInner() {
        const {
            isLoading,
            reason,
            appointment,
            type,
            refundOption,
            paymentViaOnlineBooking,
            paymentViaSavedCard,
            paymentViaCard,
            salonPaymentProvider,
            paymentDevices,
            paymentDeviceId
        } = this.state;

        let { showCancelPanel } = this.state;
        if (isLoading) {
            return (<Loader />);
        }

        var typeText;
        switch (type) {
            case 'cancel':
                typeText = 'cancel';
                break;
            case 'delete':
                typeText = 'delete';
                break;
            default:
                typeText = 'delete';
                break;
        }

        let disablePayment = false;
        if (appointment.amountPaid > 0 && !refundOption) {
            disablePayment = true;
        }

        if (!reason) {
            disablePayment = true;
        }

        if (appointment.newPayments && appointment.newPayments.length == 0) {
            showCancelPanel = false;
        }
        return (<>

            {appointment.amountPaid > 0 && <>
                <div className="form-group">
                    <label>
                        The client has paid a <b>{TextHelpers.formatCurrencyNew(appointment.amountPaid, { includeSymbol: true })}</b> deposit towards this appointment. <br />
                        What would you like to do with it?
                    </label>
                </div>
                <div className="checkbox mb-1">
                    <label>
                        <input
                            className="me-2"
                            type="radio"
                            checked={refundOption == 'None'}
                            onChange={e => this.setState({ refundOption: 'None' })}
                        />{' '}
                        Do not Refund
                    </label>
                </div>
                {salonPaymentProvider == 'evo' &&
                    <div className="checkbox mb-1">
                        <label>
                            <input
                                className="me-2"
                                type="radio"
                                checked={refundOption == 'Card machine'}
                                onChange={e => this.setState({ refundOption: 'Card machine' })}
                            />{' '}
                            Refund by Card Machine
                        </label>
                    </div>
                }
                {(paymentViaCard) &&
                    <div className="checkbox mb-1">
                        <label>
                            <input
                                className="me-2"
                                type="radio"
                                checked={refundOption == 'Card'}
                                onChange={e => this.setState({ refundOption: 'Card' })}
                            />{' '}
                            Refund by Card
                        </label>
                    </div>
                }
                <div className="checkbox mb-1">
                    <label>
                        <input
                            className="me-2"
                            type="radio"
                            checked={refundOption == 'Cash'}
                            onChange={e => this.setState({ refundOption: 'Cash' })}
                        />{' '}
                        Refund by Cash
                    </label>
                </div>
                {(paymentViaOnlineBooking || paymentViaSavedCard) &&
                    <div className="checkbox mb-1">
                        <label>
                            <input
                                className="me-2"
                                type="radio"
                                checked={refundOption == 'OnlineBookings'}
                                onChange={e => this.setState({ refundOption: 'OnlineBookings' })}
                            />{' '}
                            Refund to Online Booking payment method
                        </label>
                    </div>
                }
                <div className="checkbox mb-3">
                    <label>
                        <input
                            className="me-2"
                            type="radio"
                            checked={refundOption == 'Account'}
                            onChange={e => this.setState({ refundOption: 'Account' })}
                        />{' '}
                        Add to Client's Account Balance
                    </label>
                </div>
            </>}

            {refundOption == 'Card machine' && <>
                {paymentDevices.length == 0 && <>
                    You have no payment devices set up - please add some in the settings area.
                </>}
                <div>
                    <div className="non-selectable">
                        <span className="service-list-name">
                            Payment Device
                        </span>
                        <span className="service-list-price">
                            <select
                                style={{ width: '100%' }}
                                value={paymentDeviceId}
                                onChange={e => this.setState({ paymentDeviceId: e.target.value })}
                            ><option value="">(Select...)</option>
                                {paymentDevices.map(pd =>
                                    <option key={pd.paymentDeviceId} value={pd.paymentDeviceId}>{pd.paymentDeviceName}</option>
                                )}
                            </select>
                        </span>
                    </div>
                </div>
            </>
            }


            <div className="form-group">
                <label>For future reference, please leave a short note as to why it's being {typeText}:</label>
                <textarea
                    autoFocus
                    value={reason || ''}
                    onChange={e => this.setState({ reason: e.target.value })}
                    rows={2}
                />
            </div>

            <div className="form-group">
                <label>In line with your Terms & Conditions, if a further payment amount is due to be taken, please click here
                    {!showCancelPanel && <button className={"button cancel-amount-button " + (disablePayment ? 'disabled' : '')} onClick={() => this.showPanel(disablePayment)}>
                        <span className="fa fa-plus" title="Cancellation amount"></span>
                    </button>}
                </label>
            </div>
            {showCancelPanel && !disablePayment && this.renderCancelPanel()}
        </>);
    }

    renderCancelPanel() {
        const { appointment, step, paymentMethods } = this.state;

        if (step == 2) {
            return (<>
                {appointment.newPayments && appointment.newPayments.length > 0 && <div className="non-selectable">
                    <PaymentPanel
                        ref={this.paymentPanelRef}
                        checkOut={appointment}
                        isCancelPayment={true}
                        cancelPaymentMethods={paymentMethods}
                        checkPayment={newPayments => this.checkValidPayments(newPayments)}
                        onCheckOutChanged={checkOut => this.setState({ appointment: checkOut })}
                        onChange={(name, value) => { }}
                    />
                    <button className={"button button-primary button-small cancel-payment-button" + (this.canSave() ? '' : ' disabled')} onClick={e => this.savePayment()}>
                        <i className="fas fa-check"></i>{' '}
                        {this.updateButtonText()}
                    </button>
                </div>
                }
            </>
            )
        }
        else if (step == 3) {
            return (<>
                <PosTerminalPanel
                    checkOut={appointment}
                    onChange={(name, value) => { }}
                    onCheckOutChanged={checkOut => this.setState({ appointment: checkOut })}
                />
                <button className={'button button-primary button-small cancel-payment-button ' + (this.canContinue() ? '' : 'disabled')} onClick={e => this.savePayment(false)}>
                    <i className="fas fa-check"></i>{' '}
                    Send to payment device
                </button>
            </>)
        }
    }
}
