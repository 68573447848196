// Libs
import React, { Component } from 'react';
import queryString from 'query-string';

// Services
import AccountService from 'services/AccountService';
import GlobalStateService from 'services/GlobalStateService';
import BootboxHelper from 'helpers/BootboxHelper';
import SalonService from 'services/SalonService';

// Components
import Alert from 'components/reusable/Alert';
import Loader from 'components/reusable/Loader';

// Images
import logoImage from 'images/logo-pink.png';
import logoKevinMurphyImage from 'images/theme/kevin-murphy/logo-kevin-murphy.png';

//-------------------------------------------------------------------------------------------------------------------

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            error: null,
            salonPassword: '',
            groupPassword: '',
            pin: '',
            salonCode: (this.props.salonCode == 'group' ? '' : this.props.salonCode)
        };

        const qs = queryString.parse(window.location.search);
        this.state.userEmail = qs.email || '';

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const theme = await SalonService.getTheme(this.props.salonCode);
        this.setState({ theme });
        window.applyTheme(theme);

        setTimeout(() => {
            const loginPanelEl = document.querySelector('.login-panel');
            if (loginPanelEl) {
                loginPanelEl.classList.add('show');
            }
        }, 500);
    }

    //--------------------------------------------------------------------------------------------------------------------

    async logInSalon() {
        this.setState({ isLoading: true });

        try {
            await AccountService.logIn({
                salonPassword: this.state.salonPassword
            });
            await this.props.onLogIn();
            this.setState({
                error: null,
                isLoading: false
            });
        } catch (error) {
            this.setState({
                isLoading: false,
                error
            }, () => {
                this.shake();
            });
        }
    }

    
            //await AccountService.mobileLogIn({
            //    userEmail: this.state.userEmail,
            //    salonMobilePassword: this.state.salonMobilePassword
            //});
    async logInUser() {
        this.setState({ isLoading: true });
        try {
            await AccountService.logIn({
                pin: this.state.pin
            });
            await this.props.onLogIn();
            this.setState({
                error: null,
                isLoading: false
            });
        } catch (error) {
            this.setState({
                isLoading: false,
                error
            }, () => {
                this.shake();
            });
        }
    }

    async logInMobileUser() {
        const { userEmail, mobilePassword } = this.state;
        this.setState({ isLoading: true });
        try {
            await AccountService.mobileLogIn({
                userEmail,
                mobilePassword
            });
            await this.props.onLogIn();
            this.setState({
                error: null,
                isLoading: false
            });
        } catch (error) {
            this.setState({
                isLoading: false,
                error
            }, () => {
                this.shake();
            });
        }
    }
    async logInGroup() {
        this.setState({ isLoading: true });
        try {
            const { salonGroupCode } = this.props;
            let { salonCode } = this.state;
            const { defaultSalonCode } = await AccountService.logIn({
                groupPassword: this.state.groupPassword
            });
            if (!salonCode) {
                salonCode = defaultSalonCode;
            }
            await this.props.onLogIn();      

            // Redirect to correct salon
            if (salonGroupCode) {
                window.location.replace(`/group/${salonGroupCode}/${salonCode}`);
            }

            this.setState({
                error: null,
                isLoading: false
            });
        } catch (error) {
            this.setState({
                isLoading: false,
                error
            }, () => {
                this.shake();
            });
        }
    }

    shake() {
        const loginPanelEl = document.querySelector('.login-panel');
        if (loginPanelEl) {
            loginPanelEl.classList.add('error-shake');
            setTimeout(() => {
                loginPanelEl.classList.remove('error-shake');
            }, 200);
        }
    }

    async onSubmit(e) {
        const {
            loginDetails,
            salonCode,
            salonGroupCode,
            isMobile
        } = this.props;

        e.preventDefault();
        if (salonCode == 'group' && !salonGroupCode) {
            window.location.replace('/' + salonCode + '/' + this.state.salonGroupCode);
        } else if (salonCode) {
            if (isMobile) {
                await this.logInMobileUser();
            } else {
                const isLoggedInSalon = loginDetails && loginDetails.isLoggedInSalon;
                const isLoggedInGroup = loginDetails && loginDetails.isLoggedInGroup;
                if (isLoggedInSalon || isLoggedInGroup) {
                    await this.logInUser();
                } else if (salonGroupCode) {
                    await this.logInGroup();
                } else {
                    await this.logInSalon();
                }
            }
        } else {
            window.location.replace('/' + this.state.salonCode);
        }

        if (this.state.salonGroupCode) {
            GlobalStateService.setValue('salonGroupCode', this.state.salonGroupCode);
        }
    }

    async confirmLogOut() {
        const response = await BootboxHelper.confirm('Are you sure you want to log out?');
        if (response) {
            this.logOut();
        }
    }

    async logOut() {
        window.localStorage.removeItem('lastSalonCode');
        this.setState({ isLoading: true });
        await AccountService.logOutSalon();
        window.location.replace('/');
    }

    //--------------------------------------------------------------------------------------------------------------------

    render() {
        return (
            <div className="login-panel">

                {this.renderInner()}

            </div>
        );
    }

    renderInner() {
        const { isMobile } = this.props;
        const {
            isLoading,
            error,
            theme
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        const code = (theme && theme.code) || '';

        return (<>

            {this.renderLogo(code)}

            {error &&
                <Alert type="error">
                    {error}
                </Alert>
            }

            <form onSubmit={this.onSubmit}>

                {isMobile ? this.renderMobile() : this.renderDesktop()}

            </form>

        </>);
    }

    renderLogo(code) {
        if (code == 'kevin-murphy')
            return <img src={logoKevinMurphyImage} />;
        else
            return <img src={logoImage} />;
    }

    renderDesktop() {
        const {
            loginDetails,
            salonGroupCode
        } = this.props;
        const urlSalonCode = this.props.salonCode;
        const {
            salonCode,
            salonPassword,
            groupPassword,
            pin
        } = this.state;
        const isLoggedInSalon = loginDetails && loginDetails.isLoggedInSalon;

        // Group login
        if (salonCode == 'group' || salonGroupCode) {
            const isLoggedInGroup = loginDetails && loginDetails.isLoggedInGroup;

            return (<>

                {!salonGroupCode &&
                    <input placeholder="Salon group code" type="text" autoFocus={true} value={salonGroupCode} onChange={e => this.setState({ salonGroupCode: e.target.value })} />
                }

                {!!salonGroupCode && !isLoggedInGroup && <>
                    <input placeholder="Group password" type="password" autoFocus={true} value={groupPassword} onChange={e => this.setState({ groupPassword: e.target.value })} />
                    <input placeholder="Salon code (optional)" className="group-salon-code" type="text" value={salonCode || ''} onChange={e => this.setState({ salonCode: e.target.value })} />
                </>}

                {isLoggedInSalon && isLoggedInGroup &&
                    <input placeholder="Your PIN" autocomplete="new-password" type="password" autoFocus={true} value={pin} onChange={e => this.setState({ pin: e.target.value })} />
                }

                <button type="submit" className="button button-secondary">
                    {!!salonGroupCode ? 'Login' : 'Continue'}
                </button>

                {!!salonGroupCode && isLoggedInGroup &&
                    <div>
                        <button type="button" className="button button-secondary log-out-salon-button" onClick={e => this.confirmLogOut()}>
                            Log out of group
                        </button>
                    </div>
                }

            </>);
        }

        // Regular login
        return (<>
            
            {!urlSalonCode &&
                <input placeholder="Salon code" type="text" autoFocus={true} value={this.state.salonCode} onChange={e => this.setState({ salonCode: e.target.value })} />
            }

            {!!urlSalonCode && !isLoggedInSalon &&
                <input placeholder="Salon password" type="password" autoFocus={true} value={salonPassword} onChange={e => this.setState({ salonPassword: e.target.value })} />
            }

            {!!urlSalonCode && isLoggedInSalon &&
                <input placeholder="Your PIN" type="password" autoFocus={true} value={pin} onChange={e => this.setState({ pin: e.target.value })} />
            }

            <button type="submit" className="button button-secondary">
                {!!urlSalonCode ? 'Log in' : 'Continue'}
            </button>

            {!!urlSalonCode &&
                <div>

                    <button type="button" className="button button-secondary log-out-salon-button" onClick={e => this.confirmLogOut()}>
                        Log out of salon
                    </button>

                </div>
            }

        </>);
    }

    renderMobile() {
        const {
            userEmail,
            mobilePassword,
        } = this.state;

        return (<>
            <input className="mb-3" type="text" placeholder="Your email" autoFocus={!userEmail} value={userEmail || ''} onChange={e => this.setState({ userEmail: e.target.value })} />
            <input placeholder="Your password" type="password" value={mobilePassword || ''} autoFocus={!!userEmail} onChange={e => this.setState({ mobilePassword: e.target.value })} />

            <button type="submit" className="button button-secondary">
                Log in
            </button>
        </>);
    }
}

export default Login;