// Libs
import React from 'react';
import ReactTooltip from 'react-tooltip';
import 'moment/locale/en-gb';

// Services & Helpers
import SearchService        from 'services/SearchService';
import CustomerService      from 'services/CustomerService';
import UserService          from 'services/UserService';
import GlobalStateService   from 'services/GlobalStateService';
import FormHelper           from 'helpers/FormHelper';
import TextHelpers          from 'helpers/TextHelpers';
import BootboxHelper        from 'helpers/BootboxHelper';

// Components
import FloomlyComponent     from 'components/FloomlyComponent';
import Loader               from 'components/reusable/Loader';

//-------------------------------------------------------------------------------------------------------------------

class CustomerDetails extends FloomlyComponent {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            stylists: null
        }

        this.updateField = this.updateField.bind(this);
        this.listStylistOptions = this.listStylistOptions.bind(this);

        this.formHelper = new FormHelper({
            fields: {
                firstName: { label: 'First Name' },
                lastName: { label: 'Last Name' },
                mobileTel: {
                    label: 'Mobile',
                    getLabelExtras: (fieldInfo, fieldRef) =>
                        <div className="info-tooltip" style={{ display: 'inline' }} data-tip="Please note: for international numbers please add the relevant country code. For example if you are a UK salon with an Irish client, you would add +353 at the beginning and remove '0'.">
                            <div className="help-icon" />
                        </div>
                },
                homeTel: { label: 'Home Telephone Number' },
                workTel: { label: 'Work Telephone Number' },
                email: { label: 'Email' },
                gender: {
                    label: 'Gender',
                    type: 'single-select',
                    getOptions: () => {
                        return [
                            { id: '', name: '(Select)' },
                            { id: 'female', name: 'Female' },
                            { id: 'male', name: 'Male' },
                            { id: 'nonbinary', name: 'Non-Binary' }
                        ];
                    }
                },
                pronouns: { label: 'Pronouns' },
                dateOfBirth: {
                    type: 'date',
                    label: 'Date of birth'
                },
                regularStylistUserID: {
                    type: 'single-select',
                    label: 'Preferred stylist',
                    getOptions: this.listStylistOptions
                },
                backupStylistUserID: {
                    type: 'single-select',
                    label: 'Backup stylist',
                    getOptions: this.listStylistOptions
                },
                addressLine1: {
                    type: 'text',
                    entity: 'address',
                    field: 'line1',
                    label: 'Address Line 1'
                },
                addressLine2: {
                    type: 'text',
                    entity: 'address',
                    field: 'line2',
                    label: 'Address Line 2'
                },
                addressTown: {
                    type: 'text',
                    entity: 'address',
                    field: 'town',
                    label: 'Address Town'
                },
                addressPostcode: {
                    type: 'text',
                    entity: 'address',
                    field: 'postcode',
                    label: 'Address Postcode'
                },
                addressCounty: {
                    type: 'text',
                    entity: 'address',
                    field: 'county',
                    label: 'Address County'
                },
                patchTestDate: {
                    type: 'date',
                    label: 'Latest patch test'
                },
                source: {
                    type: 'text',
                    label: 'How did they hear about us?'
                },
                canContactByPost: {
                    type: 'checkbox',
                    label: 'Can contact by post'
                },
                canContactByPhone: {
                    type: 'checkbox',
                    label: 'Can contact by phone'
                },
                canContactByEmail: {
                    type: 'checkbox',
                    label: 'Can contact by email'
                },
                unsubscribeMarketingEmails: {
                    type: 'checkbox',
                    label: 'Unsubscribe from marketing emails',
                    isDisabled: (disabled) => this.state.customer.unsubscribeMarketingEmails
                },
                canContactBySMS: {
                    type: 'checkbox',
                    label: 'Can contact by SMS'
                },
                sendSMSReminders: {
                    type: 'checkbox',
                    label: 'Send appointment reminders (SMS)'
                },
                sendEmailReminders: {
                    type: 'checkbox',
                    label: 'Send appointment reminders (Email)'
                },
                clubExpiryDate: {
                    type: 'date',
                    label: 'Club Expiry Date'
                }
            },
            getValue: (fieldName, fieldInfo) => {
                if (fieldInfo.entity == 'address') {
                    return this.state.customer.address[fieldName];
                } else {
                    return this.state.customer[fieldName];
                }
            },
            setValue: this.updateField,
            isDisabled: () => {
                const loginDetails = GlobalStateService.getValue('loginDetails');
                return !loginDetails.permissions['ClientCardEditContactDetails'];
            }
        });
    }

    componentDidMount() {
        this.load();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const newState = {};
        if (nextProps.id != prevState.id) {
            newState.id = parseInt(nextProps.id) || 0;
            newState.isLoading = true;
        }
        return newState;
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.id != prevState.id ||
            this.props.version != prevProps.version) {
            this.load();
        }
    }

    async load() {
        let customer = null;
        if (this.state.id) {
            try {
                customer = await CustomerService.get(this.state.id);
            } catch (e) {
                BootboxHelper.alert(e);
            }
        } else {
            customer = {
                sendSMSReminders: true,
                sendEmailReminders: true
            };

            // Default name from URL
            const nameParts = TextHelpers.splitName(this.props.defaultName || '', true);
            customer.firstName = nameParts.firstName;
            customer.lastName = nameParts.lastName;
        }
        if (!customer.address) {
            customer.address = {};
        }

        const stylists = await UserService.listDiary();
        this.setState({
            isLoading: false,
            customer: customer,
            stylists: stylists
        });
    }

    async save() {
        this.setState({
            isLoading: true
        });
        const customer = { ...this.state.customer };
        try {
            if (customer.mobileTel) {
                const validTel = this.validateMobileNumber(customer.mobileTel);
                if (!validTel)
                    return;
            }
            customer.id = await CustomerService.save(customer);
            let updatedCustomer = await CustomerService.get(customer.id);
            customer.unsubscribeMarketingEmails = updatedCustomer.unsubscribeMarketingEmails;
            this.setState({
                customer: customer
            });
            if (this.props.onSave) {
                this.props.onSave(customer);
            }
        } catch(error) {
            BootboxHelper.alert(error);
        } finally {
            this.props.setIsChanged(false);
            this.setState({
                isLoading: false
            });
        }
    }

   async validateMobileNumber(mobileTel) {
       let isValid = true;
       let telLength = '';
       mobileTel = (mobileTel || '').replace(/[^+0-9]/g, '');

       if (mobileTel.startsWith('0')) {
           if (mobileTel.length < 10) {
                isValid = false;
                telLength = 'short';
           }
           else if (mobileTel.length > 11) {
                isValid = false;
                telLength = 'long';
           }
       }
       else if (mobileTel.startsWith('+')) {
            if (mobileTel.length < 12) {
                isValid = false;
                telLength = 'short';
            }
            else if (mobileTel.length > 14) {
                isValid = false;
                telLength = 'long';
            }
        }

        if (!isValid) {
            isValid = await BootboxHelper.confirm('It looks like the mobile number might be too ' + telLength + '. Is it definitely correct?');
        }

        return isValid;
    }
    
    async updateField(fieldName, value, fieldInfo) {
        const customer = { ...this.state.customer };
        if (fieldInfo.entity == 'address') {
            customer.address[fieldInfo.field || fieldName] = value;
        } else if (fieldName == 'unsubscribeMarketingEmails') {
            if (value) {
                if (!customer.email) {
                    BootboxHelper.alert('There is no email address attached to this client card - please add and save (above) the email address that you would like to unsubscribe from marketing emails and come back here to unsubcribe.');
                }
                else {
                    var confirm = await BootboxHelper.confirm('Are you sure you want to unsubscribe this client from marketing emails? - once you click confirm, this cannot be undone.');
                    if (confirm) {
                        customer[fieldName] = value;
                        customer.canContactByEmail = false;
                    }
                }                                
            }
        } else {
            customer[fieldName] = value;
        }
        this.props.setIsChanged(true);
        this.setState({
            customer: customer
        });
    }

    listStylistOptions() {
        var options = [];
        if (this.state.stylists) {
            options.push({ value: '', text: '(No preference)' });
            this.state.stylists.forEach(s => options.push({
                id: s.userID,
                name: s.firstName + ' ' + s.lastName
            }));
        }
        return options;
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------
    
    render() {
        const {
            isLoading
        } = this.state;
        
        if (isLoading) {
            return (<Loader />);
        }
        
        return (<>

            <form className="form customer-details-form" onSubmit={e => { e.preventDefault(); this.save() }}>
                
                {this.formHelper.renderFormGroups([
                    'firstName',
                    'lastName',
                    'mobileTel',
                    'homeTel',
                    'email',
                    'gender',
                    'pronouns',
                    'dateOfBirth',
                    'regularStylistUserID',
                    'backupStylistUserID',

                    'addressLine1',
                    'addressLine2',
                    'addressTown',
                    'addressPostcode',
                    'addressCounty',

                    'source',
                    'canContactByPost',
                    'canContactByPhone',
                    'canContactByEmail',
                    'unsubscribeMarketingEmails',
                    'canContactBySMS',
                    'sendSMSReminders',
                    'sendEmailReminders',

                    'clubExpiryDate'
                ])}
                
                <button type="submit" style={{ display: 'none' }}>Submit</button>

            </form>

            <ReactTooltip
                html={true}
                place="right"
                type="info"
                effect="float"
                delayShow={100}
            />
        </>);
    }
}

export default CustomerDetails;