// Libs
import React from 'react';
import NumberFormat from 'react-number-format';

// Services & Helpers
import GlobalStateService from 'services/GlobalStateService';
import RetailService from 'services/RetailService';
import UserService from 'services/UserService';
import DiaryService from 'services/DiaryService';
import SearchService from 'services/SearchService';
import SalonService from 'services/SalonService';
import PrintService from 'services/PrintService';
import LoyaltySchemeService from 'services/LoyaltySchemeService';
import ThermalPrinterService from 'services/ThermalPrinterService';
import CustomerService from 'services/CustomerService';
import EvoService from 'services/EvoService';
import OnlineBookingService from 'services/OnlineBookingService';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import Loader from 'components/reusable/Loader';
import Search from 'components/reusable/Search';
import Money from 'components/reusable/Money';
import CustomerSummary from 'components/reusable/CustomerSummary';
import WalkInPurchase from './retail2/WalkInPurchase';
import RetailRefund from './retail2/RetailRefund';
import PosTerminalPanel from './checkout/PosTerminalPanel';

//-------------------------------------------------------------------------------------------------------------------

class RetailMenu extends FloomlyComponent {

	constructor(props) {
		super(props);

		this.voucherValueRef = React.createRef();

		this.removeCustomer = this.removeCustomer.bind(this);
		this.removeForCustomer = this.removeForCustomer.bind(this);

		this.isUnmounted = false;
		this.abortAutoGoBack = false;

		this.loginDetails = GlobalStateService.getValue('loginDetails');

		this.state = {
			isLoading: true,
			mode: '',

			// Retail purchase
			customer: null,
			paymentMethods: null,
			step: 1
		};
	}

	componentDidMount() {
		this.load();
	}

	componentWillUnmount() {
		this.isUnmounted = true;
	}

	async load() {
		const loyaltyScheme = await LoyaltySchemeService.get();

		this.setState({
			loyaltyScheme,
			isLoading: false
		});
	}

	//--------------------------------------------------------------------------------------------------------------------
	// Generic
	//--------------------------------------------------------------------------------------------------------------------

	async selectRetailPurchase() {
		this.autoFocusProductIndex = -1;
		this.setState({
			mode: 'retail-purchase',
			step: 1,
		});
	}

	addNewCustomer(query) {

	}

	async selectCustomer(customer, type) {
		const newState = {
		};
		if (type == 'for') {
			newState.forCustomer = customer;
		} else {
			newState.customer = customer;
		}
		switch (this.state.mode) {
			case 'voucher':
				if (type == 'for') {
					this.updateVoucherField('forCustomerID', (customer ? customer.id : null));
					this.voucherValueRef.current.focus();
				} else {
					this.updateVoucherField('customerID', (customer ? customer.id : null));
				}
				break;
		}
		const savedCard = customer ? await CustomerService.getSavedCard(customer.id) : null;
		this.clientInfo = GlobalStateService.getValue('clientInfo');

		this.updateVoucherField('savedCard', savedCard && savedCard.paymentProvider == this.clientInfo.paymentProvider && savedCard.consentToSaveCard ? savedCard : null)
		this.setState(newState);
	}

	removeCustomer() {
		this.setState({ customer: null });
	}

	removeForCustomer() {
		this.setState({ forCustomer: null });
	}

	goBack() {
		const {
			mode,
			step
		} = this.state;

		if (step == 2) {
			this.setState({
				step: 1
			});
		} else {
			this.setState({
				mode: '',
				customer: null,
				purchases: [],
				total: 0,
				discount: 0,
			});
		}
	}

	//--------------------------------------------------------------------------------------------------------------------
	// Voucher
	//--------------------------------------------------------------------------------------------------------------------

	async selectVoucher() {
		this.autoFocusProductIndex = -1;
		this.setState({
			mode: 'voucher',
			customer: null,
			forCustomer: null,
			step: 1,
			voucher: {
				saleUserID: this.loginDetails.user.userID,
				code: '',
				subTotal: 0,
				total: 0,
				emailReceipt: false,
				printReceipt: false
			}
		});

		// Load other data
		const paymentMethodsReq = DiaryService.loadPaymentMethodsFor('retail');
		const usersReq = UserService.list();

		// Load payment methods
		let paymentMethods = await paymentMethodsReq;
		paymentMethods = paymentMethods.filter(pm => pm.code != 'Voucher');
		const cardPM = paymentMethods.find(pm => pm.code == 'Card');

		//set default payment device
		const paymentDevices = await OnlineBookingService.listPaymentDevices() || {};
		let defaultPaymentDevice = paymentDevices.find(pd => pd.isDefault);
		this.state.voucher.paymentDeviceId = defaultPaymentDevice ? defaultPaymentDevice.paymentDeviceId : null;

		this.setState({ paymentMethods: paymentMethods, cardPM });
		if (paymentMethods.length > 0) {
			this.updateVoucherField('paymentMethodID', paymentMethods[0].paymentMethodID);			
		}

		this.generateVoucherCode();

		// Load users
		const users = await usersReq;
		this.setState({
			users: users
		});
	}

	async updateVoucherField(fieldName, value) {
		const voucher = { ...this.state.voucher };
		if (fieldName == 'code') {
			value = (value || '').toUpperCase();
			value = value.replace(/[^a-z0-9\\-]/ig, '');

			if (!value) {
				this.setState({
					voucherWarning: 'Please enter a voucher code'
				})
			} else {
				const used = await RetailService.isVoucherCodeUsed(value);
				if (used) {
					this.setState({
						voucherWarning: 'Voucher code already in use'
					})
				} else {
					this.setState({
						voucherWarning: null
					})
				}
			}
		}
		voucher[fieldName] = value;
		this.setState({
			voucher: voucher
		});
	}

	async generateVoucherCode() {
		this.setState({ isGeneratingVoucherCode: true });
		const code = await RetailService.generateRandomVoucherCode();
		this.setState({
			isGeneratingVoucherCode: false
		}, () => {
			this.updateVoucherField('code', code);
		});
	}

	async saveVoucher() {
		const {
			voucher,
			cardPM
		} = this.state;
		if (this.state.step == 3) {

			if (voucher.paymentDeviceId <= 0) {
				BootboxHelper.alert('Please make sure to select the payment device.');
				return;
			}

			let response = await DiaryService.sendToCardMachine(voucher.total, voucher);
			BootboxHelper.alert('Please wait while we process your payment <br/> Please do not refresh or close this screen');

			this.setState({ isLoading: true });
			this.checkTransactionCompleteInterval = setInterval(() => {
				this.checkForTransactionDetails(response.TransactionId, response.PaymentDeviceId);
			}, 1000);

			//check if anything needs to be completed on Pos Device
			this.checkIfPosPendingTransactionTimeout = setTimeout(function () {
				BootboxHelper.alert('Pos is busy. Please complete the transaction on the terminal');
			}, 50000);

			this.checkIfPosIncompleteTransactionTimeout = setTimeout(() => {
				this.checkForIncompleteTransaction(response.TransactionId, response.PaymentDeviceId);
			}, 100000);
		}

		const isTerminalPayment = voucher.total >= 0 && voucher.paymentMethodID == cardPM.paymentMethodID && !voucher.isSavedCard && !voucher.txnReference;
		if (isTerminalPayment && voucher.paymentProvider == 'evo') {
			this.setState({ step: 3 });
		} else {
			this.setState({ isLoading: true });
			// Save
			const id = await RetailService.saveVoucher(voucher);

			// Print
			if (voucher.printReceipt) {
				this.printVoucherReceipt(id);
			}

			// Update UI
			this.setState({
				isLoading: false,
				mode: 'voucher-complete',
				step: 1
			});

			//// Automatically go back
			//window.setTimeout(() => {
			//    if (this.isUnmounted) return;
			//    this.goBack();
			//}, 3 * 1000);
		}
	}

	updateVoucherButtonText() {
		const { voucher, cardPM } = this.state;

		const cardPayment = voucher.total >= 0 && voucher.paymentMethodID == cardPM.paymentMethodID;
		const isTerminalPayment = voucher.total >= 0 && voucher.paymentMethodID == cardPM.paymentMethodID && !voucher.isSavedCard;

		if (cardPayment && voucher.savedCard) {
			if (!isTerminalPayment)
				return 'Process payment';
			else if (voucher.paymentProvider == 'evo')
				return 'Send to card machine';
			else {
				return 'Complete purchase';
			}
		}
		else if (cardPayment && isTerminalPayment && voucher.paymentProvider == 'evo')
			return 'Send to card machine';
		else {
			return 'Complete purchase';
		}
	}

	canContinue() {
		if (this.state.voucher.paymentDeviceId > 0)
			return true;
		else
			return false;
	}

	async checkForTransactionDetails(transactionId, paymentDeviceId) {
		const transactionResponse = await DiaryService.getTransactionDetails(transactionId, paymentDeviceId);
		if (transactionResponse == EvoService.RESPONSE_CANCELLED || transactionResponse == EvoService.RESPONSE_REFUSED || transactionResponse == EvoService.RESPONSE_SUCCESS) {
			clearTimeout(this.checkIfPosPendingTransactionTimeout);
			clearInterval(this.checkTransactionCompleteInterval);
			clearTimeout(this.checkIfPosIncompleteTransactionTimeout);
			if (transactionResponse == EvoService.RESPONSE_SUCCESS) {
				let voucher = this.state.voucher;
				if (!voucher.txnReference) {
					voucher.txnReference = transactionId;
					this.setState({
						step: 0,
						isLoading: false
					});
					this.saveVoucher();
				}
			}
			else if (transactionResponse == EvoService.RESPONSE_CANCELLED) {
				BootboxHelper.alert("Card transaction cancelled.Please try again.");
				this.setState({
					step: 2,
					isLoading: false
				});
			}
			else if (transactionResponse == EvoService.RESPONSE_REFUSED) {
				BootboxHelper.alert("Card payment declined. Please try with other payment method.");
				this.setState({
					step: 2,
					isLoading: false
				});
			} 			
		}
	}

	async checkForIncompleteTransaction(transactionId, paymentDeviceId) {
		const transactionResponse = await DiaryService.getTransactionDetails(transactionId, paymentDeviceId);
		if (transactionResponse == 'Fetching Details') {
			BootboxHelper.alert('Please make sure to select the correct payment device.');
			clearTimeout(this.checkIfPosPendingTransactionTimeout);
			clearInterval(this.checkTransactionCompleteInterval);
			clearTimeout(this.checkIfPosIncompleteTransactionTimeout);
			this.goBack();
		}
	}

	async printVoucherReceipt(id) {
		const { isPrintingReceipt } = this.state;
		if (isPrintingReceipt) return;
		this.setState({ isPrintingReceipt: true });
		await ThermalPrinterService.printVoucherReceiptWithPDFFallback(id);
		this.setState({ isPrintingReceipt: false });
	}

	//--------------------------------------------------------------------------------------------------------------------
	// Cash in / out
	//--------------------------------------------------------------------------------------------------------------------

	async selectAcctTran(type) {
		this.setState({
			mode: type,
			acctTran: {
				userID: this.loginDetails.user.userID,
				sourceAccountCode: (type == 'cash-in' ? 'SalesCash' : 'PettyCash'),
				destAccountCode: (type == 'cash-in' ? 'PettyCash' : null),
				paymentMethodCode: 'Cash'
			}
		});

		// Load users
		const users = await UserService.list();
		this.setState({
			users: users
		});
	}

	updateAcctTranField(fieldName, value) {
		const acctTran = { ...this.state.acctTran };
		acctTran[fieldName] = value;

		// Can't move from float to float
		if (fieldName == 'destAccountCode' && value == 'Float' && acctTran.sourceAccountCode == 'Float') {
			acctTran.sourceAccountCode = 'SalesCash';
		}

		this.setState({
			acctTran
		});
	}

	async saveAcctTran() {
		const {
			acctTran
		} = this.state;

		this.setState({ isLoading: true });

		// Save
		await RetailService.saveAcctTran(acctTran);

		// Update UI
		this.setState({
			isLoading: false,
			mode: 'acct-tran-complete'
		});
	}

	//--------------------------------------------------------------------------------------------------------------------

	selectRefund() {
		this.setState({
			mode: 'retail-refund'
		});
	}

	//--------------------------------------------------------------------------------------------------------------------

	async openCashDrawer() {
		await ThermalPrinterService.openCashDrawers(true);
	}

	//--------------------------------------------------------------------------------------------------------------------
	// Render
	//--------------------------------------------------------------------------------------------------------------------

	render() {
		const {
			isLoading,
			mode,
			step
		} = this.state;

		if (isLoading) {
			return (<Loader />);
		}

		switch (mode) {
			case 'retail-purchase':
				return (<WalkInPurchase 
					onClose={() => this.goBack()}
					/>);
				
			case 'voucher':
				if (step == 1) {
					return this.renderVoucher();
				} else if (step == 2) {
					return this.renderSelectPaymentMethod(
						this.state.voucher,
						(fieldName, value) => this.updateVoucherField(fieldName, value),
						() => this.saveVoucher()
					);
				} else if (step == 3) {
					return this.renderPosTerminal();
				}				
			case 'voucher-complete':
				return this.renderVoucherComplete();
			case 'cash-in':
				return this.renderCashIn();
			case 'cash-out':
				return this.renderCashOut();
			case 'acct-tran-complete':
				return this.renderAcctTranComplete();
			case 'retail-refund':
				return this.renderRetailRefund();
			default:
				return this.renderMenu();
		}
	}

	renderMenu() {
		return (<>
			<button className="button button-secondary button-small" onClick={this.props.onBackClicked}>
				<span className="fas fa-arrow-left"></span>{' '}
				Back to Diary
			</button>

			<div className="panel retail-menu">
				<div className="panel-header">Retail</div>
				<div className="panel-body">

					{/*
                    <button className="button button-primary button-small">
                        <span className="far fa-frown"></span>{' '}
                        Refund
                    </button>
                    */}

					{this.loginDetails.permissions['DiaryRetailWalkIn'] &&
						<button className="button button-primary button-small" onClick={e => this.selectRetailPurchase()}>
							<span className="fas fa-walking"></span>{' '}
							Walk-In Sale
						</button>
					}

					{this.loginDetails.permissions['DiaryRetailVouchers'] &&
						<button className="button button-primary button-small" onClick={e => this.selectVoucher()}>
							<span className="fas fa-ticket-alt"></span>{' '}
							Voucher
						</button>
					}

					{this.loginDetails.permissions['DiaryRetailCashOut'] &&
						<button className="button button-primary button-small" onClick={e => this.selectAcctTran('cash-out')}>
							<span className="fas fa-toolbox"></span>{' '}
							<span className="fas fa-sign-out-alt"></span>{' '}
							Cash Out
						</button>
					}

					{this.loginDetails.permissions['DiaryRetailCashIn'] &&
						<button className="button button-primary button-small" onClick={e => this.selectAcctTran('cash-in')}>
							<span className="fas fa-sign-in-alt"></span>{' '}
							<span className="fas fa-toolbox"></span>{' '}
							Cash In
						</button>
					}

					{this.loginDetails.permissions['DiaryRefundWalkInRetail'] &&
						<button className='button button-primary button-small' onClick={_ => this.selectRefund()}>
							<span className="fas fa-coins"></span>{' '}
							Refund Retail Purchase
						</button>
					}

					{this.loginDetails.permissions['DiaryCreateAppointments'] &&
						<button className="button button-primary button-small" onClick={e => this.props.onQuotationClicked()}>
							<span className="fas fa-calculator"></span>{' '}
							Quotation
						</button>
					}

					{this.loginDetails.permissions['DiaryRetailOpenCashDrawer'] &&
						<button className="button button-primary button-small" onClick={e => this.openCashDrawer()}>
							<span className="fas fa-cash-register"></span>{' '}
							Open Cash Drawer
						</button>
					}

					<button className="button button-primary button-small" onClick={e => this.props.onWaitingApptClicked()}>
						<span className="fas fa-list"></span>{' '}
							Add To Waiting List
					</button>

				</div>
			</div>
		</>);
	}

	renderCustomerSelector(type, autoFocus) {
		const customer = (type == 'for' ? this.state.forCustomer : this.state.customer);

		if (customer) {
			return (
				<CustomerSummary
					header={type == 'for' ? 'For customer' : 'Purchased by'}
					customerID={customer.id}
					onChangeClicked={e => type == 'for' ? this.removeForCustomer() : this.removeCustomer()}
				/>
			);
		}

		return (
			<div className="panel retail-purchase-customer">
				<div className="panel-header">
					{type == 'for' ? 'For customer' : 'Purchased by'}
				</div>
				<div className="panel-body">

					<Search
						className="search-box"
						autoFocus={!!autoFocus}
						placeholder="Find customer (optional)..."
						maxResults={50}
						renderWrapper={(input, results, info) => (<>
							{input}
							{results && results.length > 0 &&
								<div className="list search-results">
									{results}
								</div>
							}
						</>)}
						search={async (query, setResults, maxResults, state) => {
							this.customerSearchQuery = query;
							const results = await SearchService.search(query, ['Customer'], { maxResults, state });
							setResults(results);
						}}
						renderResult={(customer, index) =>
							<div key={index} className="search-result list-item" onMouseDown={e => this.selectCustomer(customer, type)}>
								{/*<img className="user-icon list-item-icon" src=userIconImage />*/}
								<div className="list-item-name">
									{customer.name}
									<div className="list-subitem-number">
										{customer.customerTel} {customer.customerTel && customer.customerEmail && <>-</>} {customer.customerEmail}
									</div>
								</div>
							</div>
						}
					/>

				</div>

			</div>
		);
	}

	renderSelectPaymentMethod(entity, onUpdateField, onSave) {
		const {
			paymentMethods
		} = this.state;

		this.clientInfo = GlobalStateService.getValue('clientInfo');
		entity.paymentProvider = this.clientInfo.paymentProvider;		

		if (!paymentMethods) {
			return (<Loader />);
		}

		return (<>
			<div className="panel payment-methods-panel">
				<div className="panel-header">Payment Method</div>
				<div className="panel-body">
					<div className="button-stack">
						{paymentMethods.map(paymentMethod => {
							const isSelected = (paymentMethod.paymentMethodID == entity.paymentMethodID);

							// Skip 'Account' if there is no customer selected
							if (paymentMethod.code == 'Account' && !entity.customer) {
								return;
							}

							return (<>
								<button
									key={paymentMethod.paymentMethodID}
									className={'button button-small button-selectable'}
									onClick={e => onUpdateField('paymentMethodID', Number(paymentMethod.paymentMethodID))}
								>
									{isSelected && <>
										<i className='fas fa-check'></i>{' '}
									</>}
									{paymentMethod.name}
									{paymentMethod.code == 'Account' && <>
										<div className="button-subtitle">
											(Current: <Money amount={entity.customer.accountBalance} />)
										</div>
									</>}
								</button>
								<>
									{
										paymentMethod && paymentMethod.code === 'Card' && entity.savedCard &&
										<div className="non-selectable payment-method-subtitle">
											<span className="service-list-name">
												<input
													id="saved-card"
													class="saved-card"
													type="checkbox"
														checked={entity.isSavedCard}
														onChange={e => onUpdateField('isSavedCard', !entity.isSavedCard )}
												/>
												<span><label htmlFor="saved-card">Charge Card ending in {entity.savedCard.last4Digits}</label></span>
											</span>
										</div>
									}
								</>
							</>
							);
						})}
					</div>

					<ul className="list service-list">

						{/* Amount to Pay */}
						<li className="non-selectable remaining-balance">

							<span className="service-list-icon">
								<span className="fas fa-money-alt"></span>
							</span>

							<span className="service-list-name" style={{ fontWeight: 'bold' }}>
								Total Amount to Pay
							</span>

							<span className="service-list-price">

								<Money amount={entity.total} />

							</span>

						</li>

						{/* Receipt */}
						<li className="non-selectable receipt-row" onClick={e => onUpdateField('printReceipt', !entity.printReceipt)}>

							<span className="service-list-icon">
								<span className="fas fa-print"></span>
							</span>

							<span className="service-list-name">
								Print Receipt
							</span>

							<span className="service-list-price">

								<input
									type="checkbox"
									checked={entity.printReceipt}
									onChange={e => { /* NOP */ }}
								/>

							</span>

						</li>

						{/*
                        <li className="non-selectable receipt-row" onClick={e => onUpdateField('emailReceipt', !entity.emailReceipt)}>

                            <span className="service-list-icon">
                                <span className="far fa-envelope"></span>
                            </span>

                            <span className="service-list-name">
                                Email receipt
                            </span>
                            
                            <span className="service-list-price">

                                <input
                                    type="checkbox"
                                    checked={entity.emailReceipt}
                                    onChange={e => { }}
                                />

                            </span>

                        </li>
                        */}

					</ul>

				</div>

			</div>

			<div className="button-stack">

				<button className="button button-primary button-small" onClick={e => onSave()}>
					<i className="fas fa-check"></i>{' '}
					{this.updateVoucherButtonText()}
				</button>

				<button className="button button-tertiary button-small" onClick={e => this.goBack()}>
					<i className="fas fa-arrow-left"></i>{' '}
					Back
				</button>

			</div>

		</>);
	}

	//--------------------------------------------------------------------------------------------------------------------
	// Render - Voucher
	//--------------------------------------------------------------------------------------------------------------------

	renderVoucher() {
		const {
			voucher,
			users,
			customer,
			forCustomer,
			isGeneratingVoucherCode
		} = this.state;

		if (!users) {
			return (<Loader />);
		}

		return (<>

			<button className="button button-secondary button-small" onClick={e => this.goBack()}>
				<span className="fas fa-arrow-left"></span>{' '}
				Back
			</button>

			{this.renderCustomerSelector(null, !customer)}
			{this.renderCustomerSelector('for', !!customer && !forCustomer)}

			<div className="panel voucher">

				<div className="panel-body">

					<div className="form-group">
						<label>Amount</label>
						<input
							ref={this.voucherValueRef}
							type="number"
							value={voucher.total || ''}
							onChange={e => this.updateVoucherField('total', Number(e.target.value) || '')}
						/>
					</div>

					<div className="form-group">
						<label>Voucher Code</label>
						{this.state.voucherWarning &&
							<div className="voucher-warning">
								<span className="fas fa-exclamation-triangle"></span>
								{' ' + this.state.voucherWarning}
							</div>
						}
						<input
							type="text"
							className="voucher-code"
							value={voucher.code}
							onChange={e => this.updateVoucherField('code', e.target.value)}
						/>
					</div>

					<div className="form-group">
						<label>Sold by</label>
						<select
							value={voucher.saleUserID}
							onChange={e => this.updateVoucherField('saleUserID', Number(e.target.value) || null)}
						>
							<option value="">(Select)</option>
							{users.map(u =>
								<option key={u.userID} value={u.userID}>
									{u.nickname}
								</option>
							)}
						</select>
					</div>

				</div>

			</div>

			<div className="button-stack">

				<button className="button button-primary button-small" disabled={this.state.voucherWarning} onClick={e => this.setState({ step: 2 })}>
					Save and continue{' '}
					<i className="fas fa-arrow-right"></i>
				</button>

				<button className="button button-tertiary button-small" onClick={e => this.goBack()}>
					<i className="fas fa-arrow-left"></i>{' '}
					Cancel purchase
				</button>

			</div>
		</>);
	}

	renderVoucherComplete() {
		const {
			voucher
		} = this.state;

		return (<>

			<div className="panel retail-purchase-complete-panel">
				<div className="panel-body">
					Purchase complete!
				</div>
			</div>
			<div className="panel voucher-complete-panel">

				<div className="panel-body">
					<div>Please add this voucher code to the voucher purchased <i className="far fa-smile"></i></div>

					{voucher.code &&
						<div className="voucher-code">
							Voucher code:
							<div className="code">
								{voucher.code}
							</div>
						</div>
					}

				</div>

			</div>

			<button className="button button-primary" onClick={e => this.goBack()}>
				<i className="fas fa-check"></i>{' '}
				Back to Menu
			</button>

			<button className="button button-secondary" onClick={e => this.selectVoucher()}>
				<i className="fa fa-cheron-right"></i>{' '}
				Another Voucher
			</button>

		</>);
	}

	renderPosTerminal() {
		let {
			voucher
		} = this.state;
		return (<>
			<PosTerminalPanel
				checkOut={voucher}
				onCheckOutChanged={checkOut => this.setState({ voucher: checkOut })}
				onChange={(name, value) => { }}
				isRetailMenu={true }
			/>
			<div className="button-stack">
				<button className={'button button-primary button-small ' + (this.canContinue() ? '' : 'disabled')} onClick={e => this.saveVoucher()}>
					<i className="fas fa-check"></i>{' '}
					Send to payment device
				</button>

				<button className="button button-tertiary button-small" onClick={e => this.goBack()}>
					<i className="fas fa-arrow-left"></i>{' '}
					Back
				</button>

			</div>
		</>)
	}

	//--------------------------------------------------------------------------------------------------------------------
	// Render - Cash in / out
	//--------------------------------------------------------------------------------------------------------------------

	renderCashIn() {
		const {
			acctTran,
			users
		} = this.state;
		const currency = SalonService.getCurrentCurrency();

		if (!users) {
			return (<Loader />);
		}

		return (<>

			<button className="button button-secondary button-small" onClick={e => this.goBack()}>
				<span className="fas fa-arrow-left"></span>{' '}
				Back
			</button>

			<div className="panel acct-tran">
				<div className="panel-header">Cash In</div>

				<div className="panel-body">

					<div className="form-group">
						<label>Type</label>
						<select
							value={acctTran.destAccountCode}
							onChange={e => this.updateAcctTranField('destAccountCode', e.target.value)}
						>
							<option value="PettyCash">Petty Cash</option>
							<option value="Float">Float</option>
						</select>
					</div>

					<div className="form-group">
						<label>Source</label>
						<select
							value={acctTran.sourceAccountCode}
							onChange={e => this.updateAcctTranField('sourceAccountCode', e.target.value)}
						>
							<option value="SalesCash">Cash Takings</option>
							{acctTran.destAccountCode != 'Float' &&
								<option value="Float">Float</option>
							}
							<option value="External">External</option>
						</select>
					</div>

					<div className="form-group">
						<label>Amount</label>
						<NumberFormat
							prefix={currency.symbol}
							thousandSeparator={currency.thousandSeparator}
							decimalSeparator={currency.decimalSeparator}
							value={acctTran.amount}
							onValueChange={(values) => this.updateAcctTranField('amount', values.value)}
						/>
					</div>

					<div className="form-group">
						<label>Added by</label>
						<select
							value={acctTran.userID}
							onChange={e => this.updateAcctTranField('userID', Number(e.target.value) || null)}
						>
							<option value="">(Select)</option>
							{users.map(u =>
								<option key={u.userID} value={u.userID}>
									{u.nickname}
								</option>
							)}
						</select>
					</div>

				</div>

			</div>

			<div className="button-stack">

				<button className="button button-primary button-small" onClick={e => this.saveAcctTran()}>
					Submit{' '}
					<i className="fas fa-check"></i>
				</button>

				<button className="button button-tertiary button-small" onClick={e => this.goBack()}>
					<i className="fas fa-arrow-left"></i>{' '}
					Cancel
				</button>

			</div>
		</>);
	}

	renderCashOut() {
		const {
			acctTran,
			users
		} = this.state;
		const currency = SalonService.getCurrentCurrency();

		if (!users) {
			return (<Loader />);
		}

		return (<>

			<button className="button button-secondary button-small" onClick={e => this.goBack()}>
				<span className="fas fa-arrow-left"></span>{' '}
				Back
			</button>

			<div className="panel acct-tran">
				<div className="panel-header">Cash Out</div>

				<div className="panel-body">

					<div className="form-group">
						<label>Type</label>
						<select
							value={acctTran.sourceAccountCode}
							onChange={e => this.updateAcctTranField('sourceAccountCode', e.target.value)}
						>
							<option value="PettyCash">Petty Cash</option>
							<option value="Float">Float</option>
						</select>
					</div>

					<div className="form-group">
						<label>Amount</label>
						<NumberFormat
							prefix={currency.symbol}
							thousandSeparator={currency.thousandSeparator}
							decimalSeparator={currency.decimalSeparator}
							value={acctTran.amount}
							onValueChange={(values) => this.updateAcctTranField('amount', values.value)}
						/>
					</div>

					<div className="form-group">
						<label>Taken by</label>
						<select
							value={acctTran.userID}
							onChange={e => this.updateAcctTranField('userID', Number(e.target.value) || null)}
						>
							<option value="">(Select)</option>
							{users.map(u =>
								<option key={u.userID} value={u.userID}>
									{u.nickname}
								</option>
							)}
						</select>
					</div>

					<div className="form-group">
						<label>Description</label>
						<textarea
							value={acctTran.description || ''}
							rows={2}
							onChange={e => this.updateAcctTranField('description', e.target.value)}
						/>
					</div>
				</div>

			</div>

			<div className="button-stack">

				<button className="button button-primary button-small" onClick={e => this.saveAcctTran()}>
					Submit{' '}
					<i className="fas fa-check"></i>
				</button>

				<button className="button button-tertiary button-small" onClick={e => this.goBack()}>
					<i className="fas fa-arrow-left"></i>{' '}
					Cancel
				</button>

			</div>
		</>);
	}

	renderAcctTranComplete() {
		const {
			acctTran
		} = this.state;

		return (<>

			<div className="panel acct-tran-complete-panel">

				<div className="panel-body">

					Transaction Added!

				</div>

			</div>

			<button className="button button-primary" onClick={e => this.goBack()}>
				<i className="fas fa-check"></i>{' '}
				Back to Menu
			</button>

		</>);
	}

	renderRetailRefund() {
		return (<>

			<RetailRefund
				onClose={() => this.goBack()}/>

		</>);
	}
}

export default RetailMenu;