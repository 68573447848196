// Libs
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/en-gb';

// Services & Helpers
import CustomerService      from 'services/CustomerService';
import UploadService        from 'services/UploadService';
import GlobalStateService   from 'services/GlobalStateService';
import FormHelper from 'helpers/FormHelper';
import BootboxHelper from 'helpers/BootboxHelper';
import OnlineBookingService from 'services/OnlineBookingService';
import ThermalPrinterService from 'services/ThermalPrinterService';

// Components
import FloomlyComponent     from 'components/FloomlyComponent';
import InfoBar              from 'components/layout/InfoBar'
import Loader               from 'components/reusable/Loader';
import CustomerSummary      from 'components/reusable/CustomerSummary';
import TabPanel, { Tab }    from 'components/reusable/TabPanel';
//import PhotoGallery         from 'components/reusable/PhotoGallery';
import UploadButton         from 'components/reusable/UploadButton';

import CustomerDetails      from 'components/pages/customer/CustomerDetails';
import CustomerApptList     from 'components/pages/customer/CustomerApptList';
import CustomerApptNotes    from 'components/pages/customer/CustomerApptNotes';
import CustomerComms        from 'components/pages/customer/CustomerComms';
import CustomerForms        from 'components/pages/customer/CustomerForms';
import MergeCustomersModal  from 'components/pages/customer/MergeCustomersModal';
import ViewUploadModal      from 'components/pages/customer/ViewUploadModal';

//-------------------------------------------------------------------------------------------------------------------

class CustomerPage extends FloomlyComponent {

    constructor(props) {
        super(props);

        this.state = {
            isSummaryLoading: true,
            tab: null,
            customer: null,
            isClientRecordUpdated: false
        }

        this.customerDetailsRef = React.createRef();
        this.customerApptNotesRef = React.createRef();
        this.photoGalleryRef = React.createRef();
        this.mergeCustomersModalRef = React.createRef();
        this.viewUploadModalRef = React.createRef();

        this.save = this.save.bind(this);
        this.onSave = this.onSave.bind(this);
        this.updateField = this.updateField.bind(this);
        this.commitFieldUpdate = this.commitFieldUpdate.bind(this);
        this.setIsChanged = this.setIsChanged.bind(this);

        this.summaryFormHelper = new FormHelper({
            fields: {
                patchTestDate: {
                    type: 'date',
                    label: 'Date of last patch test'
                },
                wowNotes: {
                    className: 'notes-field',
                    type: 'multiline-text',
                    label: 'WOW Notes',
                    description: '(such as client getting married, likes tea with oat milk etc.- these will show when you hover over an appointment)',
                    rows: 6
                },
                importantNotes: {
                    className: 'notes-field',
                    type: 'multiline-text',
                    label: 'Important Notes',
                    description: '(any alerts, allergies or important information - an alert icon will show when you hover over an appointment to prompt you to check notes in client card)',
                    rows: 6
                }
            },
            getValue: (fieldName) => {
                return this.state.customer[fieldName];
            },
            setValue: this.updateField,
            onBlur: this.commitFieldUpdate,
            isDisabled: () => {
                const loginDetails = GlobalStateService.getValue('loginDetails');
                return !loginDetails.permissions['ClientCardEditAlertNotes'];
            }
        });
    }

    componentDidMount() {
        this.load();
        if (this.props.match.params.tab == 'gallery') {
            this.loadPhotos();
        }

        this.unblock = this.props.history.block((nextLocation) => {
            if (this.isChanged || (this.state.isClientRecordUpdated && !nextLocation.pathname.startsWith('/customer'))) {
                this.handleBlockedNavigation(nextLocation);
                return false;
            }
            return true;
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id != prevProps.match.params.id) {
            this.load();
        }
        if (this.props.match.params.tab == 'gallery' && prevProps.match.params.tab != 'gallery') {
            this.loadPhotos();
        }
    }

    componentWillUnmount() {
        if (this.unblock) {
            this.unblock();
        }
    }

    load() {
        const id = parseInt(this.props.match.params.id) || null;
        if (id) {
            this.setState({
                isSummaryLoading: true
            });
            this.loadSummary();
        } else {
            this.setState({
                customer: {
                    customerID: 0
                },
                isSummaryLoading: false
            });
        }
    }

    setIsChanged(value) {
        this.isChanged = value;
        GlobalStateService.setValue('unsavedChanges', value);
    }

    handleBlockedNavigation = async (nextLocation) => {
        const confirm = await BootboxHelper.confirm('If you navigate away, the changes you have made will be lost. Is that ok?');
        if (confirm) {
            this.isChanged = false;
            GlobalStateService.setValue('unsavedChanges', true);
            this.setState({isClientRecordUpdated: false});
            this.props.history.push(nextLocation.pathname);
        }
    };

    async loadSummary() {
        const id = parseInt(this.props.match.params.id) || null;
        const customer = await CustomerService.getSummary(id);
        this.setState({
            customer,
            isSummaryLoading: false
        });
        this.loadOnlineBookingAccount();
    }

    async loadOnlineBookingAccount() {
        const id = parseInt(this.props.match.params.id)
        const onlineBookingAccount = await CustomerService.getOnlineBookingAccount(id);
        this.setState({
            onlineBookingAccount,
            isOnlineBookingAccountLoaded: true
        });
    }

    async loadPhotos() {
        const id = parseInt(this.props.match.params.id) || null;
        const photos = await UploadService.listForCustomer(id, 'Photo');
        this.setState({
            photos: photos
        });
    }

    save() {
        this.setState({
            isClientRecordUpdated: false
        });
        this.setIsChanged(false);
        switch (this.props.match.params.tab || 'details') {
            case 'details':
                this.customerDetailsRef.current.save();
                break;
            case 'appointment-notes':
                this.customerApptNotesRef.current.save();
                break;
        }
    }

    onSave(customer) {
        this.setIsChanged(false);
        if (customer.id != this.state.customer.customerID) {
            this.props.history.push(`/customer/${customer.id}/${this.props.match.params.tab}`)
        }
        this.loadSummary();
    }

    updateField(fieldName, value, fieldInfo) {
        const customer = { ...this.state.customer };
        customer[fieldName] = value;
        this.setState({
            customer
        });
        if (fieldName == 'patchTestDate') {
            this.commitFieldUpdate(fieldName, value, fieldInfo);
        }
    }

    commitFieldUpdate(fieldName, value, fieldInfo) {
        if (this.state.customer.customerID) {
            // Existing customer - send straight to server
            CustomerService.updateNotes(this.state.customer.customerID, fieldName, value);
        } else {
            // New customer - update in details tab
            this.customerDetailsRef.current.updateField(fieldName, value, fieldInfo);
        }
    }

    async confirmDelete() {
        const confirm = await BootboxHelper.confirm('Are you sure you want to delete this client?');
        if (confirm) {
            this.delete();
        }
    }

    async delete() {
        this.setState({
            isLoading: true,
            isSummaryLoading: true
        });
        await CustomerService.delete(this.state.customer.customerID);
        this.props.history.replace('/');
    }

    async printCustomerAppointments() {
        if (this.state.isPrinting) {
            return;
        }
        this.setState({ isPrinting: true });
        await ThermalPrinterService.printCustomerApptsWithPDFFallback(this.state.customer.customerID, moment().format('YYYY-MM-DD'));
        this.setState({ isPrinting: false });
    }

    async showMergeModal() {
        await this.setRecordUpdate();
        const customerID = this.state.customer.customerID;
        const { primaryCustomerID } = await this.mergeCustomersModalRef.current.show({
            customerID
        });
        if (primaryCustomerID == customerID) {
            this.setState({
                version: this.state.version + 1
            });
        } else {
            this.props.history.replace(`/customer/${primaryCustomerID}`);
        }
    }

    async showConsultationFormModal() {
        const { customer } = this.state;
        await this.viewUploadModalRef.current.show({
            guid: customer.consultationFormUploadGUID
        });
    }

    async confirmOnlineBookingAccount() {
        const confirm = await BootboxHelper.confirm(
            'This will confirm the online booking account and allow them to log in.<br/>' +
            'You should only use this if they did not receive the confirmation email.<br />' +
            'Are you sure you want to confirm their online booking account?'
        );
        if (confirm) {
            this.setState({ isSummaryLoading: true, isOnlineBookingAccountLoaded: false });
            const id = this.state.customer.customerID;
            await CustomerService.confirmOnlineBookingAccount(id);
            this.setState({ isSummaryLoading: false });
            this.loadOnlineBookingAccount();
            BootboxHelper.alert('The client\'s online booking account has been confirmed. Please ask them to refresh the screen.');
        }
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {

        return (<>
            <div className="page-content customer-page">

                <div className="page-content-left">
                    {this.renderSideContent()}
                </div>

                <div className="page-content-right">

                    {this.renderInfoBar()}
                    {this.renderMainContent()}

                </div>

            </div>

            <MergeCustomersModal ref={this.mergeCustomersModalRef} />
            <ViewUploadModal ref={this.viewUploadModalRef} />
        </>);
    }

    renderInfoBar() {
        const {
            tab
        } = this.props.match.params;
        const {
            customer
        } = this.state;
        const loginDetails = GlobalStateService.getValue('loginDetails');
        const showSaveButton = (((tab || 'details') == 'details' && loginDetails.permissions['ClientCardEditContactDetails']) || (tab == 'appointment-notes' && loginDetails.permissions['ClientCardEditAppointmentNotes']));

        return (<>

            <InfoBar containerClassName="floating-on-mobile" className={'customer-info-bar ' + (showSaveButton ? '' : 'desktop-only')} sticky={false}>

                <div className="info-bar-panel-section">

                    {showSaveButton &&
                        <button className="button" onClick={this.save}>
                            <span className="fa fa-pencil-alt"></span>{' '}
                            Save
                        </button>
                    }

                    {tab == 'gallery' &&
                        <UploadButton
                            onUpload={file => this.photoGalleryRef.current.upload(file)}
                        >
                            <span className="fa fa-plus"></span>{' '}
                            Upload Photo
                        </UploadButton>
                    }
                </div>

                {customer && !!customer.customerID &&
                    <div className="info-bar-panel-section" style={{ marginLeft: 'auto' }}>

                        {!!customer.consultationFormUploadGUID &&
                            <button className="button me-1" onClick={() => this.showConsultationFormModal()}>
                                <span className="far fa-copy"></span>{' '}
                                Consultation Form
                            </button>
                        }

                        <button className="button merge-customers-button me-1" onClick={() => this.printCustomerAppointments()}>
                            <span className="fa fa-print"></span>{' '}
                            Print Future Appointments
                        </button>

                        {loginDetails.permissions['ClientCardEditContactDetails'] &&
                            <button className="button merge-customers-button me-1" onClick={() => this.showMergeModal()}>
                                <span className="far fa-copy"></span>{' '}
                                Merge
                            </button>
                        }

                        <button className="button delete-customer-button" onClick={() => this.confirmDelete()}>
                            <span className="fa fa-trash"></span>{' '}
                            Delete
                        </button>

                    </div>
                }

            </InfoBar>

        </>);
    }

    renderSideContent() {
        const {
            isSummaryLoading,
            customer,
            isOnlineBookingAccountLoaded
        } = this.state;

        if (isSummaryLoading) {
            return (<Loader />);
        }
        const loginDetails = GlobalStateService.getValue('loginDetails');

        return (<>

            <CustomerSummary customerID={customer.customerID}>

                <div className="form">

                    {loginDetails.permissions['ClientCardViewAlertNotes'] && this.summaryFormHelper.renderFormGroups([
                        'patchTestDate',
                        'wowNotes',
                        'importantNotes'
                    ])}

                </div>

            </CustomerSummary>

            {isOnlineBookingAccountLoaded &&
                this.renderOnlineBookingAccount()
            }

        </>);
    }

    renderOnlineBookingAccount() {
        const {
            customer,
            onlineBookingAccount
        } = this.state;

        let content;
        if (!onlineBookingAccount) {
            content = (
                <div className="empty-text">
                    This customer has no online booking account
                </div>
            );
        }
        else if (onlineBookingAccount.isEmailConfirmed) {
            content = (<>
                <p>Status: Active and Confirmed <span className="fa fa-check" /></p>
            </>);
        } else {
            content = (<>
                <p>Status: Email address not confirmed yet (code: {onlineBookingAccount.confirmEmailToken})</p>
                <p>
                    <button type="button" className="button button-secondary" onClick={() => this.confirmOnlineBookingAccount()}>
                        Confirm
                    </button>
                </p>
            </>);
        }

        return (
            <div className="panel customer-online-booking-account-panel">

                <div className="panel-header">
                    Online Booking Account
                </div>

                <div className="panel-body">

                    {content}

                </div>
            </div>
        );
    }

    async changeTab(tab, id) {
        await this.setRecordUpdate(tab);
        this.props.history.push('/customer/' + id + '/' + tab);
    }

    async setRecordUpdate(tab) {
        if (this.state.isClientRecordUpdated && (tab != "appointment-notes")) {
            const confirm = await BootboxHelper.confirm(`Do you want to save notes before you exit?`);
            if (confirm) {
                await this.customerApptNotesRef.current.save();
            }
        }
        this.setState({
            isClientRecordUpdated: false
        });
        GlobalStateService.setValue('unsavedChanges', false);
    }

    renderMainContent() {
        const {
            photos,
            isLoading
        } = this.state;
        let {
            id,
            tab
        } = this.props.match.params;
        const loginDetails = GlobalStateService.getValue('loginDetails');
        if (!tab) {
            if (loginDetails.permissions['ClientCardViewContactDetails']) {
                tab = 'details';
            } else {
                tab = 'appointment-history';
            }
        }

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <TabPanel
                tab={tab}
                className={'customer-page-' + tab}
                onChangeTab={tab => { this.changeTab(tab, id) }}
            >

                {loginDetails.permissions['ClientCardViewContactDetails'] &&
                    <Tab id="details" title="Details" tabClassName="tab-teal">

                        <CustomerDetails
                            ref={this.customerDetailsRef}
                            id={id}
                            defaultName={this.props.match.params.defaultName}
                            onSave={this.onSave}
                            version={this.state.version}
                            setIsChanged={this.setIsChanged}
                        />

                    </Tab>
                }

                {!!id &&
                    <Tab id="appointment-history" title="History" tabClassName="tab-yellow" render={() =>
                        <CustomerApptList
                            type="past"
                            id={id}
                        />
                    }></Tab>
                }

                {!!id &&
                    <Tab id="future-appointments" title="Future Appts" tabClassName="tab-pink" render={() =>
                        <CustomerApptList
                            type="future"
                            id={id}
                        />
                    }></Tab>
                }

                {!!id && loginDetails.permissions['ClientCardViewAppointmentNotes'] &&
                    <Tab id="appointment-notes" title="Notes" tabClassName="tab-green" render={() =>
                        <CustomerApptNotes
                            ref={this.customerApptNotesRef}
                            id={id}
                            isRecordUpdated={isClientRecordUpdated => {
                                this.setState({ isClientRecordUpdated });
                                GlobalStateService.setValue('unsavedChanges', true);
                            }}
                        />
                    }></Tab>
                }

                {!!id &&
                    <Tab id="comms" title="SMS & Email" tabClassName="tab-purple" render={() =>
                        <CustomerComms
                            id={id}
                        />
                    }></Tab>
                }

                {!!id &&
                    <Tab id="forms" title="Consultation Forms" tabClassName="tab-orange" render={() =>
                        <CustomerForms
                            id={id}
                        />
                    }></Tab>
                }
                {/*
                {!!id &&
                    <Tab id="gallery" title="Gallery" tabClassName="tab-teal">
                        
                        <PhotoGallery
                            ref={this.photoGalleryRef}
                            photos={photos}
                            customerID={id}
                            allowUpload={true}
                            emptyText="This customer has no photos yet"
                            onUpload={(file, guid) => {
                                this.loadPhotos();
                            }}
                        />

                    </Tab>
                }
                */}

            </TabPanel>

        </>);
    }

}

export default withRouter(CustomerPage);