// Libs
import React from 'react';
import moment from 'moment';
import * as $ from 'jquery';

// Services & Helpers
import GlobalStateService       from 'services/GlobalStateService';
import DiaryService             from 'services/DiaryService';
import CustomerService          from 'services/CustomerService';
import SearchService            from 'services/SearchService';
import ServiceService           from 'services/ServiceService';
import InternalApptTypeService  from 'services/InternalApptTypeService';
import WaitingApptService       from 'services/WaitingApptService';
import TagService               from 'services/TagService';
import TextHelpers              from 'helpers/TextHelpers';
import BootboxHelper            from 'helpers/BootboxHelper';
import DateHelpers              from 'helpers/DateHelpers';
import FormHelper               from 'helpers/FormHelper';

// Components
import FloomlyComponent         from 'components/FloomlyComponent';
import Loader                   from 'components/reusable/Loader';
import Search                   from 'components/reusable/Search';
import CustomerSummary          from 'components/reusable/CustomerSummary';
import Money                    from 'components/reusable/Money';
import NewCustomerPanel         from 'components/pages/diary/NewCustomerPanel';
import BlockBookingModal        from 'components/pages/diary/BlockBookingModal';

//-------------------------------------------------------------------------------------------------------------------

class AppointmentEditor extends FloomlyComponent {

    constructor(props) {
        super(props);

        this.blockBookingModalRef = React.createRef();

        this.state = {
            serviceCategory: null,
            services: [],
            packages: [],

            isLoading: true
        };

        this.customerSearchQuery = '';

        this.clientInfo = GlobalStateService.getValue('clientInfo');

        this.loginDetails = GlobalStateService.getValue('loginDetails');

        this.removeCustomer = this.removeCustomer.bind(this);
        this.deselectServiceCategory = this.deselectServiceCategory.bind(this);

        this.removeWaitingAppt = this.removeWaitingAppt.bind(this);
        this.updateField = this.updateField.bind(this);
        this.formHelper = new FormHelper({
            fields: {
                apptTagIDs: {
                    label: 'Please select the appointment tags',
                    type: 'multi-select',
                    blankText: '(No tags)',
                    creatable: {
                        formatCreateLabel: (name) => 'Add tag: ' + name,
                        onCreateOption: (option) => {
                            return new Promise((resolve) => {
                                const appointmentTypeTags = [...this.state.appointmentTypeTags];
                                const newOption = {
                                    id: `new${appointmentTypeTags.length}-${option.name}`,
                                    name: option.name
                                };
                                appointmentTypeTags.push(newOption);
                                this.setState({
                                    appointmentTypeTags
                                }, () => {
                                    resolve(newOption);
                                });
                            });
                        }
                    },
                    getOptions: () => this.state.appointmentTypeTags.map(at => ({
                        id: at.tagID || at.id,
                        name: at.name
                    }))
                }
            },

            getValue: (fieldName, fieldInfo) => {
                return this.props.appointment.apptTagIDs
            },
            setValue: this.updateField            
        });
    }

    
    updateField(fieldName, value, fieldInfo) {
        let { apptTagIDs } = this.props.appointment;
        apptTagIDs = apptTagIDs || [];
        apptTagIDs = value;
        this.props.onChange('apptTagIDs', apptTagIDs);
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.allServices && props.services) {
            return { allServices: props.services };
        }
        return null;
    }

    async componentDidMount() {
        $('[data-appointment-id=' + this.props.appointmentID + ']').closest('.rbc-event').removeClass('highlighted');
        this.tryLoadRecentSearchLogs();

        // Load internal appt types
        if (this.loginDetails.permissions['DiaryCreateInternalAppointments']) {
            this.setState({ isLoadingInternalApptTypes: true });
            const internalApptTypes = await InternalApptTypeService.list();
            this.setState({
                internalApptTypes,
                isLoadingInternalApptTypes: false
            });
        }

        if (this.props.waitingApptID > 0 && this.props.waitingApptID != this.props.oldWaitingApptID) {
            const waitingAppt = await WaitingApptService.get(this.props.waitingApptID);
            this.setState({ waitingAppt });
            this.updateCustomer(waitingAppt.customer);

            while (this.props.appointment.appointmentServices.length > 0) {
                await this.removeService(0, false);
            }
            var index = 0;

            for (const wApptS of waitingAppt.waitingApptServices) {
                await this.addService(wApptS, null, null, false);
                await this.updateAppointmentServiceField(index, 'stylistUserID', wApptS.stylistUserID);
            }

            this.props.loadedWaitingAppt();
        }
        const appointmentTypeTags = await TagService.getApptTypeTags();
        this.setState({ isLoading: false, appointmentTypeTags });
    }

    async componentDidUpdate(oldProps) {
        if (oldProps.selectedTime != this.props.selectedTime || oldProps.selectedStylistID != this.props.selectedStylistID) {
            this.placeUnassignedAppointmentServices(this.props.selectedTime, this.props.selectedStylistID);
        }
    }

    async tryLoadRecentSearchLogs() {
        if (!this.state.recentSearchLogs && this.props.appointment && !this.props.appointment.customer) {
            const recentSearchLogs = await SearchService.listRecentSearchLogs('ApptCustomer');
            this.setState({
                recentSearchLogs: recentSearchLogs
            });
        }
    }

    //--------------------------------------------------------------------------------------------------------------------
    // Customer selection
    //--------------------------------------------------------------------------------------------------------------------

    selectCustomer(searchResult) {
        this.props.onChange('customer', {
            customerID: searchResult.id,
            name: searchResult.name
        });
        SearchService.addSearchLog({
            searchType: 'ApptCustomer',
            customerID: searchResult.id,
            query: this.customerSearchQuery
        });
    }

    updateCustomer(customer) {
        this.props.onChange('customer', customer);
    }

    addNewCustomer(query) {
        const names = (query ? TextHelpers.splitName(query, true) : "");
        this.props.onChange('customer', {
            customerID: 0,
            firstName: names.firstName,
            lastName: names.lastName,
            mobileTel: '',
            searchQuery: query
        });
    }

    removeCustomer() {
        this.props.onChange('customer', null);
        this.removeWaitingAppt();
    }

    loadRecentSearchLog(searchLog) {
        if (searchLog.customerID) {
            SearchService.addSearchLog({
                searchType: 'ApptCustomer',
                customerID: searchLog.customerID,
                query: searchLog.query
            });
            this.props.onChange('customer', {
                customerID: searchLog.customerID,
                name: searchLog.customer.firstName + ' ' + searchLog.customer.lastName
            });
        } else {
            const names = TextHelpers.splitName(searchLog.query, true);
            this.props.onChange('customer', {
                customerID: 0,
                searchLogID: searchLog.searchLogID,
                firstName: names.firstName,
                lastName: names.lastName,
                mobileTel: ''
            });
        }
    }

    //--------------------------------------------------------------------------------------------------------------------
    // Service selection
    //--------------------------------------------------------------------------------------------------------------------

    selectServiceCategory(serviceCategory) {
        this.setState({
            serviceCategory: serviceCategory,
            allServices: this.props.services,
            allPackages: this.props.packages,

            // Show all services & packages that belong to this category

            services: this.props.services.filter(s => s.serviceCategoryIDs.findIndex(i => String(i) == String(serviceCategory.serviceCategoryID)) != -1 && !s.isPackageOnly),
            packages: this.props.packages.filter(p => p.serviceCategoryIDs.findIndex(i => String(i) == String(serviceCategory.serviceCategoryID)) != -1)
        }, () => {
            document.querySelector('.main-content-inner').scrollTop = 270;
        });
    }

    deselectServiceCategory() {
        this.setState({
            serviceCategory: null,
            services: [],
            packages: []
        });
    }

    async addPackage(pkg, serviceCategory) {
        const appointmentPackages = [...this.props.appointment.appointmentPackages || []];

        // Add the package
        let lowestID = 0;
        appointmentPackages.forEach(ap => {
            if (ap.appointmentPackageID < lowestID) {
                lowestID = ap.appointmentPackageID;
            }
        });
        const apptPackage = {
            appointmentPackageID: lowestID - 1,
            packageID: pkg.packageID,
            package: pkg,
            pricingType: pkg.pricingType
        };
        if (pkg.pricingType == 'fixed') {
            apptPackage.total = pkg.fixedPriceOrDiscountProportion;
        }
        appointmentPackages.push(apptPackage);
        await this.props.onChange('appointmentPackages', appointmentPackages);

        // Add each service
        for (var i = 0; i < pkg.serviceIDs.length; i++) {
            const serviceID = pkg.serviceIDs[i];
            const service = this.state.allServices.find(s => s.serviceID == serviceID);
            await this.addService(service, serviceCategory, apptPackage);
        }
    }

    async addService(service, serviceCategory, appointmentPackage, updateTime = true) {
        const {
            appointment
        } = this.props;
        let start = null, stylistUserID = null;

        const appointmentServices = [...this.props.appointment.appointmentServices || []];

        // Get more service details from service
        service = await ServiceService.get(service.serviceID || service.id);

        let cooldownPeriod = 0;

        // Add service at selected time / stylist if there is a selection
        if (this.props.selectedTime && this.props.selectedStylistID) {
            start = moment(moment(this.props.date).format('YYYY-MM-DD') + ' ' + this.props.selectedTime);
            stylistUserID = this.props.selectedStylistID;

            // Add cooldown from the previous appointment if applicable
            if (start && appointmentServices.length > 0) {
                cooldownPeriod = DiaryService.getCooldownPeriod(appointmentServices[appointmentServices.length - 1], service);
            }

        } else {

            // If not then add to the end of the last service that has a time (if any)
            for (let i = appointmentServices.length - 1; i >= 0; i--) {
                const apptService = appointmentServices[i];
                if (apptService.time) {
                    start = moment(moment(this.props.date).format('YYYY-MM-DD') + ' ' + moment(apptService.time).format('HH:mm'));
                    start.add(apptService.durationMins, 'minutes');
                    stylistUserID = apptService.stylistUserID;
                    cooldownPeriod = DiaryService.getCooldownPeriod(apptService, service);
                    break;
                }
            }

        }

        if (start) {
            start.add(cooldownPeriod, 'minutes');
        }

        // Add service
        let lowestID = 0;
        appointmentServices.forEach(asv => {
            if (asv.appointmentServiceID < lowestID) {
                lowestID = asv.appointmentServiceID;
            }
        });

        const newApptService = {
            appointmentServiceID: lowestID - 1,
            appointmentID: 0,
            stylistUserID,
            service,
            serviceCategory,
            time: (start && updateTime ? start.toDate() : null),
            durationMins: this.props.getStylistServiceDuration(service.serviceID, stylistUserID)  ?? service.durationMins,//get duration time from service band
            appointmentPackageID: (appointmentPackage ? appointmentPackage.appointmentPackageID : null)
        };

        // Get price
        if (appointmentPackage && appointmentPackage.pricingType == 'fixed') {
            newApptService.total = 0;
        } else {
            let pkg;
            if (appointmentPackage) {
                pkg = this.props.packages.find(p => p.packageID == appointmentPackage.packageID);
            }
            newApptService.total = (stylistUserID ?
                this.props.getServicePrice(service.serviceID, stylistUserID, pkg) :
                0
            );
        }

        // Insert service in correct location 
        if (appointment.status == 'checkedOut') {
            // If appt checked out, add it to the end
            appointmentServices.push(newApptService);
        } else {
            // If appt not checked out, add it in the right place based on sequence
            let insertIndex = appointmentServices.length;
            let insertTime = null;
            let isInsertedAfterColour = false;

            // Insert before any services with a higher sequence
            if (appointmentServices.length > 0) {
                for (var i = appointmentServices.length - 1; i >= 0; i--) {
                    const otherApptService = appointmentServices[i];
                    if (service.sequence < otherApptService.service.sequence) {
                        insertIndex = i;
                        insertTime = otherApptService.time;
                        isInsertedAfterColour = false;
                    } else if (otherApptService.service.isColour) {
                        isInsertedAfterColour = true;
                    }
                }
            }
            appointmentServices.splice(insertIndex, 0, newApptService);

            // If we inserted the appt before the end, push services down if they overlap
            if (insertTime) {
                newApptService.time = insertTime;
                for (let i = insertIndex - (isInsertedAfterColour ? 1 : 0); i < appointmentServices.length - 1; i++) {
                    if (i < 0) continue;
                    const currApptService = appointmentServices[i];
                    const nextApptService = appointmentServices[i + 1];
                    const duration = currApptService.durationMins + DiaryService.getCooldownPeriod(currApptService, nextApptService.service);
                    const endTime = moment(currApptService.time);
                    endTime.add(duration, 'minutes');
                    const nextEndTime = moment(nextApptService.time);
                    if (endTime.isSameOrAfter(nextEndTime)) {
                        const delta = moment.duration(endTime.diff(nextEndTime)).asMinutes();
                        nextApptService.time = moment(nextApptService.time).add(delta, 'minutes').toDate();
                    }
                }
            }
        }

        await this.props.onChange('appointmentServices', appointmentServices);

        // Warn if patch test is due
        const customerID = this.props.appointment.customer.customerID;
        if (service.requiresPatchTest && customerID) {
            this.checkForPatchTest(customerID);
        }

        // If time slot selected, select the next slot after the last service
        if (updateTime && this.props.selectedTime) {
            const lastApptService = appointmentServices[appointmentServices.length - 1];
            const newSlot = moment(lastApptService.time).add(lastApptService.durationMins, 'minutes');
            await this.props.onSlotChange(newSlot.format('HH:mm'));
        }
    }

    async checkForPatchTest(customerID) {
        try {
            const patchTest = await CustomerService.getPatchTestDue(customerID);
            if (patchTest.patchTestDue) {
                BootboxHelper.alert(
                    (patchTest.patchTestDate === undefined ? 'No patch test history' : 'Patch test is due.'),
                    { title: 'Patch Test' }
                );
            }
        } catch (e) {
            // Ignore errors with this
        }
    }

    confirmRemoveService(index) {
        // TODO show confirmation box?
        this.removeService(index);
    }

    async removeService(index, updateTime = true) {
        const appointmentServices = [...this.props.appointment.appointmentServices]

        // Update service list
        const removedApptService = appointmentServices.splice(index, 1)[0];
        let apptServiceDelta = 0;

        // Shift other services up to fill the gap left by the removed service
        if (appointmentServices.length >= 1 && index < appointmentServices.length) {
            apptServiceDelta = moment(appointmentServices[index].time).diff(moment(removedApptService.time), 'minutes');

            // Removed 06/09/2022 as this seems to not actually help but causes a bug
            // If removing this service would then require a cooldown gap, make sure it is added
            // (e.g. Colour, Colour, Haircut - remove the second Colour, and there must be a gap between the remaining Colour and the haircut)
            // NOTE: TEMPORARILY RESTORED UNTIL WE CAN FIGURE IT OUT PROPERLY AS THIS IS STILL BUGGY
            //if (index > 0) {
            //    const cooldownTemp = DiaryService.getCooldownPeriod(appointmentServices[index - 1], appointmentServices[index]);
            //    apptServiceDelta -= cooldownTemp;
            //}

            // Shift all subsequent services down by the delta amount
            for (var i = index; i < appointmentServices.length; i++) {
                const thisApptService = { ...appointmentServices[i] };
                const time = moment(thisApptService.time).add(-apptServiceDelta, 'minutes');
                if (time.isValid()) {
                    thisApptService.time = time.toDate();
                }
                appointmentServices[i] = thisApptService;
            }
        }

        await this.props.onChange('appointmentServices', appointmentServices);

        if (updateTime) {
            // Move the cursor up too
            let delta = removedApptService.durationMins;
            const newTime = moment(moment(this.props.date).format('YYYY-MM-DD') + ' ' + this.props.selectedTime);
            if (newTime.isValid()) {
                if (index > 0) {
                    const removedServiceCooldown = DiaryService.getCooldownPeriod(appointmentServices[index - 1], removedApptService.service);
                    delta += removedServiceCooldown;
                } else {
                    delta = Math.max(delta, apptServiceDelta);
                }
                newTime.add(-delta, 'minutes');
                if (delta != 0) {
                    this.props.onSlotChange(newTime.format('HH:mm'));
                }
            }
        }
        return appointmentServices;
    }

    confirmRemovePackage(id) {
        // TODO show confirmation box?
        this.removePackage(id);
    }

    async removePackage(id) {
        let appointmentServices = this.props.appointment.appointmentServices;
        const appointmentPackages = [...this.props.appointment.appointmentPackages]
        const index = appointmentPackages.findIndex(ap => ap.appointmentPackageID == id);
        const appointmentPackage = appointmentPackages.splice(index, 1)[0];

        let anyDeleted = false;
        do {
            anyDeleted = false;

            for (var i = appointmentServices.length - 1; i >= 0; i--) {
                const appointmentService = appointmentServices[i];
                if (appointmentService.appointmentPackageID == appointmentPackage.appointmentPackageID) {
                    appointmentServices = await this.removeService(i);
                    anyDeleted = true;
                    break;
                }
            }

        } while (anyDeleted);

        await this.props.onChange('appointmentPackages', appointmentPackages);
    }

    placeUnassignedAppointmentServices(newTime, newStylistID) {
        const appointmentServices = [...this.props.appointment.appointmentServices];
        let anyPlaced = false;

        newTime = moment(moment(this.props.date).format('YYYY-MM-DD') + ' ' + newTime);

        let lastApptService = null;
        appointmentServices.forEach(apptService => {
            if (!apptService.time && apptService.appointmentServiceID) {
                // Determine how much to move the time slot
                let delta = 0;
                if (lastApptService) {
                    delta = lastApptService.durationMins + DiaryService.getCooldownPeriod(lastApptService, apptService.service);
                    newTime.add(delta, 'minutes');
                }

                // Assign time
                apptService.time = newTime.toDate();
                apptService.stylistUserID = newStylistID;

                // Get price
                apptService.total = this.props.getServicePrice(apptService.service.serviceID, newStylistID);
                anyPlaced = true;
            }
            lastApptService = apptService;
        });

        this.props.onChange('appointmentServices', appointmentServices);

        if (anyPlaced) {
            if (lastApptService) {
                newTime.add(lastApptService.durationMins, 'minutes');
            }
            this.props.onSlotChange(newTime.format('HH:mm'));
        }
    }

    async selectInternalApptType(internalApptType) {
        const time = moment(moment(this.props.date).format('YYYY-MM-DD') + ' ' + this.props.selectedTime);
        await this.props.onChangeMultiple({
            stylistUserID: this.props.selectedStylistID,
            date: time.format('YYYY-MM-DD'),
            time: time.format('YYYY-MM-DDTHH:mm'),
            durationMins: internalApptType.defaultDuration,
            internalApptType: internalApptType
        });
        this.props.onSaveClicked();
    }

    updateAppointmentServiceField(index, field, value) {
        const appointmentServices = [...this.props.appointment.appointmentServices];
        const apptService = { ...appointmentServices[index] };

        // Update field value
        apptService[field] = value;

        // If changing stylist, get correct price
        if (field == 'stylistUserID') {
            let canUpdatePrice = true;
            if (apptService.appointmentPackageID) {
                const appointmentPackage = this.props.appointment.appointmentPackages.find(ap => ap.appointmentPackageID == apptService.appointmentPackageID);
                if (appointmentPackage) {
                    canUpdatePrice = !appointmentPackage.isFixedPrice; // TODO
                }
            }
            if (canUpdatePrice) {
                apptService.total = this.props.getServicePrice(apptService.service.serviceID, value)
            }
            apptService.durationMins = this.props.getStylistServiceDuration(apptService.service.serviceID, value);//get duration from service band or get default duration
        }

        appointmentServices[index] = apptService;
        this.props.onChange('appointmentServices', appointmentServices);
    }

    updateAppointmentPackageField(id, field, value) {
        const appointmentPackages = [...this.props.appointment.appointmentPackages];
        const index = appointmentPackages.findIndex(a => a.appointmentPackageID == id);
        const appointmentPackage = { ...appointmentPackages[index] };
        appointmentPackage[field] = value;
        appointmentPackages[index] = appointmentPackage;
        this.props.onChange('appointmentPackages', appointmentPackages);
    }

    removeWaitingAppt() {
        this.setState({ waitingAppt: null });
    }

    //--------------------------------------------------------------------------------------------------------------------
    // Block booking
    //--------------------------------------------------------------------------------------------------------------------

    trySetIsBlockBooking(value) {
        if (value) {
            const anyNoTimeOrStylist = this.props.appointment.appointmentServices.some(asv => !asv.time || !asv.stylistUserID);
            if (anyNoTimeOrStylist) {
                BootboxHelper.alert('Please select a stylist and start time first');
                return;
            }
            this.showBlockBookingModal();
        }
        this.props.onChange('isBlockBooking', value);
    }

    async showBlockBookingModal() {
        const {
            appointment,
            diaryInterval
        } = this.props;

        let blockBooking = appointment.blockBooking;
        if (!blockBooking) {
            blockBooking = {
                customer: appointment.customer,
                startDate: this.props.date,
                frequencyDays: 7,
                leewayMins: diaryInterval,
                template: { ...appointment }
            };

            // Set preferred start time to the time of the first service, if there is a time set 
            const firstServiceTime = appointment.appointmentServices[0].time;
            if (firstServiceTime) {
                blockBooking.preferredStartTime = moment(firstServiceTime).format('HH:mm');
            }
        }
        await this.blockBookingModalRef.current.show({
            blockBooking: blockBooking
        });
        this.props.onBlockBookingSaved();

        //if (blockBooking) {
        //    this.props.onChange('blockBooking', blockBooking)
        //}
    }

    //--------------------------------------------------------------------------------------------------------------------
    // Render
    //--------------------------------------------------------------------------------------------------------------------

    renderCustomerFinder() {
        const {
            recentSearchLogs
        } = this.state;

        if (!this.loginDetails.permissions['DiaryCreateAppointments']) {
            return null;
        }

        return (<>
            <div className="panel">

                <div className="panel-body">

                    <Search
                        className="search-box"
                        autoFocus={true}
                        placeholder="Type customer's name..."
                        maxResults={50}
                        defaultQuery={this.customerSearchQuery}
                        renderWrapper={(input, results, info) => (
                            <>
                                <div className="input-group">
                                    {input}
                                    <button
                                        className="button add-customer-button"
                                        onClick={e => this.addNewCustomer(info.query)}
                                        title="Add customer with this name"
                                    >
                                        <i className="fas fa-plus" />
                                    </button>
                                </div>
                                {results && results.length > 0 &&
                                    <div className="list search-results">
                                        {results}
                                    </div>
                                }
                            </>
                        )}
                        search={async (query, setResults, maxResults, state) => {
                            this.customerSearchQuery = query;
                            const results = await SearchService.search(query, ['Customer'], { maxResults, state });
                            setResults(results);
                        }}
                        renderResult={(customer, index) =>
                            <div key={index} className="search-result list-item" onMouseDown={e => this.selectCustomer(customer)}>
                                {/*<img className="user-icon list-item-icon" src=userIconImage />*/}
                                <div className="list-item-name">
                                    {customer.name}
                                    <div className="list-subitem-number">
                                        {customer.customerTel} {customer.customerTel && customer.customerEmail && <>-</>} {customer.customerEmail}
                                    </div>
                                </div>
                            </div>
                        }
                    />

                </div>

            </div>

            {recentSearchLogs && recentSearchLogs.length > 0 &&
                <div className="panel">
                    <div className="panel-header">
                        Recent Searches
                    </div>
                    <div className="panel-body">

                        <ul className="list">
                            {recentSearchLogs.map(searchLog =>
                                <li key={searchLog.searchLogID} onClick={e => this.loadRecentSearchLog(searchLog)}>
                                    {/*<img className="user-icon list-item-icon" src=userIconImage />*/}
                                    <div className="list-item-name">
                                        {searchLog.customer ?
                                            (searchLog.customer.firstName + ' ' + searchLog.customer.lastName) :
                                            searchLog.query
                                        }
                                    </div>
                                </li>
                            )}
                        </ul>

                    </div>

                </div>
            }

        </>);
    }

    highlightEvent(id) {
        $('#Event-' + id).closest('.rbc-event').addClass('highlighted');
    }

    unhighlightEvent(id) {
        $('#Event-' + id).closest('.rbc-event').removeClass('highlighted');
    }

    highlightEvents(appointmentPackageID) {
        $('.rbc-event-appt-package-' + appointmentPackageID).closest('.rbc-event').addClass('highlighted');
    }

    unhighlightEvents(appointmentPackageID) {
        $('.rbc-event-appt-package-' + appointmentPackageID).closest('.rbc-event').removeClass('highlighted');
    }

    renderEditor() {
        const {
            serviceCategory,
            packages
        } = this.state;
        const {
            appointment,
            diaryInterval
        } = this.props;

        const services = this.state.services.filter(s => !s.isPackageOnly);
        let lastApptService = null;

        return (<>

            {/* Customer summary */}
            {!!appointment.customer.customerID &&
                <CustomerSummary
                    customerID={appointment.customer.customerID}
                    onChangeClicked={this.removeCustomer}
                />
            }

            {/* New Customer */}
            {!appointment.customer.customerID &&
                <NewCustomerPanel
                    customer={appointment.customer}
                    onChange={customer => this.updateCustomer(customer)}
                    onChangeClicked={this.removeCustomer}
                />
            }

            {this.state.waitingAppt && <>
                {this.renderWaitingApptInfo()}
            </>}

            {/* Save buttons */}
            {(
                (appointment.appointmentServices && appointment.appointmentServices.length > 0) ||
                (appointment.appointmentPackages && appointment.appointmentPackages.length > 0)
            ) && <>

                    {/* Save */}
                    <button className="button button-primary button-small" onClick={this.props.onSaveClicked}>
                        <i className='fas fa-check'></i>{' '}
                        {appointment.status == 'provisional' ? 'Confirm booking' : 'Save'}
                    </button>

                </>}

            {/* Service category selector */}
            {!serviceCategory &&
                this.props.serviceCategories &&
                this.props.serviceCategories.length > 0 &&
                <div className="panel">
                    <div className="panel-header">
                        Service Categories
                    </div>
                    <div className="panel-body">

                        {/* Service search */}
                        <div className="service-search search-box-absolute">
                            <Search
                                className="search-box"
                                autoFocus={true}
                                maxResults={50}
                                placeholder="Search for a service..."
                                search={async (query, setResults, maxResults, state) => {
                                    const results = await SearchService.search(query, ['Service','Package'], { maxResults, state });
                                    results.sort((a, b) => {
                                        if (a.type == b.type) {
                                            return b.score - a.score;
                                        } else {
                                            return a.type == 'Package' ? -1 : 1;
                                        }
                                    });
                                    setResults(results);
                                }}
                                renderResult={(result, index, info) =>
                                    <div key={index} className="search-result list-item" onMouseDown={e => {
                                        if (result.type == 'Package') {
                                            const pkg = this.props.packages.find(p => p.packageID == result.id);
                                            this.addPackage(pkg);
                                        } else {
                                            result.serviceID = result.id; // TODO just use 'id' everywhere
                                            this.addService(result);
                                        }
                                        info.clearSearch();
                                        info.focus();
                                    }}>
                                        <div className="list-item-name">
                                            {result.type == 'Package' && <>
                                                <span className="fas fa-cubes"></span>{' '}
                                            </>}
                                            {result.name}
                                        </div>
                                    </div>
                                }
                            />
                        </div>

                        <ul className="list">
                            {this.props.serviceCategories.map((serviceCategory, index) =>
                                <li key={serviceCategory.serviceCategoryID} onClick={e => this.selectServiceCategory(serviceCategory)}>
                                    {serviceCategory.name}
                                </li>
                            )}
                        </ul>

                    </div>
                </div>
            }

            {/* Service / package selector */}
            {serviceCategory &&
                <div className="panel service-selector-panel">
                    <div className="panel-header">
                        {serviceCategory.name}
                    </div>
                    <div className="panel-body service-selector">

                        <button className="button button-secondary button-small" onClick={this.deselectServiceCategory}>
                            <span className="fa fa-arrow-left"></span>{' '}
                            Back
                        </button>

                        {/* PACKAGES */}
                        <ul className="list service-list">
                            {packages.map((pkg, index) =>
                                <li key={pkg.packageID} onClick={e => this.addPackage(pkg, serviceCategory)}>
                                    <span className="service-list-icon">
                                        <span className="fas fa-cubes"></span>
                                    </span>
                                    <span className="service-list-name">{pkg.name}</span>

                                    {pkg.fixedPrice > 0 &&
                                        <span className="service-list-price">
                                            <Money amount={pkg.fixedPrice} />
                                        </span>
                                    }

                                </li>
                            )}

                            {/* SERVICES */}

                            {services.map((service, index) =>
                                <li key={service.serviceID} onClick={e => this.addService(service, serviceCategory)}>
                                    <span className="service-list-name">{service.name}</span>

                                    <span className="service-list-price">
                                        {service.minPrice === service.maxPrice &&
                                            <Money amount={service.minPrice} />
                                        }
                                        {service.minPrice !== service.maxPrice &&
                                            <>
                                                <Money amount={service.minPrice} />
                                                {' - '}
                                                <Money amount={service.maxPrice} />
                                            </>
                                        }
                                    </span>

                                </li>
                            )}
                        </ul>

                    </div>
                </div>
            }

            {/* Selected services, save buttons, block booking modal */}
            {(appointment.appointmentServices && appointment.appointmentServices.length > 0) && <>

                <div className="panel">
                    <div className="panel-header">
                        Selected Services
                    </div>
                    <div className="panel-body">
                        <ul className="list service-list">

                            {appointment.appointmentServices && appointment.appointmentServices.map((apptService, index) => {
                                const content = (
                                    <React.Fragment key={index + '-' + apptService.appointmentServiceID}>
                                        {this.renderApptService(apptService, index, lastApptService)}
                                    </React.Fragment>
                                );
                                lastApptService = apptService;
                                return content;
                            })}

                            {appointment.serviceTotal > 0 && <>
                                {/* Total */}
                                <li className="non-selectable total-row">

                                    <span className="service-list-icon">
                                        <span className="fas fa-money-bill"></span>
                                    </span>

                                    <span className="service-list-name">
                                        Total
                                    </span>

                                    <span className="service-list-price">
                                        <Money amount={appointment.serviceTotal} />
                                    </span>

                                    <span className="service-list-icon-right">

                                        {/* Blank space */}

                                    </span>

                                </li>
                            </>}

                            {/* Client Quoted */}
                            <li className="client-quoted-row" onClick={e => {
                                this.props.onChange('isCustomerQuoted', !appointment.isCustomerQuoted)
                            }}>

                                <span className="service-list-icon">
                                    <span className="fas fa-comment"></span>
                                </span>

                                <span className="service-list-name">

                                    Client quoted

                                </span>

                                <span className="service-list-price">

                                    <input
                                        type="checkbox"
                                        checked={appointment.isCustomerQuoted || false}
                                        onChange={e => { /* NOP */ }}
                                    />

                                </span>

                                <span className="service-list-icon-right">

                                    {/* Blank space */}

                                </span>

                            </li>

                            {/* Requested Stylist */}
                            <li className="requested-stylist-row" onClick={e => {
                                this.props.onChange('isRequestedStylist', !appointment.isRequestedStylist)
                            }}>

                                <span className="service-list-icon">
                                    <span className="fas fa-hand-point-right"></span>
                                </span>

                                <span className="service-list-name">

                                    Requested stylist

                                </span>

                                <span className="service-list-price">

                                    <input
                                        type="checkbox"
                                        checked={appointment.isRequestedStylist || false}
                                        onChange={e => { /* NOP */ }}
                                    />

                                </span>

                                <span className="service-list-icon-right">

                                    {/* Blank space */}

                                </span>

                            </li>

                            {/* Block booking */}
                            {appointment.status != 'checkedOut' && ((appointment.appointmentServices && appointment.appointmentServices.length > 0) || (appointment.appointmentPackages && appointment.appointmentPackages.length > 0)) &&
                                <li className="block-booking-row" onClick={e => {
                                    const value = !appointment.isBlockBooking;
                                    this.trySetIsBlockBooking(value);
                                }}>

                                    <span className="service-list-icon">
                                        <span className="fas fa-list-ol"></span>
                                    </span>

                                    <span className="service-list-name">

                                        Block booking

                                    </span>

                                    <span className="service-list-price">

                                        <input
                                            type="checkbox"
                                            checked={appointment.isBlockBooking || false}
                                            onChange={e => { /* NOP */ }}
                                        />

                                    </span>

                                    <span className="service-list-icon-right">

                                        {appointment.isBlockBooking &&
                                            <button className="button" onClick={e => { e.stopPropagation(); this.showBlockBookingModal() }}>
                                                <span className="fa fa-search"></span>
                                            </button>
                                        }

                                    </span>

                                </li>
                            }

                        </ul>

                        {appointment.appointmentServices.some(as => !as.time && !as.appointmentServiceID) &&
                            <div className="info-text">
                                Click on the diary to <br /> select a time for the new services
                            </div>
                        }

                    </div>

                </div>
                <div className="panel">
                    <div className="panel-header">
                        Tags
                    </div>
                    <div className="panel-body">
                        {this.formHelper.renderFormGroups([
                            'apptTagIDs'
                        ])}
                    </div>
                </div>

                {/* Save */}
                <button className="button button-primary button-small" onClick={this.props.onSaveClicked}>
                    <i className='fas fa-check'></i>{' '}
                    {appointment.status == 'provisional' ? 'Confirm booking' : 'Save'}
                </button>

                {/* Block booking */}
                <BlockBookingModal
                    customer={this.props.appointment.customer}
                    ref={this.blockBookingModalRef}
                />

            </>}

        </>);
    }

    renderApptService(apptService, index, lastApptService) {
        const { appointment, diaryInterval } = this.props;
        const apptPackage = (appointment.appointmentPackages || []).find(ap => ap.appointmentPackageID == apptService.appointmentPackageID);
        const isFixedPricePackage = !!apptPackage && apptPackage.pricingType == 'fixed';
        const showPackageHeader = !!apptPackage && (!lastApptService || apptService.appointmentPackageID != lastApptService.appointmentPackageID);

        return (<>
            {showPackageHeader &&
                this.renderPackageHeader(apptPackage)
            }

            <li
                id={'Service-' + apptService.appointmentServiceID}
                className={
                    (apptService.stylistUserID ? 'colour-coded-item ' : '') +
                    (apptService.warnings && apptService.warnings.length > 0 ? 'service-list-has-warning' : '')
                }
                onMouseOver={e => this.highlightEvent(apptService.appointmentServiceID)}
                onMouseOut={e => this.unhighlightEvent(apptService.appointmentServiceID)}
                style={
                    (apptService.stylistUserID && this.props.stylistsLookup[apptService.stylistUserID]) ?
                        { borderLeft: '4px solid ' + this.props.stylistsLookup[apptService.stylistUserID].diaryColour }
                        : {}
                }
            >

                <span className={'service-list-icon ' + (!!apptService.appointmentPackageID && 'service-list-icon-indent')}>
                    <span className="fas fa-cut"></span>
                </span>

                <span className="service-list-name">
                    {apptService.service.name}
                </span>

                <span className="service-list-time mobile-only">

                    <select
                        value={apptService.time ? moment(apptService.time).format('HH:mm') : ''}
                        onChange={e => {
                            let time = e.target.value;
                            if (time) {
                                time = moment(moment(appointment.date).format('YYYY-MM-DD') + ' ' + time).toDate();
                            }
                            this.updateAppointmentServiceField(index, 'time', time);
                        }}
                    >
                        <option value="">Time</option>
                        {DateHelpers.listTimes(diaryInterval, '00:00', '23:59').map(t =>
                            <option key={t.value} value={t.value}>{t.text}</option>
                        )}
                    </select>

                </span>

                <span className="service-list-price">
                    {apptService.service.isPriceOnConsultation ?
                        <abbr title="Price on Consultation">POC</abbr> :
                        <>
                            {apptService.time && (!apptService.appointmentPackageID || !isFixedPricePackage) &&
                                <input type="number" value={apptService.total} onChange={e => this.updateAppointmentServiceField(index, 'total', Number(e.target.value) || '')} />
                            }
                            {!apptService.time && (!apptService.appointmentServiceID || apptService.appointmentServiceID <= 0) &&
                                <span className="fas fa-question-circle" title="Please click on the diary to select a time"></span>
                            }
                        </>
                    }
                </span>

                {!apptService.appointmentPackageID &&
                    <span className="floating-controls">

                        <button className="button customer-summary-change-button" onClick={e => this.confirmRemoveService(index)}>
                            <span className="fa fa-times"></span>
                        </button>

                    </span>
                }

                {apptService.warnings && apptService.warnings.map((warning, index) =>
                    <div key={index} className="service-list-warning">
                        <span className="fas fa-exclamation-triangle"></span>
                        {warning}
                    </div>
                )}
            </li>

            {!!apptService.stylistUserID && !!apptService.time &&
                <li className="non-selectable">

                    <span className="service-list-icon" style={{ marginLeft: 15 }}>
                        <span className="fa fa-user" />
                    </span>

                    <span className="service-list-name" style={{ marginRight: 0 }}>

                        <select
                            value={apptService.stylistUserID}
                            style={{ width: '100%' }}
                            onChange={e => this.updateAppointmentServiceField(index, 'stylistUserID', parseInt(e.target.value))}
                        >
                            {
                                !apptService.time &&
                                <option value="">Salon Sales</option>
                            }
                            {this.clientInfo.users.filter(u => u.isOnDiary).map(u =>
                                <option key={u.userID} value={u.userID}>{u.nickname}</option>
                            )}
                        </select>

                    </span>

                </li>
            }

        </>);
    }

    renderPackageHeader(apptPackage) {
        const id = apptPackage.appointmentPackageID;
        return (
            <li
                key={'Package-' + id}
                id={'Package-' + id}
                className="colour-coded-item service-list-package"
                style={{ borderLeft: '4px solid black' }}
                onMouseOver={e => this.highlightEvents(id)}
                onMouseOut={e => this.unhighlightEvents(id)}
            >

                <span className="service-list-icon">
                    <span className="fas fa-cubes"></span>
                </span>

                <span className="service-list-name">
                    {apptPackage.package.name}
                </span>

                {apptPackage.pricingType == 'fixed' &&
                    <span className="service-list-price">
                        <input
                            type="number"
                            value={apptPackage.total}
                            onChange={e => this.updateAppointmentPackageField(id, 'total', Number(e.target.value) || '')}
                        />
                    </span>
                }

                <span className="floating-controls">

                    <button className="button customer-summary-change-button" onClick={e => this.confirmRemovePackage(id)}>
                        <span className="fa fa-times"></span>
                    </button>

                </span>

            </li>
        );
    }

    renderInternalApptTypeFinder() {
        const {
            internalApptTypes,
            isLoadingInternalApptTypes
        } = this.state;

        if (isLoadingInternalApptTypes) {
            return (<Loader />);
        }

        if (!internalApptTypes || internalApptTypes.length == 0) {
            return null;
        }

        return (<>
            <div className="panel internal-appt-type-panel">
                <div className="panel-header">
                    Internal Appointment
                </div>
                <div className="panel-body">

                    <ul className="list">
                        {internalApptTypes.map(iat =>
                            <li key={iat.internalApptTypeID} onClick={e => this.selectInternalApptType(iat)}>
                                {/*<img className="user-icon list-item-icon" src=userIconImage />*/}
                                <div className="list-item-name">
                                    {iat.name}
                                </div>
                            </li>
                        )}
                    </ul>

                </div>
            </div>

        </>);
    }

    renderWaitingApptInfo() {
        return (<>
            <div className="panel waiting-appt-panel">
                <button className="button waiting-appt-remove-button" onClick={this.removeWaitingAppt}>
                    <span className="fa fa-times"></span>
                </button>

                <div className="panel-header">
                    Waiting Appointment
                </div>
                <div className="panel-body">
                    {this.state.waitingAppt.waitingApptServices && <>
                        <b>Services:</b><br />
                        {this.state.waitingAppt.waitingApptServices.map(wApptS =>
                            <>{wApptS.service.name} by
                                {wApptS.stylistUserID && <> {wApptS.stylistUser.nickname}</>}
                                {!wApptS.stylistUserID && <> Any</>}
                                <br /></>
                        )}
                    </>}
                    <div style={{ display: "flex" }}>
                        <div style={{flex: "50%"} }>
                            {(this.state.waitingAppt.timeOfDay) && <>
                                <b>Preferred Time:</b><br />
                            </>}
                            {this.state.waitingAppt.timeOfDay && <>
                                {this.state.waitingAppt.timeOfDay}<br />
                            </>}
                        </div>
                        <div style={{flex: "50%"} }>
                            <b>Preferred Dates:</b><br />
                            {moment(this.state.waitingAppt.primaryDate).format('DD/MM/YYYY')}<br />
                            {this.state.waitingAppt.secondaryDate && <>
                                {moment(this.state.waitingAppt.secondaryDate).format('DD/MM/YYYY')}<br />
                            </>}
                            {this.state.waitingAppt.tertiaryDate && <>
                                {moment(this.state.waitingAppt.tertiaryDate).format('DD/MM/YYYY')}<br />
                            </>}
                        </div>
                    </div>
                    {(this.state.waitingAppt.timeNotes || this.state.waitingAppt.notes) && <>
                        <b>Notes:</b><br />
                        {this.state.waitingAppt.timeNotes && <>
                            {this.state.waitingAppt.timeNotes}<br />
                        </>}
                        {this.state.waitingAppt.notes}
                    </>}
                </div>
            </div>
        </>);
    }

    render() {
        const {
            isLoading
        } = this.state;


        if (isLoading || this.props.appointment.internalApptType) {
            return (<Loader />);
        }

        //when={isEditMode && hasChanges}
        return (<>

            {/*
            <Prompt
                when
                message={location =>
                    `If you navigate away without pressing Save, any changes you have made will be lost. Are you sure you want to continue?`
                }
            />
            */}

            {/* Select customer or internal appointment */}
            {!this.props.appointment.customer && <>
                {this.renderCustomerFinder()}
                {!!this.props.selectedStylistID && !!this.props.selectedTime && this.renderInternalApptTypeFinder()}
            </>}

            {/* Edit Appointment */}
            {this.props.appointment.customer &&
                this.renderEditor()
            }

            {/* Edit internal appointment */}
            {/*
            {this.props.appointment.internalApptType && 
                this.renderInternalAppt()
            }*/}

            <div className="flex-row">

                {/* Back */}
                <button className="button button-tertiary button-small flex-4" onClick={this.props.onCancelClicked}>
                    <i className='fas fa-arrow-left'></i>{' '}
                    Back
                </button>

                {/* Change Date */}
                {this.props.appointment.customer && this.props.appointment.status != 'checkedOut' &&
                    <button className="button button-secondary button-small flex-6" onClick={this.props.onChangeDateClicked}>
                        <i className='fas fa-calendar-alt'></i>{' '}
                        Change Date
                    </button>
                }
            </div>

        </>);
    }

}

export default AppointmentEditor;