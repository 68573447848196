// Libs
import React from 'react';
import { Portal } from 'react-portal';
import moment from 'moment';

// Services & Helpers
import BaseService from 'services/BaseService';
import GlobalStateService from 'services/GlobalStateService';
import CustomerService from 'services/CustomerService';
import DiaryService from 'services/DiaryService';
import TextHelpers from 'helpers/TextHelpers';

// Components
import ClientRating from 'components/reusable/ClientRating';

class ApptTooltip extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            apptID: null,
            apptServiceID: null,
            appt: null,
            isVisible: false
        };
    }

    async show(opt) {
        this.x = opt.x;
        this.y = opt.y;
        this.apptID = opt.apptID;
        this.apptServiceID = opt.apptServiceID;
        this.setState({ isVisible: true });
        window.clearTimeout(this.timeout);
        this.timeout = window.setTimeout(() => this.showInner(opt), 100);
    }

    async showInner(opt) {
        const { diary } = this.props;
        if (opt.apptID != this.apptID || opt.apptServiceID != this.apptServiceID || !this.state.isVisible) return;

        // Reset and set position
        this.setState({
            appt: null,
            clientRatings: null
        }, async () => {
            // Load appt info
            const appt = await BaseService.callAPI('appointment/get-tooltip-info/' + opt.apptID + '/' + (opt.apptServiceID || ''));
            this.setState({ appt });
            if (!appt) return;
            if (appt.appointmentID != this.apptID) return;
            if (appt.firstService && appt.firstService.appointmentServiceID != this.apptServiceID) return;

            // Load perfect client info
            if (appt.customer) {
                let endDate = moment(diary.date);
                switch (diary.view) {
                    case 'day': endDate.add(1, 'day'); break;
                    case 'week': endDate.add(7, 'days'); break;
                }
                const clientRatings = await CustomerService.getRatings([appt.customer.customerID], endDate.toDate())
                this.setState({ clientRatings });
            }
        });
    }

    hide() {
        const { appt, isVisible } = this.state;
        if (appt || isVisible) {
            this.setState({
                apptID: null,
                appt: null,
                clientRatings: null,
                isVisible: false
            });
        }
    }

    setPos(x, y) {
        const { appt } = this.state;
        this.x = x;
        this.y = y;
        const el = document.querySelector('.appt-tooltip');
        if (el) {
            const rect = el.getBoundingClientRect();
            x += 20;
            y += 20;
            if (x + rect.width + 20 >= window.innerWidth) {
                x = window.innerWidth - (rect.width + 20);
            }
            if (y + rect.height + 20 >= window.innerHeight) {
                y = window.innerHeight - (rect.height + 20);
            }
            el.style.left = `${x}px`;
            el.style.top = `${y}px`;
            el.style.visibility = (appt ? 'visible' : 'hidden');
        }
    }

    getBestTextColor(backgroundColor) {
        if (backgroundColor == 'inherit') {
            return '#000000';
        }
        try {
            // Convert #RRGGBB to RGB components
            const r = parseInt(backgroundColor.slice(1, 3), 16);
            const g = parseInt(backgroundColor.slice(3, 5), 16);
            const b = parseInt(backgroundColor.slice(5, 7), 16);

            // Calculate the relative luminance (https://en.wikipedia.org/wiki/Relative_luminance)
            const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;

            // Return black or white based on the luminance threshold
            return luminance > 0.7 ? '#000000' : '#FFFFFF';
        } catch (e) {
            return '#000000';
        }
    }

    render() {
        const { isVisible, appt } = this.state;
        if (!isVisible || !appt) return null;
        setTimeout(() => this.setPos(this.x, this.y));
        if (!appt.internalApptType || appt.notes) {
            return (
                <Portal>
                    <div className="appt-tooltip">
                        {this.renderInner()}
                    </div>
                </Portal>
            );
        } else {
            return null;
        }
    }

    renderInner() {
        const { appt } = this.state;

        if (appt.internalApptType) {

            if (appt.notes) {

                return (<>
                    <div className="event-tooltip-header">Notes</div>
                    <div className="event-tooltip-body">{appt.notes}</div>
                </>);

            } else {

                return null;

            }

        } else if (appt.customer) {
            const { clientRatings } = this.state;
            const loginDetails = GlobalStateService.getValue('loginDetails');
            const canViewContactDetails = loginDetails.permissions['DiaryContactDetailsOnHoverOver'];
            const hideCustomerLastName = loginDetails.permissions['ClientCardHideClientLastNames'];

            let custApptNumber, custApptNumberClass;
            switch (appt.custApptNumber) {
                case 0:
                    custApptNumber = '';
                    break;
                case 1:
                    custApptNumber = '1st Visit';
                    custApptNumberClass = 'event-tooltip-appt-number-1';
                    break;
                case 2:
                    custApptNumber = '2nd Visit';
                    custApptNumberClass = 'event-tooltip-appt-number-2';
                    break;
                case 3:
                    custApptNumber = '3rd Visit';
                    custApptNumberClass = 'event-tooltip-appt-number-3';
                    break;
                case 4:
                    custApptNumber = '4th Visit';
                    custApptNumberClass = 'event-tooltip-appt-number-4';
                    break;
                case 5:
                    custApptNumber = '5th Visit';
                    custApptNumberClass = 'event-tooltip-appt-number-5';
                    break;
                default:
                    custApptNumber = 'Regular';
                    custApptNumberClass = 'event-tooltip-appt-number-regular';
                    break;
            }

            return (<>
                <div style={{ marginTop: 40 }}>
                    <div className="event-tooltip-status">
                        {appt.appointmentID ? DiaryService.getAppointmentStatusFriendly(appt) : 'New'}
                    </div>

                    {custApptNumber &&
                        <div className={'event-tooltip-appt-number ' + custApptNumberClass}>
                            {custApptNumber}
                        </div>
                    }

                    {appt.numInBlock &&
                        <div className="event-tooltip-appt-block">
                            {appt.blockApptNumber} / {appt.numInBlock}
                        </div>
                    }

                    {appt.appointmentTags && appt.appointmentTags.length > 0 &&
                        <>
                            <div className="event-tooltip-tags">
                                <div>
                                    {appt.appointmentTags.map(e =>
                                        <>
                                            <span className="appt-tags" style={{ color: '' + this.getBestTextColor(e.backgroundColour) + '', backgroundColor: '' + e.backgroundColour + '' }}>
                                                {e.shortName}
                                            </span>
                                            <span>{' '}</span>
                                        </>
                                    )}
                                </div>
                            </div>

                        </>
                    }

                    <div className="event-tooltip-header">Client</div>
                    <div className="event-tooltip-body">
                        {hideCustomerLastName ? appt.customer.firstName : appt.customer.fullName}
                        {!!appt.customer.pronouns && <>{' '}({appt.customer.pronouns})</>}
                    </div>
                    {appt.customer.hasImportantNotes && <>
                        <div className="event-tooltip-header important-notes">
                            <span className="fa fa-exclamation-triangle" />{' '}
                            Important Notes
                        </div>
                        <div className="event-tooltip-body important-notes">See Client Card</div>
                    </>}
                    {appt.customer && (appt.customer.mobileTel || appt.customer.homeTel) && canViewContactDetails && <>
                        <div className="event-tooltip-header">Contact Number</div>
                        <div className="event-tooltip-body">{appt.customer.mobileTel || appt.customer.homeTel}</div>
                    </>}
                    {appt.firstService && <>
                        <div className="event-tooltip-header">Service</div>
                        <div className="event-tooltip-body">{appt.firstService.service.name}</div>
                    </>}
                    <div className={"event-tooltip-header" + (appt.customer.patchTestDue ? " important-notes" : "")}>Patch Test</div>
                    <div className={"event-tooltip-body" + (appt.customer.patchTestDue ? " important-notes" : "")}>
                        {appt.customer.patchTestDate ?
                            <>{moment(appt.customer.patchTestDate).format('DD MMM YYYY')} ({TextHelpers.formatDateDiff(new Date(), appt.customer.patchTestDate)})</> :
                            'No Recent History'
                        }
                    </div>
                    {appt.customer.wowNotes && <>
                        <div className="event-tooltip-header">WOW Notes</div>
                        <div className="event-tooltip-body">{appt.customer.wowNotes}</div>
                    </>}

                    {!!appt.appointmentID && <>
                        <div className="event-tooltip-header">Client Quoted</div>
                        <div className="event-tooltip-body">
                            {appt.isCustomerQuoted ? 'Yes (' + TextHelpers.formatCurrencyNew(appt.total, { includeSymbol: true, numDP: 2, commas: true }) + ')' : 'No'}
                        </div>

                        {appt.status != 'CheckedOut' && <>
                            <div className="event-tooltip-header">Deposit Paid</div>
                            <div className="event-tooltip-body">
                                {appt.depositAmount > 0 ? 'Yes (' + TextHelpers.formatCurrencyNew(appt.depositAmount, { includeSymbol: true, numDP: 2, commas: true }) + ')' : 'No'}
                            </div>
                        </>}

                        <div className="event-tooltip-header">Requested Stylist</div>
                        <div className="event-tooltip-body">
                            {appt.isRequestedStylist ? 'Yes' : 'No'}
                        </div>

                        {appt.customerComms && <>
                            <div className="event-tooltip-header">Client Response</div>
                            <div className="event-tooltip-body">
                                {appt.customerComms.length > 0 ?
                                    appt.customerComms.map((cc, index) =>
                                        <div key={index} className="customer-response">
                                            <span className="fas fa-mobile-alt"></span>
                                            <span className="body">{cc.body}</span>
                                        </div>
                                    ) :
                                    <>Not confirmed</>
                                }
                            </div>
                        </>}

                        {/* Client rating */}
                        <div className="event-tooltip-header">Perfect Client Rating</div>
                        <ClientRating
                            rating={clientRatings ? clientRatings[appt.customer.customerID] : {}}
                        />
                    </>}
                </div>
            </>);
        } else {
            return null;
        }
    }
}

export default ApptTooltip;