import BaseService from 'services/BaseService';

//-------------------------------------------------------------------------------------------------------------------

export default class TagService
    extends BaseService {
    
    static async list() {
        return BaseService.callAPI('tag/list');
    }

    static async get(tagID) {
        return BaseService.callAPI('tag/get/' + tagID);
    }

    static async getApptTypeTags() {
        return BaseService.callAPI('tag/get-appt-type-tags');
    }

    static async getApptTags(appointmentID) {
        return BaseService.callAPI('tag/get-appt-tags/' + appointmentID);
    }

    static async save(tag) {
        return BaseService.callAPI('tag/save', tag);
    }

    static async delete(id) {
        return BaseService.callAPI('tag/delete/' + id);
    }

    static async saveApptTags(appointment) {
        return BaseService.callAPI('tag/save-appt-tags', appointment);
    }
}
