// Libs
import React from 'react';
import queryString from 'query-string';
import { Link, withRouter } from 'react-router-dom';
import TabPanel, { Tab } from 'components/reusable/TabPanel';
import SuperTable from 'components/reusable/SuperTable';
import moment from 'moment';

// Services & helpers
import ClientListService from 'services/ClientListService';
import EmailTemplateService from 'services/EmailTemplateService';

// Components
import FloomlyComponent  from 'components/FloomlyComponent';
import ClientFinder  from 'components/pages/marketing/ClientFinder';
import Loader        from 'components/reusable/Loader';
import SendSMSModal from 'components/SendSMSModal';

//-------------------------------------------------------------------------------------------------------------------

class ClientListsTab extends FloomlyComponent {

    constructor(props) {
        super(props);

        this.sendSMSModalRef = React.createRef();

        // Init state
        this.state = {
            isLoading: false,
            isUpdating: false,
            isUpdated: false,
            isExporting: false,
            isOpen: {},
            isLoadingCounts: {},
            clientCounts: {},
            unSubscribedRecipients: []
        };
    }

    componentDidMount() {
        this.loadFromURLParams(this.props.match.params);
        const qs = queryString.parse(window.location.search);
        if (qs) {
            const emailTemplateID = qs.emailTemplateID;
            this.setState({
                emailTemplateID
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (
            (prevProps.match.params.subTab != 'saved-lists' && this.props.match.params.subTab == 'saved-lists') ||
            prevProps.match.params.subTabParam != this.props.match.params.subTabParam
        ) {
            this.loadFromURLParams(this.props.match.params);
        }
    }

    loadFromURLParams(params) {
        if (params.subTab == 'saved-lists') {
            this.load(() => {
                const id = parseInt(params.subTabParam) || 0;
                if (id) {
                    this.loadClientList(id, 0);
                } else {
                    this.setState({
                        clientList: null
                    });
                }
            });
        } else {

        }
    }

    async load(callback) {
        this.setState({ isLoading: true });
        const clientLists = await ClientListService.list();
        this.setState({
            isLoading: false,
            isUpdated: false,
            clientLists
        }, callback);
    }

    async loadClientList(id, page) {
        this.setState({ isLoading: true });
        const clientList = await ClientListService.get(id, page);
        const unSubscribedRecipients = await EmailTemplateService.listUnSubscribedRecipients(id);
        this.setState({
            isLoading: false,
            clientList,
            unSubscribedRecipients,
            page
        });
    }

    goBack() {
        this.props.history.replace(`/marketing/client-lists/${this.props.match.params.subTab}`);
        this.setState({
            clientList: null
        });
    }

    async refresh() {
        this.setState({
            isUpdating: true
        });
        const id = this.state.clientList.id;
        await ClientListService.refresh(id);
        await this.loadClientList(id, this.state.page);
        this.setState({
            isUpdating: false,
            isUpdated: true
        });
    }

    async showSendSMSModal() {
        this.sendSMSModalRef.current.show({
            clientListID: this.state.clientList.id
        });
    }

    async delete() {
        await ClientListService.delete(this.state.clientList.id);
        this.props.history.replace("/marketing/client-lists/saved-lists");
    }

    async exportToExcel() {
        this.setState({ isExporting: true });
        await ClientListService.exportToExcel(this.state.clientList.id, this.state.page);
        this.setState({ isExporting: false });
    }

    toggleOpen(id) {
        const isOpen = { ...this.state.isOpen };
        if (isOpen[id]) {
            delete isOpen[id];

            // Remove client list from memory
            const clientCounts = { ...this.state.clientCounts };
            delete clientCounts[id];
            this.setState({ clientCounts });
        } else {
            isOpen[id] = true;

            // Load counts
            let isLoadingCounts = { ...this.state.isLoadingCounts };
            isLoadingCounts[id] = true;
            this.setState({ isLoadingCounts });
            ClientListService.get(id, 0).then((clientCount) => {
                isLoadingCounts = { ...isLoadingCounts };
                delete isLoadingCounts[id];
                const clientCounts = { ...this.state.clientCounts };
                clientCounts[id] = clientCount;
                this.setState({ isLoadingCounts, clientCounts }); 
            });
        }

        this.setState({ isOpen });
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        const subTab = this.props.match.params.subTab;
        const { unSubscribedRecipients } = this.state;

        return (
            <div className="client-lists-tab">
                {this.renderInner()}
                <SendSMSModal ref={this.sendSMSModalRef} />
            </div>
        );
    }

    renderInner() {
        const subTab = this.props.match.params.subTab || 'new-list';
        const { isLoading, clientList, unSubscribedRecipients } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            {/* DESKTOP */}
            <TabPanel className="desktop-only" tab={subTab || 'new-list'} onChangeTab={tab => {
                this.props.history.push(`/marketing/client-lists/${tab}`);
            }}>

                    <Tab id="new-list" title="Create new list" tabClassName="tab-teal">

                        <ClientFinder emailTemplateID={this.state.emailTemplateID} />

                </Tab>

                <Tab id="saved-lists" title="My saved lists" tabClassName="tab-pink">

                    {subTab == 'saved-lists' &&
                        <div className="saved-client-lists">

                            {clientList ? this.renderClientList(false) : this.renderClientLists()}

                        </div>
                    }

                </Tab>

                <Tab id="unsubscribed-recipients" title="Unsubscribed recipients" tabClassName={'tab-green ' + (unSubscribedRecipients.length == 0 ? 'unsubscribed-receipients' : 'disable-tab')}>

                    {this.renderUnsubscribedRecipients()}

                </Tab>

            </TabPanel>

            {/* MOBILE */}
            <div className="mobile-only">
                {!clientList &&
                    <select
                        value={subTab}
                        onChange={e => this.props.history.push(`/marketing/client-lists/${e.target.value}`)}
                        className="w-100 mb-3"
                    >
                        <option value="new-list">Create a new list</option>
                        <option value="saved-lists">My saved lists</option>
                    </select>
                }

                {subTab == 'new-list' &&
                    <ClientFinder />
                }
                {subTab == 'saved-lists' &&
                    <div className="saved-client-lists">
                        {clientList ? this.renderClientList(true) : this.renderClientLists()}
                    </div>
                }
            </div>

        </>);
    }

    renderClientCounts(id) {
        const counts = this.state.clientCounts[id];
        const isLoadingCounts = this.state.isLoadingCounts[id];

        return (
            <div className="counts mt-3">
                <figure>
                    <span className="stat">
                        {isLoadingCounts || !counts ? <Loader isInline /> : counts.numClients}
                    </span>
                    <figcaption>Matching clients</figcaption>
                </figure>

                <figure>
                    <span className="stat">
                        <span>{isLoadingCounts || !counts ? <Loader isInline /> : counts.numClientsForEmail}</span>
                        <span className="method">Email</span>
                        <span>{isLoadingCounts || !counts ? <Loader isInline /> : counts.numClientsForSMS}</span>
                        <span className="method">SMS</span>
                    </span>
                    <figcaption>Contactable</figcaption>
                </figure>

                <figure>
                    <span className="stat">
                        <span>{isLoadingCounts || !counts ? <Loader isInline /> : counts.numClientsOptInEmail}</span>
                        <span className="method">Email</span>
                        <span>{isLoadingCounts || !counts ? <Loader isInline /> : counts.numClientsOptInSMS}</span>
                        <span className="method">SMS</span>
                    </span>
                    <figcaption>Marketing Opt-In</figcaption>
                </figure>
            </div>
        );
    }
    
    renderClientLists() {
        const {
            clientLists,
            isOpen
        } = this.state;
        if (!clientLists) return null;

        return (<>

            <ul className="fv2-thing-list">

                {clientLists.map(cl =>
                    <li className="thing clickable" key={cl.id}>

                        <div className="thing-side" onClick={e => 
                            this.props.history.push(`/marketing/client-lists/saved-lists/${cl.id}`)
                        }>

                            <div className="thing-icon bordered fa-solid fa-clipboard-list" />

                            <div className="thing-detail">
                                {cl.numClients} Client{cl.numClients == 1 ? '' : 's'}
                            </div>

                        </div>

                        <div className="thing-main" onClick={e => 
                            this.props.history.push(`/marketing/client-lists/saved-lists/${cl.id}`)
                        }>

                            <div className="thing-detail heading">
                                {cl.name || 'Unnamed list'}
                            </div>

                            <div className="thing-detail">
                                Last updated: {moment(cl.dateLastUpdated).format('DD/MM/YYYY HH:mm')}
                            </div>

                            {isOpen[cl.id] &&
                                <div className="thing-detail">

                                    {this.renderClientCounts(cl.id)}

                                </div>
                            }

                        </div>

                        <div className="thing-buttons-icons">

                            <button type="button" className="button button-secondary button-small" onClick={() => this.toggleOpen(cl.id)}>
                                <span className="fa fa-chevron-down"></span>
                            </button>

                        </div>

                    </li>
                )}

            </ul>

        </>);
    }

    renderClientList() {
        const {
            isLoading,
            clientList,
            page,
            isUpdating,
            isUpdated,
            isExporting
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }
        const pages = [...new Array(clientList.numPages).keys()];

        // Table of clients
        return (<>

            <div className="control-panel">

                {this.renderClientListDropdownAndBackButton()}
                
                <div className="last-updated mt-2 mb-2">
                    Updated on{' '}
                    {clientList.dateLastUpdated ? moment(clientList.dateLastUpdated).format('DD/MM/YYYY') : 'Never'}
                </div>

                <div className="button-stack mb-3">

                    <button className="button button-secondary button-small ml-auto" onClick={e => this.exportToExcel()} disabled={isExporting}>
                        {isExporting ? <Loader isInline /> : <>Export</>}
                    </button>

                    <button className="button button-secondary button-small ml-auto" onClick={e => this.refresh()}>
                        {isUpdating ? <>
                            <Loader isInline />{' '}
                            Updating...
                        </> :
                            isUpdated ? <>
                                <span className="fa fa-check" />{' '}
                                Updated
                            </> : <>
                                <span className="fa fa-redo" />{' '}
                                'Update' to most recent
                            </>
                        }
                    </button>

                    <button className="button button-primary button-small ml-auto" onClick={e => this.showSendSMSModal()}>
                        Send SMS
                    </button>

                </div>

            </div>

            <div className="d-flex mb-3">

                <h2 className="flex-1">{clientList.name}</h2>

                {clientList.numPages > 1 &&
                    <select
                        value={page}
                        onChange={e =>  this.loadClientList(this.state.clientList.id, e.target.value)}
                        className="ms-auto"
                    >
                        {pages.map(p =>
                            <option key={p} value={p}>
                                Page {p + 1}
                            </option>
                        )}
                    </select>
                }

            </div>

            <SuperTable
                className="client-list-table"
                rows={clientList.clients}
                keyAccessor={client => client.customerID}
                cols={{
                    firstName: { label: 'First name' },
                    lastName: { label: 'Last name' },
                    email: { label: 'Email' },
                    canContactByEmail: {
                        label: 'Email Opt-In',
                        getValue: (col, client) => <>
                            {' '}
                            {client.canContactByEmail ?
                                <span className="fa fa-check" /> :
                                <span className="fa fa-times" />
                            }
                        </>
                    },
                    mobileTel: { label: 'Mobile' },
                    canContactBySMS: {
                        label: 'SMS Opt-In',
                        getValue: (col, client) => <>
                            {' '}
                            {client.canContactBySMS ?
                                <span className="fa fa-check" /> :
                                <span className="fa fa-times" />
                            }
                        </>
                    }
                }}
                emptyText="This client list is empty"
            />

            <div className="button-stack mt-3">

                <button className="button button-secondary button-small ml-auto" onClick={e => this.delete()}>
                    Delete
                </button>

            </div>
        </>);
    }

    renderClientListDropdownAndBackButton() {
        const { clientList, clientLists } = this.state;
        
        return (
            <div className="d-flex">
                <button
                    type="button"
                    className="button button-secondary button-small me-2 text-nowrap"
                    style={{ height: 40 }}
                    onClick={() => this.props.history.push('/marketing/client-lists/saved-lists')}
                >
                    <span className="fa-solid fa-chevron-left" />{' '}Go back
                </button>
                <select
                    value={clientList.id}
                    onChange={e => this.props.history.push(`/marketing/client-lists/saved-lists/${e.target.value}`)}
                    className="w-100"
                >
                    {clientLists.map(cl =>
                        <option key={cl.id} value={cl.id}>
                            {cl.name || 'Unnamed list'} - {cl.numClients} Client{cl.numClients == 1 ? '' : 's'}
                        </option>
                    )}
                </select>
            </div>
        );
    }

    renderUnsubscribedRecipients() {
        const { unSubscribedRecipients } = this.state;
        return (
            <SuperTable
            className="unsubscribed-recipients"
            rows={unSubscribedRecipients}
            keyAccessor={rec => rec.customerID}
            cols={{
                name: { label: 'Name' },
                unsubscribedEmail: { label: 'Email' },
                unsubscribedDate: {
                    label: 'Unsubscribed Date',
                    className: 'date-column',
                    getValue: (colInfo, rec) => <>
                        {
                            <><span>{moment(rec.unsubscribedDate).format('DD/MM/YYYY')}</span></>
                        }
                    </>
                },
            }}
            emptyText="This list is empty"
        />)
    }
};

export default withRouter(ClientListsTab);