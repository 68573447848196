import moment from 'moment-timezone';
import GlobalStateService from 'services/GlobalStateService';

export default class DateHelpers {

    static daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

    static stripTime(dateTime) {
        const dateStr = dateTime.toISOString().split('T')[0];
        const date = new Date(dateStr);
        return date;
    }

    static convertToUTC(dateTime) {
        const offset = dateTime.getTimezoneOffset();
        //debugger;
        dateTime = new Date(dateTime.getTime() - offset * 60 * 1000);
        return dateTime;
    }

    static convertToLocal(dateTime) {
        const offset = dateTime.getTimezoneOffset();
        //debugger;
        dateTime = new Date(dateTime.getTime() + offset * 60 * 1000);
        return dateTime;
    }

    static convertToSalonTime(dateTime) {
        const clientInfo = GlobalStateService.getValue('clientInfo');
        const offset = moment.tz(dateTime, clientInfo.timeZoneLocation).utcOffset();
        dateTime = new Date(dateTime.getTime() + offset * 60 * 1000);
        return dateTime;
    }

    //https://stackoverflow.com/questions/53052012/set-timezone-react-datepicker
    //const setLocalZone = (date: Date, timezone: string) => {
    //    const dateWithoutZone = moment
    //        .tz(date, timezone)
    //        .format("YYYY-MM-DDTHH:mm:ss.SSS")
    //    const localZone = moment(dateWithoutZone).format("Z")
    //    const dateWithLocalZone = [dateWithoutZone, localZone].join("")

    //    return new Date(dateWithLocalZone)
    //}

    //const setOtherZone = (date: Date, timezone: string) => {
    //    const dateWithoutZone = moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS")
    //    const otherZone = moment.tz(date, timezone).format("Z")
    //    const dateWithOtherZone = [dateWithoutZone, otherZone].join("")

    //    return new Date(dateWithOtherZone)
    //}


    static stripSeconds(time) {
        if (!time) return time;
        const timeParts = time.split(':');
        const result = timeParts.splice(0, 2);
        return result.join(':');
    }

    static parseTimeToNumMins(time) {
        if (!time) return null;
        const timeParts = time.split(':');
        if (timeParts.length >= 2) {
            return parseInt(timeParts[0]) * 60 + parseInt(timeParts[1]);
        } else {
            return null;
        }
    }

    static numMinsToTime(numMins) {
        const numHours = Math.floor(numMins / 60);
        numMins -= numHours * 60;
        return (numHours < 10 ? '0' : '') + numHours + ':' + (numMins < 10 ? '0' : '') + numMins;
    }

    static listTimes(interval, earliest, latest) {
        const results = [];
        interval = parseInt(interval);
        if (!interval || interval <= 0) return results;
        let earliestMins = 0, latestMins = 24 * 60 - interval;
        if (earliest) {
            earliestMins = DateHelpers.parseTimeToNumMins(earliest);
        }
        if (latest) {
            latestMins = DateHelpers.parseTimeToNumMins(latest);
        }
        let numHours = 0, numMins = 0;
        let totalNumMins = 0;
        while (numHours < 24) {
            const time = (numHours < 10 ? '0' : '') + numHours + ':' + (numMins < 10 ? '0' : '') + numMins;
            if (totalNumMins >= earliestMins) {
                results.push({
                    value: time,
                    text: time
                });
            }
            totalNumMins += interval;
            numMins += interval;
            while (numMins >= 60) {
                numMins -= 60;
                numHours++;
            }
            if (totalNumMins > latestMins) {
                break;
            }
        }
        return results;
    }

    static findNearestTime(oldTime, newTimes) {
        oldTime = DateHelpers.parseTimeToNumMins(oldTime);
        let lastNewTime = null;
        for (var i = 0; i < newTimes.length; i++) {
            const newTime = newTimes[i];
            const newTimeMins = DateHelpers.parseTimeToNumMins(newTime.value);
            if (newTimeMins == oldTime) {
                return newTime.value;
            } else if (newTimeMins > oldTime) {
                return lastNewTime;
            }
            lastNewTime = newTime.value;
        }
    }

    static removeSeconds(timeSpan) {
        if (!timeSpan) return '';
        const parts = timeSpan.split(':');
        parts.splice(2);
        return parts.join(':');
    }

    static convertTimeSpanToDate(timeSpan) {
        const parts = timeSpan.split(':');
        return new Date(2000, 0, 1, Number(parts[0]), Number(parts[1]), 0);
    }

    static getShortDayName(day) {
        return day.substring(0, 1).toUpperCase() + day.substring(1, 3);
    }

	static toLocalDateString(date) {
		return date.getFullYear() + (date.getMonth() >= 9 ? '-' : '-0') + (date.getMonth()+1) + (date.getDate() > 9 ? '-' : '-0') + date.getDate() + "T00:00:00";
	}

	static preserveLocal(date) {
		return new Date(date.getFullYear() + (date.getMonth() >= 9 ? '-' : '-0') + (date.getMonth()+1) + (date.getDate() > 9 ? '-' : '-0') + date.getDate() + "T13:00:00");
	}
}
