// Libs
import React from 'react';
import { Portal } from 'react-portal';
import moment from 'moment';

// Services & Helpers
import BaseService from 'services/BaseService';
import GlobalStateService from 'services/GlobalStateService';
import DiaryService from 'services/DiaryService';
import TextHelpers from 'helpers/TextHelpers';

class StylistTooltip extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userID: null,
            date: null,
            isVisible: false
        };
    }

    async show(opt) {
        this.x = opt.x;
        this.y = opt.y;
        this.date = opt.date;
        this.userID = opt.userID;
        this.setState({ isVisible: true });
        window.clearTimeout(this.timeout);
        this.timeout = window.setTimeout(() => this.showInner(opt), 100);
    }

    async showInner(opt) {
        const { diary } = this.props;
        if (opt.userID != this.userID || !this.state.isVisible) return;

        // Reset and set position
        this.setState({
            totals: null
        }, async () => {
            // Load totals
            this.totalsRequestID = Math.random();
            let { totals, requestID } = await DiaryService.getTotals(
                this.date,
                this.date,
                this.userID,
                this.totalsRequestID
            );
            if (totals && requestID == this.totalsRequestID) {
                totals = totals[Object.keys(totals)[0]];
                if (totals) {
                    totals = totals[this.userID];
                } else {
                    totals = {};
                }
            }
            this.setState({ totals });
        });
    }

    //getStylistTotals(stylistUserID, date) {
    //    const { totals } = this.state;
    //    let totalServices = 0;
    //    let totalRetail = 0;
    //    if (!totals) {
    //        return { totalServices, totalRetail, isLoading: true };
    //    }
    //    const dateKey = moment(date).format('YYYY-MM-DD');
    //    const dateInt = parseInt(moment(date).format('YYYYMMDD'));
    //    const todayInt = parseInt(moment().format('YYYYMMDD'));
    //    const totalsForDate = totals[dateKey];
    //    if (totalsForDate) {
    //        const totalsForStylist = totalsForDate[parseInt(stylistUserID)];
    //        if (totalsForStylist) {
    //            if (todayInt <= dateInt) {
    //                totalServices += (totalsForStylist.totalServicesBooked || 0);
    //            }
    //            totalServices += (totalsForStylist.totalServicesCheckedOut || 0);
    //            totalRetail += (totalsForStylist.totalRetail || 0);
    //        }
    //    }
    //    return { totalServices, totalRetail };
    //}

    hide() {
        const { totals, isVisible } = this.state;
        this.totalsRequestID = null;
        if (totals || isVisible) {
            this.setState({
                userID: null,
                totals: null,
                isVisible: false
            });
        }
    }

    setPos(x, y) {
        const { totals } = this.state;
        this.x = x;
        this.y = y;
        const el = document.querySelector('.stylist-tooltip');
        if (el) {
            const rect = el.getBoundingClientRect();
            x += 20;
            y += 20;
            if (x + rect.width + 20 >= window.innerWidth) {
                x = window.innerWidth - (rect.width + 20);
            }
            if (y + rect.height + 20 >= window.innerHeight) {
                y = window.innerHeight - (rect.height + 20);
            }
            el.style.left = `${x}px`;
            el.style.top = `${y}px`;
            el.style.visibility = (totals ? 'visible' : 'hidden');
        }
    }

    render() {
        const { isVisible, totals } = this.state;
        if (!isVisible || !totals) return null;
        setTimeout(() => this.setPos(this.x, this.y));
        return (
            <Portal>
                <div className="stylist-tooltip">
                    {this.renderInner()}
                </div>
            </Portal>
        );
    }

    renderInner() {
        const { totals } = this.state;
        const totalServicesBooked = totals.totalServicesBooked || 0;
        const totalServicesCheckedOut = totals.totalServicesCheckedOut || 0;
        const totalRetail = totals.totalRetail || 0;
        const totalServices = totalServicesBooked + totalServicesCheckedOut;
        const total = totalServices + totalRetail;

        return (<>
            <div className="diary-total-header">
                {TextHelpers.formatCurrencyNew(total, { includeSymbol: true, numDP: 2, commas: true })}
            </div>
            <div className="diary-total-breakdown">
                <div className="diary-total-breakdown-services">
                    Services <br />
                    <span>{TextHelpers.formatCurrencyNew(totalServices, { includeSymbol: true, numDP: 2, commas: true })}</span>
                </div>
                <div className="diary-total-breakdown-retail">
                    Retail <br />
                    <span>{TextHelpers.formatCurrencyNew(totalRetail, { includeSymbol: true, numDP: 2, commas: true })}</span>
                </div>
            </div>
            <div className="diary-total-note">
                This includes any <br />
                refunds issued today
            </div>
        </>);
    }
}

export default StylistTooltip;