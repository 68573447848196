// Libs
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment-timezone';
import 'moment/locale/en-gb';
import { ContextMenuTrigger, ContextMenu, MenuItem, connectMenu } from 'react-contextmenu';
import * as $ from 'jquery';
import ReactTooltip from 'react-tooltip';
import { showMenu } from 'react-contextmenu/modules/actions'

// Services & Helpers
import DiaryService from 'services/DiaryService';
import UserService from 'services/UserService';
import GlobalStateService from 'services/GlobalStateService';
import BootboxHelper from 'helpers/BootboxHelper';
import DateHelpers from 'helpers/DateHelpers';
import PrintService from 'services/PrintService';
import TextHelpers from 'helpers/TextHelpers';
import WebSocketService from 'services/WebSocketService';
import SearchService from 'services/SearchService';
import EvoService from 'services/EvoService';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import Calendar from 'components/pages/diary/Calendar'
import DiaryBrowser from 'components/pages/diary/DiaryBrowser'
import AppointmentEditor from 'components/pages/diary/AppointmentEditor'
import PreviousAppointments from 'components/pages/diary/PreviousAppointments'
import Refund from 'components/pages/diary/Refund'
import CheckOut from 'components/pages/diary/CheckOut'
import ClientRecord from 'components/pages/diary/ClientRecord'
import BookingConfirmed from 'components/pages/diary/BookingConfirmed'
import BookingMenu from 'components/pages/diary/BookingMenu'
import InfoBar from 'components/layout/InfoBar'
import Loader from 'components/reusable/Loader';
import RetailMenu from 'components/pages/diary/RetailMenu';
import EditRotaDateModal from 'components/pages/diary/EditRotaDateModal';
import OnlineBookingsModal from 'components/pages/diary/OnlineBookingsModal';
import WaitingListModal from 'components/pages/diary/WaitingListModal';
import CancelApptModal from 'components/pages/diary/CancelApptModal';
import Quotation from 'components/pages/diary/Quotation';
import SendSMSModal from 'components/SendSMSModal';
import ApptTooltip from 'components/diary/ApptTooltip';
import StylistTooltip from 'components/diary/StylistTooltip';
import WaitingApptEditor from './WaitingApptEditor';
import ThermalPrinterService from '../../../services/ThermalPrinterService';
import WaitingApptService from '../../../services/WaitingApptService';
import ErrorBoundary from 'components/ErrorBoundary';
import FormListModal from 'components/pages/diary/FormListModal';
import PendingRefundsModal from 'components/pages/diary/PendingRefundsModal';
import AppointmentTagsModal from './AppointmentTagsModal';


//-------------------------------------------------------------------------------------------------------------------

let AppointmentContextMenu = (props) => {
    const { id, trigger, parent } = props;

    if (!trigger) {
        return (
            <ContextMenu id={id} onShow={() => { ReactTooltip.hide(); }}> {/* Not sure why onShow only gets called if it's specified here and not below */}
                <></> {/* React fragment prevents validation warning */}
            </ContextMenu>
        );
    }

    if (trigger.appointmentID === 0) { return (<></>); }

    const loginDetails = GlobalStateService.getValue('loginDetails');
    const clientInfo = GlobalStateService.getValue('clientInfo');
    const isProvisional = (trigger.status == 'provisional');
    const isOpenStatus = ['unconfirmed', 'confirmed', 'waiting', 'withStylist'].indexOf(trigger.status) != -1;
    
    return (
        <ContextMenu
            id={id}
            onShow={() => document.body.classList.add('context-menu-visible')}
            onHide={() => document.body.classList.remove('context-menu-visible')}
            preventHideOnScroll={parent.isMobile}
        >
            <MenuItem onClick={() => null} className="close">
                <span className="fa fa-times"></span> Close menu
            </MenuItem>

            {trigger.status == 'provisional' && loginDetails.permissions['DiaryConfirmOnlineBookings'] &&
                <MenuItem onClick={() => parent.updateAppointmentStatus(trigger.appointmentID, 'unconfirmed')}>
                    <span className="fa fa-thumbs-up"></span> Confirm Booking
                </MenuItem>
            }
            {trigger.status == 'unconfirmed' &&
                <MenuItem onClick={() => parent.updateAppointmentStatus(trigger.appointmentID, 'confirmed')}>
                    <span className="fa fa-thumbs-up"></span> Confirm
                </MenuItem>
            }
            {(trigger.status == 'unconfirmed' || trigger.status == 'confirmed') &&
                <MenuItem onClick={() => parent.updateAppointmentStatus(trigger.appointmentID, 'waiting')}>
                    <span className="fa fa-door-open"></span> Client Arrived
                </MenuItem>
            }
            {trigger.status == 'confirmed' &&
                <MenuItem onClick={() => parent.updateAppointmentStatus(trigger.appointmentID, 'unconfirmed')}>
                    <span className="fa fa-times"></span> Undo Confirm
                </MenuItem>
            }
            {trigger.status == 'waiting' &&
                <MenuItem onClick={() => parent.updateAppointmentStatus(trigger.appointmentID, 'confirmed')}>
                    <span className="fa fa-times"></span> Undo Client Arrived
                </MenuItem>
            }
            {trigger.status == 'waiting' &&
                <MenuItem onClick={() => parent.updateAppointmentStatus(trigger.appointmentID, 'withStylist')}>
                    <span className="fas fa-chair"></span> Client With Stylist
                </MenuItem>
            }
            {trigger.status == 'withStylist' &&
                <MenuItem onClick={() => parent.updateAppointmentStatus(trigger.appointmentID, 'waiting')}>
                    <span className="fas fa-chair"></span> Undo Client With Stylist
                </MenuItem>
            }

            {isOpenStatus && <>
                {loginDetails.permissions['DiaryCheckOutAppointments'] &&
                    <MenuItem onClick={() => parent.loadCheckOut(trigger.appointmentID, true)}>
                        <span className="fa fa-money-bill"></span> Add Deposit
                    </MenuItem>
                }
                {loginDetails.permissions['DiaryCheckOutAppointments'] &&
                    <MenuItem onClick={() => parent.loadCheckOut(trigger.appointmentID, false)}>
                        <span className="fa fa-money-bill"></span> Check Out
                    </MenuItem>
                }
                {loginDetails.permissions['DiaryCancelAppointments'] && trigger.status != 'withStylist' && <>
                    <MenuItem onClick={() => parent.confirmCancelOrDeleteAppt(trigger.appointmentID, 'noShow')}>
                        <span className="far fa-thumbs-down"></span> No Show
                    </MenuItem>
                    <MenuItem onClick={() => parent.confirmCancelOrDeleteAppt(trigger.appointmentID, 'cancel')}>
                        <span className="fa fa-times"></span> Cancel Appointment
                    </MenuItem>
                </>}
            </>}
            {isProvisional && loginDetails.permissions['DiaryCancelAppointments'] &&
                <MenuItem onClick={() => parent.confirmCancelOrDeleteAppt(trigger.appointmentID, 'cancel')}>
                    <span className="fa fa-times"></span> Cancel Booking
                </MenuItem>
            }
            {isOpenStatus && (loginDetails.permissions['DiaryEditAppointments'] || loginDetails.permissions['DiaryUpdateClientRecord']) &&
                <MenuItem divider />
            }
            {loginDetails.permissions['DiaryEditAppointments'] && trigger.status != 'checkedOut' && <>
                <MenuItem onClick={(e, data) => parent.loadAppointmentSchedule(trigger.appointmentID)}>
                    <span className="fa fa-pencil-alt"></span> Edit services
                </MenuItem>
                {trigger.status != 'checkedOut' &&
                    <MenuItem onClick={async (e, data) => {
                        await parent.loadAppointmentSchedule(trigger.appointmentID);
                        parent.changeDate();
                    }}>
                        <span className="fas fa-calendar"></span> Change date
                    </MenuItem>
                }
            </>}
            {!isProvisional && loginDetails.permissions['DiaryUpdateClientRecord'] &&
                <MenuItem onClick={(e, data) => parent.setAppointmentIDAndMode(trigger.appointmentID, 'client-record')}>
                    <span className="fa fa-pencil-alt"></span> Update client record
                </MenuItem>
            }
            <MenuItem onClick={(e, data) => parent.showFormListModal(trigger.customerID)}>
                <span className="fa fa-clipboard"></span> Consultation Forms
            </MenuItem>
            {(trigger.status == 'checkedOut' || trigger.hasAnyPayments) && <>
                <MenuItem onClick={(e, data) => parent.setAppointmentIDAndMode(trigger.appointmentID, 'refund')}>
                    <span className="fa fa-sad-tear"></span> Refund
                </MenuItem>
                {clientInfo.paymentProvider != 'evo' && <>
                    <MenuItem divider />
                    <MenuItem onClick={(e, data) => parent.confirmUndoCheckOut(trigger.appointmentID)}>
                        <span className="fa fa-undo"></span> Undo checkout
                    </MenuItem>
                </>}
            </>}
            {isOpenStatus && loginDetails.permissions['DiaryDeleteAppointments'] && !trigger.hasDeposit && <>
                <MenuItem divider />
                <MenuItem onClick={(e, data) => parent.confirmCancelOrDeleteAppt(trigger.appointmentID, 'delete')}>
                    <span className="fa fa-trash"></span> Delete booking
                </MenuItem>
            </>}
            <MenuItem divider />
            <MenuItem onClick={(e, data) => parent.printApptPDF(trigger.appointmentID)}>
                <span className="fa fa-print"></span> Print Appointment Notes
            </MenuItem>
            <MenuItem onClick={(e, data) => parent.printCustomerApptsPDF(trigger.customerID)}>
                <span className="fa fa-print"></span> Print Future Appointments
            </MenuItem>
            {trigger.hasMobileNumber && <>
                <MenuItem divider />
                <MenuItem onClick={(e, data) => parent.showSendSMSModal(trigger.customerID, trigger.appointmentID)}>
                    <span className="fas fa-mobile-alt"></span> Send SMS
                </MenuItem>
            </>}
            {clientInfo.enableDeposits && trigger.status != 'checkedOut' && trigger.hasMobileNumber && clientInfo.paymentProvider && clientInfo.paymentProvider == 'evo' && <>
                <MenuItem divider />
                <MenuItem onClick={(e, data) => parent.sendConfirmLinkSMS(trigger.customerID, trigger.appointmentID, true)}>
                    <span className="fas fa-mobile-alt"></span>Send deposit link SMS
                </MenuItem>
            </>}
            {!clientInfo.enableDeposits && trigger.hasMobileNumber && trigger.status == 'unconfirmed' && <>
                <MenuItem divider />
                <MenuItem onClick={(e, data) => parent.sendConfirmLinkSMS(trigger.customerID, trigger.appointmentID, false)}>
                    <span className="fas fa-mobile-alt"></span>Send confirm link SMS
                </MenuItem>
            </>}
            {trigger.hasTags && <>
                <MenuItem divider />
                <MenuItem onClick={(e, data) => parent.showAppointmentTagsModal(trigger.appointmentID)}>
                    <span className="fa fa-tag"></span> Edit tags
                </MenuItem>
            </>}
        </ContextMenu>
    );
};
AppointmentContextMenu = connectMenu('appointment-context-menu')(AppointmentContextMenu);

let StylistContextMenu = (props) => {
    const { id, trigger, parent } = props;

    const loginDetails = GlobalStateService.getValue('loginDetails');

    if (!trigger) {
        return (
            <ContextMenu id={id} onShow={() => { ReactTooltip.hide() }}>
                <></>
            </ContextMenu>
        );
    }

    return (
        <ContextMenu
            id={id}
            onShow={() => document.body.classList.add('context-menu-visible')}
            onHide={() => document.body.classList.remove('context-menu-visible')}
            preventHideOnScroll={parent.isMobile}
        >
            <MenuItem onClick={() => null} className="close">
                <span className="fa fa-times"></span> Close menu
            </MenuItem>
            {loginDetails.permissions['DiaryEditRotas'] &&
                <MenuItem onClick={(e, data) => parent.showEditRotaDateModal(trigger.stylistUserID)}>
                    <span className="fa fa-calendar-day"></span> Edit rota
                </MenuItem>
            }
            <MenuItem onClick={(e, data) => parent.printStylistApptsPDF(trigger.stylistUserID, null)}>
                <span className="fa fa-print"></span> Print appointments
            </MenuItem>
        </ContextMenu>
    );
};
StylistContextMenu = connectMenu('stylist-context-menu')(StylistContextMenu);

let InternalApptContextMenu = (props) => {
    const { id, trigger, parent } = props;

    if (!trigger) {
        return (
            <ContextMenu id={id} onShow={() => { ReactTooltip.hide() }}> {/* Not sure why onShow only gets called if it's specified here and not below */}
                <></> {/* React fragment prevents validation warning */}
            </ContextMenu>
        );
    }

    const loginDetails = GlobalStateService.getValue('loginDetails');

    if (!loginDetails.permissions['DiaryDeleteInternalAppointments']) {
        return null;
    }

    return (
        <ContextMenu
            id={id}
            onShow={() => document.body.classList.add('context-menu-visible')}
            onHide={() => document.body.classList.remove('context-menu-visible')}
            preventHideOnScroll={parent.isMobile}
        >
            <MenuItem onClick={() => null} className="close">
                <span className="fa fa-times"></span> Close menu
            </MenuItem>
            <MenuItem id={id} onClick={(e, data) => parent.confirmCancelOrDeleteAppt(trigger.appointmentID, 'delete')}>
                <span className="fa fa-trash"></span> Delete
            </MenuItem>
        </ContextMenu>
    );
};
InternalApptContextMenu = connectMenu('internal-appt-context-menu')(InternalApptContextMenu);

//-------------------------------------------------------------------------------------------------------------------

class DiaryPage extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Refs
        this.editRotaDateModelRef = React.createRef();
        this.onlineBookingsModalRef = React.createRef();
        this.waitingListModalRef = React.createRef();
        this.sendSMSModalRef = React.createRef();
        this.checkOutRef = React.createRef();
        this.cancelApptModalRef = React.createRef();
        this.apptTooltipRef = React.createRef();
        this.stylistTooltipRef = React.createRef();
        this.formListModalRef = React.createRef();
        this.pendingRefundModalRef = React.createRef();
        this.appointmentTagsModalRef = React.createRef();

        // Init state
        const clientInfo = GlobalStateService.getValue('clientInfo');
        this.state = {
            isLoading: true,
            isLoadingAppts: true,
            mode: 'browse',
            isClientRecordUpdated: false,
            appointments: [],
            diary: {
                date: new Date(moment().utc().format('YYYY-MM-DD')),
                view: 'day',
                stylistID: null
            },
            stylists: [],
            serviceCategories: [],
            services: [],
            packages: [],
            selectedTime: null,
            selectedStylistID: null,
            dates: {},
            hideStylistsNotIn: clientInfo.hideStylistsNotIn,
            refundPosDevice: null
        };

        // Non-state data
        this.date = new Date(moment().utc().format('YYYY-MM-DD'));
        this.oldDate = null;
        this.stylistsLookup = {};
        this.appointmentID = -1;
        this.pricing = {};
        this.isMobile = window.matchMedia('(pointer: coarse)').matches;

        // Bind all functions
        this.selectDate = this.selectDate.bind(this);
        this.newAppointment = this.newAppointment.bind(this);
        this.cancelAppointmentEdit = this.cancelAppointmentEdit.bind(this);
        this.updateAppointmentField = this.updateAppointmentFields.bind(this);
        this.changeDate = this.changeDate.bind(this);
        this.loadAppointmentSchedule = this.loadAppointmentSchedule.bind(this);
        this.saveAppointmentSchedule = this.saveAppointmentSchedule.bind(this);
        this.blockBookingSaved = this.blockBookingSaved.bind(this);
        this.loadBookingInfo = this.loadBookingInfo.bind(this);
        this.load = this.load.bind(this);
        this.listCalendarEvents = this.listCalendarEvents.bind(this);
        this.getServicePrice = this.getServicePrice.bind(this);
        this.getStylistServiceDuration = this.getStylistServiceDuration.bind(this);
        this.processAppointmentServices = this.processAppointmentServices.bind(this);
        this.onEventDropOrResize = this.onEventDropOrResize.bind(this);
        this.onEventClick = this.onEventClick.bind(this);
        this.getIsEventEditable = this.getIsEventEditable.bind(this);
        this.cancelDateChange = this.cancelDateChange.bind(this);
        this.selectSlot = this.selectSlot.bind(this);
        this.updateSlot = this.updateSlot.bind(this);
        this.startAppointmentPreBook = this.startAppointmentPreBook.bind(this);
        this.cancelPreBook = this.cancelPreBook.bind(this);
        this.onReplayRoute = this.onReplayRoute.bind(this);
        this.goToRetailArea = this.goToRetailArea.bind(this);
        //this.onMouseMove = this.onMouseMove.bind(this);
        this.editWaitingAppt = this.editWaitingAppt.bind(this);
        this.bookWaitingAppt = this.bookWaitingAppt.bind(this);
        this.loadedWaitingAppt = this.loadedWaitingAppt.bind(this);
        this.showFormListModal = this.showFormListModal.bind(this);
        this.clientRecordRef = React.createRef();

        window.test = this.load;
    }

    async componentDidMount() {
        let options = null;
        let appointmentID = null;
        let mode = null;
        if (this.props.match.params.date) {
            options = {
                view: 'day'
            };
            options.date = DateHelpers.convertToSalonTime(moment(this.props.match.params.date).toDate());
            appointmentID = parseInt(this.props.match.params.appointmentID) || 0;
            mode = this.props.match.params.mode;
            this.props.history.replace('/diary');
        }

        const stylists = await UserService.listDiary();
        this.stylistsLookup = {};
        stylists.forEach(s => {
            this.stylistsLookup[s.userID] = s;
        });
        const paymentMethods = await DiaryService.loadPaymentMethods();
        const cardPM = paymentMethods.find(pm => pm.code == 'Card');
        await window.updateClientInfo();
        this.setState({
            stylists,
            cardPM,
            hideStylistsNotIn: GlobalStateService.getValue('clientInfo').hideStylistsNotIn
        });

        document.body.onkeydown = (e) => {
            if ([
                'INPUT',
                'TEXTAREA',
                'SELECT'
            ].indexOf(e.target.tagName) != -1) {
                return;
            }

            switch (e.code) {
                case 'ArrowRight':
                    e.preventDefault();
                    this.navigate(+1);
                    break;
                case 'ArrowLeft':
                    e.preventDefault();
                    this.navigate(-1);
                    break;
                case 'ArrowUp':
                    e.preventDefault();
                    this.navigate(-7);
                    break;
                case 'ArrowDown':
                    e.preventDefault();
                    this.navigate(+7);
                    break;
            }
        };

        // Load
        await this.load(options, false);
        if (appointmentID) {
            if (mode == 'check-out-payments') {
                this.goToPayment = true;
                mode = 'check-out';
            }
            if (this.state.appointments.find(a => a.appointmentID === appointmentID)) {
                await this.setAppointmentIDAndMode(appointmentID, mode || 'booking-menu');
            }
        }

        GlobalStateService.subscribeEvent('replayRoute', this.onReplayRoute);

        this.unblock = this.props.history.block((nextLocation) => {
            if (this.isChanged && nextLocation.pathname != '/diary') {
                this.handleBlockedNavigation(nextLocation);
                return false;
            }
            return true;
        });

        // Web sockets
        WebSocketService.joinPage('Diary')
            .then(() => {
                WebSocketService.subscribe('DiaryUpdated', () => {
                    if (!this.state.isLoading && !this.isLoading) {
                        this.load(null, true);
                    }
                });
            });

        // Check for online bookings waiting to be confirmed
        const clientInfo = GlobalStateService.getValue('clientInfo');
        if (clientInfo.enableOnlineBooking) {
            this.checkForOnlineBookings();
            this.checkOnlineBookingsInterval = setInterval(() => {
                this.checkForOnlineBookings();
            }, 60 * 1000);
        }

        // Reload automatically in the background
        this.reloadInterval = setInterval(() => {
            this.load(null, true);
        }, 2 * 60 * 1000);

        // Event handlers
        //document.body.addEventListener('mousemove', this.onMouseMove);
    }

    async componentDidUpdate(prevProps, prevState) {
        const date = this.props.match.params.date;
        if (date && date != prevProps.match.params.date) {
            const appointmentID = parseInt(this.props.match.params.appointmentID) || 0;

            let options = null;
            if (date) {
                options = {
                    date: new Date(date),
                    view: 'day'
                };
                this.props.history.replace('/diary');
            }
            await this.returnToBrowseMode();
            await this.load(options, false);

            if (appointmentID) {
                this.setAppointmentIDAndMode(appointmentID, 'booking-menu');
            }
        }
    }

    componentWillUnmount() {
        document.body.onkeydown = function () { };
        GlobalStateService.unsubscribeEvent('replayRoute', this.onReplayRoute);
        clearInterval(this.checkOnlineBookingsInterval);
        clearInterval(this.reloadInterval);
        WebSocketService.unsubscribe('DiaryUpdated');
        WebSocketService.leavePage('Diary');

        if (this.unblock) {
            this.unblock();
        }

        // Event handlers
        //document.body.removeEventListener('mousemove', this.onMouseMove);
    }

    onReplayRoute() {
        this.parentAppointment = null;
        this.returnToBrowseMode();
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Stylists
    //--------------------------------------------------------------------------------------------------------------------

    listCalendarResources() {
        let stylists = [...this.state.stylists];
        const diary = this.state.diary;
        const loginDetails = GlobalStateService.getValue('loginDetails');
        const showDiaryAll = true;// loginDetails.permissions['ShowDiaryAll'];
        const showDiaryIndividual = false;// = loginDetails.permissions['ShowDiaryIndividual'];

        // Get start and end date for the period being viewed
        const startDate = moment(diary.date);
        const endDate = moment(diary.date);
        let numDays;
        switch (diary.view) {
            case 'day': numDays = 1; break;
            case 'week': numDays = 7; break;
        }
        endDate.add(numDays, 'days');
        if (!showDiaryIndividual && !showDiaryAll) {
            stylists = [];
        }
        //Filter to show only their diary
        if (showDiaryIndividual && !showDiaryAll) {
            stylists = stylists.filter(s => s.userID == loginDetails.user.userID);
        }
        // Filter list to only show a single stylist
        else if (this.state.diary.stylistID) {
            stylists = stylists.filter(s => s.userID == this.state.diary.stylistID);
        } else if (this.state.hideStylistsNotIn) {
            // Only show stylists that are working at least one day in this period
            const date = moment(startDate);
            const stylistsWorking = {};
            for (var i = 0; i < numDays; i++) {
                const dateKey = moment(date).format('YYYY-MM-DD');
                const dateInfo = this.state.dates[dateKey];
                if (dateInfo) {
                    dateInfo.stylists.forEach(s => {
                        if (s.rotaDayMulti && s.rotaDayMulti.findIndex(rdm => rdm.workingType == 'working') >= 0) {
                            stylistsWorking[s.userID] = true;
                        }
                    });
                }
                date.add(1, 'day');
            }
            stylists = stylists.filter(s => stylistsWorking[s.userID]);
        }

        // Map stylist list to calendar resources
        const resources = stylists.map(s => {
            return {
                resourceId: s.userID,
                resourceTitle: s.nickname,
                diaryColour: s.diaryColour
            }
        });

        return resources;
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Diary / Appointments
    //--------------------------------------------------------------------------------------------------------------------

    async load(options, isSilent, dontPreserve) {

        this.requestID = `${Math.random()}`;
        this.isLoading = true;
        if (options) {
            if (options.date != this.state.diary.date) {
                $('.rbc-event').removeClass('highlighted');
                this.date = options.date;
            }
        } else {
            options = this.state.diary;
        }
        const thisAppointment = this.state.appointments.find(a => a.appointmentID === this.appointmentID);
        if (!thisAppointment) {
            this.isScrolled = false;
        }

        if (!isSilent) {
            this.isScrolled = false;
            this.setState({
                diary: options,
                salonPeriod: null,
                appointments: [],
                clientRatings: null
            });
        }

        // Load from API
        const diaryInfo = await DiaryService.loadDiary(
            moment(options.date).format('YYYY-MM-DD'),
            options.view,
            options.stylistID,
            this.requestID
        );
        if (diaryInfo.requestID != this.requestID) {
            return;
        }
        const appointments = diaryInfo.appointments;

        // Preserve the current appointment
        if (thisAppointment && !dontPreserve) {
            const thisAppointmentIndex = appointments.findIndex(a => a.appointmentID === this.appointmentID);
            if (thisAppointmentIndex == -1) {
                appointments.push({ ...thisAppointment });
            } else {
                appointments[thisAppointmentIndex] = thisAppointment;
            }
        }

        // Determine minimum diary interval for the displayed period
        let diaryInterval = 60;
        for (var d in diaryInfo.dates) {
            const intervalDate = diaryInfo.dates[d];
            if (intervalDate.diaryInterval < diaryInterval) {
                diaryInterval = intervalDate.diaryInterval;
            }
        }

        // Determine how many days to look ahead
        const date = moment(options.date);
        let numDays = 0;
        switch (options.view) {
            case 'day': numDays = 1; break;
            case 'week': numDays = 7; break;
        }

        // Determine earliest opening time and latest closing time
        let earliestOpeningTime = '00:00';
        let latestClosingTime = '23:59';
        let earliestOpeningTimeMins = 1440;
        let latestClosingTimeMins = 0;
        for (var i = 0; i < numDays; i++) {
            const dateISO = moment(date).format('YYYY-MM-DD');
            const dateInfo = diaryInfo.dates[dateISO];
            if (!dateInfo) continue;

            dateInfo.stylists.forEach(s => {
                // Salon opening hours
                if (dateInfo.isOpen) {
                    const openingTime = dateInfo.openingTime || '00:00';
                    const closingTime = dateInfo.closingTime || '23:59';
                    const openingTimeMins = DateHelpers.parseTimeToNumMins(openingTime);
                    const closingTimeMins = DateHelpers.parseTimeToNumMins(closingTime);

                    if (openingTimeMins < earliestOpeningTimeMins) {
                        earliestOpeningTimeMins = openingTimeMins;
                        earliestOpeningTime = openingTime;
                    }
                    if (closingTimeMins > latestClosingTimeMins) {
                        latestClosingTimeMins = closingTimeMins;
                        latestClosingTime = closingTime;
                    }
                }

                // If the stylist is working outside of the normal salon opening hours
                if (s.rotaDayMulti) {
                    for (let i = 0; i < s.rotaDayMulti.length; i++) {
                        const rotaDay = s.rotaDayMulti[i];

                        if (rotaDay.startTime) {
                            const startTimeMins = DateHelpers.parseTimeToNumMins(rotaDay.startTime);
                            if (startTimeMins < earliestOpeningTimeMins) {
                                earliestOpeningTimeMins = startTimeMins;
                                earliestOpeningTime = rotaDay.startTime;
                            }
                        }
                        if (rotaDay.endTime) {
                            const endTimeMins = DateHelpers.parseTimeToNumMins(rotaDay.endTime);
                            if (endTimeMins > latestClosingTimeMins) {
                                latestClosingTimeMins = endTimeMins;
                                latestClosingTime = rotaDay.endTime;
                            }
                        }
                    }
                }
            });
            date.add(1, 'day');
        }

        // Short term fix to work around bugginess of react-big-calendar
        while (latestClosingTimeMins - earliestOpeningTimeMins < 300) {
            earliestOpeningTimeMins -= 30;
            latestClosingTimeMins += 30;
        }

        earliestOpeningTimeMins -= 90;
        if (earliestOpeningTimeMins < 0) {
            earliestOpeningTimeMins = 0;
        }
        earliestOpeningTime = DateHelpers.numMinsToTime(earliestOpeningTimeMins);
        latestClosingTimeMins += 90;
        if (latestClosingTimeMins > 1439) {
            latestClosingTimeMins = 1439;
        }
        latestClosingTime = DateHelpers.numMinsToTime(latestClosingTimeMins);

        // Update screen
        await this.setStateAsync({
            diary: options,
            totals: null,
            appointments,
            dates: diaryInfo.dates,
            diaryInterval,
            minTime: earliestOpeningTime,
            maxTime: latestClosingTime,
            isLoading: false,
            isLoadingAppts: false,

        });
        this.debounceLoadTotals();
        this.isScrolled = true;
        this.isLoading = false;
    }

    debounceLoadTotals() {
        clearTimeout(this.loadTotalsTimeout);
        this.loadTotalsTimeout = setTimeout(() => {
            this.loadTotals();
        }, 500);
    }

    async loadTotals() {
        const { diary } = this.state;

        // Determine end date
        let endDate = moment(diary.date);
        switch (diary.view || 'day') {
            case 'day':
                endDate.add(0, 'day');
                break;
            case 'week':
                endDate.add(6, 'days');
                break;
        }
    }

    async setAppointmentIDAndMode(appointmentID, mode, checkOutStep) {
        if (this.appointmentID > 0 || (this.appointmentID == 0 && this.isChanged == true)){
            await this.load(null, true, true);
        }

        // Set ID
        this.appointmentID = appointmentID;
        this.isChanged = false;
        GlobalStateService.setValue('unsavedChanges', false);

        // Store the date for use later
        this.appointmentDate = null;
        const appointment = this.state.appointments.find(a => a.appointmentID == appointmentID);
        if (appointment) {
            this.appointmentDate = appointment.date;
        }

        // Set mode
        const update = {
            mode: mode
        };
        if (mode == 'check-out' || mode == 'add-deposit') {
            update.selectedTime = null;
            if (mode == 'check-out') {
                this.checkOutStep = checkOutStep;
            }
        }
        this.setState(update, () => {
            if (this.isMobile) {
                window.scrollTo(0, 0);
            }
        });
    }

    async confirmUndoCheckOut(appointmentID) {
        const confirm = await BootboxHelper.confirm('Are you sure you want to undo the check-out for this appointment (including all payments and deposits)?');
        if (confirm) {
            this.undoCheckOut(appointmentID);
        }
    }

    async undoCheckOut(appointmentID) {
        //check if there are any payments with the Pos device or complete the undo check out
        const appointment = await DiaryService.loadCheckOut(appointmentID);
        const dateInt = parseInt(moment(appointment.date).format('YYYYMMDD'));
        const todayInt = parseInt(moment().format('YYYYMMDD'));
        const paymentThroughCardMachine = appointment.appointmentPayments.some(ap => ap.paymentMethodName == 'Card' && ap.paymentProvider == 'posDevice');
        
        if (paymentThroughCardMachine && (dateInt != todayInt)) {
            await this.checkForPosTransaction(appointmentID, appointment);
        }
        else {
            this.setState({
                isLoading: true
            });
            await DiaryService.undoCheckOut(appointmentID);
            this.cancelAppointmentEdit();
            this.load(null, true);
            this.setState({
                isLoading: false
            });
        }
    }

    async checkForPosTransaction(appointmentID, appointment) {
        let amountPaid = 0;
        const refundTransaction = appointment.appointmentPayments.filter(ap => ap.paymentMethodID == this.state.cardPM.paymentMethodID
            && (ap.paymentProvider == 'posDevice'));

        refundTransaction.forEach(pr => {
            amountPaid += pr.amount;
        });

        var refundAmount = amountPaid - appointment.amountRefunded;

        if (refundAmount > 0 && (refundTransaction && refundTransaction.length > 0)) {
            let response = await DiaryService.refundTransaction(refundAmount * 100, refundTransaction[0].paymentDeviceID);
            BootboxHelper.alert('Please complete the transaction on the pos device.');
            this.setState({
                isLoading: true
            });
            this.checkTransactionComplete = setInterval(() => {
                this.checkForTransactionDetails(response.TransactionId, response.PaymentDeviceId, amountPaid, appointmentID, amountPaid);
            }, 5000);
        }
    }

    async checkForTransactionDetails(transactionId, paymentDeviceId, amount, appointmentID, totalAmount) {
        let refundAmount = 0;
        const transactionResponse = await DiaryService.getTransactionDetails(transactionId, paymentDeviceId);
        if (transactionResponse == EvoService.RESPONSE_SUCCESS) {
            this.setState({
                refundPosDevice: {
                    appointmentID: appointmentID,
                    refundTransactionId: transactionId,
                    paymentMethodId: this.state.cardPM.paymentMethodID,
                    Amount: amount
                }
            });
            refundAmount += amount;
            clearInterval(this.checkTransactionComplete);
            await DiaryService.issueRefund(this.state.refundPosDevice);
            if (refundAmount == totalAmount) {
                await DiaryService.undoCheckOut(appointmentID);
                this.cancelAppointmentEdit();
                this.load(null, true);
                this.setState({
                    isLoading: false
                });
            }
        }
        else if (transactionResponse == EvoService.RESPOSE_CANCELLED
            || transactionResponse == EvoService.RESPONSE_REFUSED) {
            BootboxHelper.alert('Please note undo checkout not completed as transaction cancelled on the pos device');
            clearInterval(this.checkTransactionComplete);
            this.setState({
                isLoading: false
            });
        }
    }

    async selectDate(info) {
        let anyChanges = false;
        const newDiary = { ...this.state.diary };

        if (info.date) {
            const newDate = info.date;// DateHelpers.stripTime(info.date);
            if (moment(newDate).format('YYYY-MM-DD') != moment(newDiary.date).format('YYYY-MM-DD')) {
                anyChanges = true;
                newDiary.date = newDate;

                if (!this.isMobile) {
                    this.apptTooltipRef.current.hide();
                    this.stylistTooltipRef.current.hide();
                }
            }
            this.date = newDate;
        }
        if (info.view && info.view != newDiary.view) {
            newDiary.view = info.view;
            anyChanges = true;
        }
        if (typeof info.stylistID != 'undefined' && newDiary.stylistID != info.stylistID) {
            newDiary.stylistID = info.stylistID;
            anyChanges = true;
        }
        if (anyChanges) {
            await this.load(newDiary, true);
        }
    }

    async newAppointment(startTime, resourceID) {
        await this.loadBookingInfo();

        if (startTime) {
            startTime = moment(startTime).format('HH:mm');
        }

        // Create blank appointment
        const appointment = {
            appointmentID: 0,
            appointmentServices: [],
            appointmentPackages: [],
            appointmentTags: [],
            date: DateHelpers.stripTime(this.state.diary.date),
            parentAppointmentID: (this.parentAppointment ? this.parentAppointment.appointmentID : null),
            customer: (this.parentAppointment ? this.parentAppointment.customer : null),
            isRequestedStylist: !!this.parentAppointment,
            status: 'unconfirmed',

            startTime: startTime,
            defaultStylistID: resourceID
        };

        // If pre-booking, skipping to copy the services from previous appointment
        // TODO also take the time into account
        if (this.parentAppointment && this.state.mode != "pre-book") {

            // Create a copy of the packages (if applicable)
            let apptPackageMapping = {};
            let newApptPackageID = -1;
            if (this.parentAppointment.appointmentPackages) {
                this.parentAppointment.appointmentPackages.forEach(apptPackage => {
                    const newApptPackage = { ...apptPackage };
                    apptPackageMapping[newApptPackage.appointmentPackageID] = newApptPackageID;
                    newApptPackage.appointmentPackageID = newApptPackageID;
                    newApptPackageID--;
                    appointment.appointmentPackages.push(newApptPackage);
                });
            }

            // Determine the earliest time
            let earliestTime, earliestTimeMins = 99999;
            this.parentAppointment.appointmentServices.forEach(apptService => {
                if (apptService.time) {
                    const time = moment(apptService.time).format('HH:mm');
                    const timeMins = DateHelpers.parseTimeToNumMins(time);
                    if (!earliestTime || timeMins < earliestTimeMins) {
                        earliestTime = time;
                        earliestTimeMins = timeMins;
                    }
                }
            });

            // Create a copy of the services
            let startTimeMins = startTime ? DateHelpers.parseTimeToNumMins(startTime) : 0;
            let newApptServiceID = -1;
            this.parentAppointment.appointmentServices.forEach(apptService => {
                const newApptService = { ...apptService };
                newApptService.appointmentServiceID = newApptServiceID--;

                // Assign new apptPackageID
                if (newApptService.appointmentPackageID) {
                    newApptService.appointmentPackageID = apptPackageMapping[newApptService.appointmentPackageID];
                }

                // Assign new stylist
                if (resourceID) {
                    newApptService.stylistUserID = resourceID;
                }

                // Assign new time
                if (startTime && newApptService.time) {
                    const timeMins = DateHelpers.parseTimeToNumMins(moment(newApptService.time).format('HH:mm'));
                    const timeDiffMins = timeMins - earliestTimeMins;
                    newApptService.time = moment(moment(appointment.date).format('YYYY-MM-DD')).add(startTimeMins + timeDiffMins, 'minutes').toDate();
                } else {
                    newApptService.time = null;
                }

                // Assign new price (except if part of a fixed-price package)
                let canSetPrice = true;
                if (newApptService.appointmentPackageID) {
                    const appointmentPackage = (this.parentAppointment.appointmentPackages || []).find(ap => ap.appointmentPackageID == newApptService.appointmentPackageID);
                    canSetPrice = !appointmentPackage || !appointmentPackage.isFixedPrice;
                }
                if (canSetPrice) {
                    const price = this.getServicePrice(newApptService.service.serviceID, newApptService.stylistUserID);
                    newApptService.total = price;
                }

                appointment.appointmentServices.push(newApptService);
            });
        }

        // Add to list and note the ID
        const newAppointments = this.state.appointments.concat(appointment);
        this.appointmentID = appointment.appointmentID;
        this.isChanged = false;
        GlobalStateService.setValue('unsavedChanges', false);

        // Update UI
        this.setState({
            appointments: newAppointments,
            mode: 'edit-schedule'
        });
    }

    async returnToBrowseMode() {
        if (this.isChanged) {
            const confirm = await BootboxHelper.confirm('If you navigate away, the changes you made to this appointment will be lost. Is that ok?');
            if (!confirm) {
                return;
            }
        }
        $('.rbc-event').removeClass('highlighted');
        const newAppointments = this.state.appointments.filter(a => a.appointmentID !== 0);
        this.appointmentID = -1;
        this.isChanged = false;
        GlobalStateService.setValue('unsavedChanges', false);

        await this.setStateAsync({
            appointments: newAppointments,
            mode: 'browse',
            selectedTime: null,
            selectedStylistID: null,
            waitingApptID: 0,
            oldWaitingApptID: 0
        });
    }

    async cancelAppointmentEdit() {
        if (this.appointmentID === 0 || this.appointmentID === -1) {
            this.setState({
                waitingApptID: 0,
                oldWaitingApptID: 0
            })

            if (this.parentAppointment) {
                // Are we pre-booking? If so, go back to the original appointment
                await this.cancelPreBook();
                const newAppointments = this.state.appointments.filter(a => a.appointmentID !== 0);
                this.setState({
                    appointments: newAppointments
                });
            } else {
                // Otherwise, go back to browse mode
                this.returnToBrowseMode();
            }
        } else {

            // Restore the old schedule (to undo any unsaved changes)
            const appointments = [...this.state.appointments];
            if (this.oldSchedule) {
                const index = appointments.findIndex(a => a.appointmentID == this.appointmentID);
                if (index != -1) {
                    const appointment = { ...this.state.appointments[index] };
                    appointment.appointmentServices = this.oldSchedule.services;
                    appointment.appointmentPackages = this.oldSchedule.packages;
                    delete this.oldSchedule;
                    appointments[index] = appointment;
                }
            }

            // Update UI
            this.setState({
                mode: 'booking-menu',
                appointments: appointments,
                selectedTime: this.oldTime
            });
        }
    }

    async updateAppointmentFields(appointmentID, values) {
        const newAppointments = [...this.state.appointments];
        const appointmentIndex = newAppointments.findIndex(a => a.appointmentID == appointmentID);

        newAppointments[appointmentIndex] = {
            ...newAppointments[appointmentIndex]
        };

        let updateTotals = false;
        for (var key in values) {
            let value = values[key];
            const appointment = newAppointments[appointmentIndex];

            if (appointment[key] != value) {
                this.isChanged = true;
                GlobalStateService.setValue('unsavedChanges', true);
            }

            if (key == 'appointmentServices') {
                // Get date (do this first as the new date is needed by processAppointmentServices) 
                if (value.length > 0 && value[0].time) {
                    const date = moment.utc(value[0].time).format('YYYY-MM-DD');
                    appointment.date = date;
                }
                value = this.processAppointmentServices(value, appointment.date);
                updateTotals = true;
            } else if (key == 'appointmentPurchases' || key == 'appointmentPackages' || key == 'tips') {
                updateTotals = true;
            }
            appointment[key] = value;
        }


        const appointment = newAppointments[appointmentIndex];
        if (appointment.appointmentID == 0 && appointment.appointmentServices.length == 0 && appointment.appointmentPackages.length == 0) {
            this.isChanged = false;
            GlobalStateService.setValue('unsavedChanges', false);
        }

        if (updateTotals) {
            DiaryService.updateTotals(newAppointments[appointmentIndex]);
        }

        await this.setStateAsync({
            appointments: newAppointments
        });
    }

    changeDate() {
        this.oldDate = this.state.diary.date;
        this.setState({
            mode: 'change-date'
        });
    }

    async saveAppointmentSchedule() {
        const appointment = this.state.appointments.find(a => a.appointmentID === this.appointmentID);
        const waitingApptID = this.state.waitingApptID;
        if (appointment.internalApptType) {
            return this.saveInternalAppt(appointment);
        }

        // Warn if no services
        if (appointment.appointmentServices.length == 0) {
            BootboxHelper.alert('Please select at least one service');
            return;
        }
        for (var i = 0; i < appointment.appointmentServices.length; i++) {
            var appointmentService = appointment.appointmentServices[i];
            if (((appointment.status != 'checkedOut' && !appointmentService.time) || !appointmentService.stylistUserID) && !appointment.appointmentID) {
                BootboxHelper.alert('Please select a stylist and time for all services');
                return;
            }
        }

        // If new customer, warn if no contact details specified
        if (!appointment.customer.customerID) {
            if (!appointment.customer.firstName && appointment.customer.lastName) {
                BootboxHelper.alert('Please provide the customer\'s first and/or last name');
                return;
            }
            if (!appointment.customer.mobileTel && appointment.customer.email) {
                BootboxHelper.alert('Please provide the customer\'s phone number or email address');
                return;
            }
        }

        this.setState({
            isLoading: true,
            waitingApptID: 0,
            oldWaitingApptID: 0,
        });

        try {
            const { appointmentID } = await DiaryService.saveAppointmentSchedule(appointment);

            if (waitingApptID) {
                await WaitingApptService.book(waitingApptID);
            }

            this.setState({
                isLoading: false
            });

            this.isChanged = false;
            GlobalStateService.setValue('unsavedChanges', false);

            if (this.appointmentID === 0) {
                const newAppointments = this.state.appointments.filter(a => a.appointmentID !== 0);
                newAppointments.push(appointment);
                this.appointmentID = appointmentID;
                await this.setStateAsync({
                    mode: 'booking-confirmed',
                    selectedTime: null,
                    appointments: newAppointments,
                });
                //this.load(null, true);
            } else {
                this.appointmentID = -1;
                this.cancelAppointmentEdit();
            }
            this.load(null, true);

            if (!appointment.customer.customerID) {
                SearchService.updateNgramIndex(true);
            }

        } catch (error) {
            BootboxHelper.alert(error);
        } finally {
            this.setState({
                isLoading: false
            });
        }
    }

    async blockBookingSaved() {
        const newAppointments = this.state.appointments.filter(a => a.appointmentID !== 0);
        this.setState({
            mode: 'booking-confirmed',
            selectedTime: null,
            appointments: newAppointments
        }, () => {
            this.load(null, true);
        });
    }

    async saveInternalAppt(appointment) {
        this.setState({
            isLoading: true
        });
        await DiaryService.saveAppointmentSchedule(appointment);
        this.setState({
            isLoading: false
        });
        this.appointmentID = -1;
        this.cancelAppointmentEdit();
        this.load(null, true);
    }

    async loadAppointmentSchedule(id) {
        await this.loadBookingInfo();

        const appointmentIndex = this.state.appointments.findIndex(a => a.appointmentID === id);
        if (appointmentIndex !== -1) {
            this.setState({ isLoading: true });
            const appointment = await DiaryService.loadSchedule(id);

            const newAppointments = [...this.state.appointments];
            appointment.appointmentServices = this.processAppointmentServices(appointment.appointmentServices, appointment.date);
            newAppointments[appointmentIndex] = appointment;
            this.oldSchedule = { // Keep a backup in case we cancel
                services: [...appointment.appointmentServices.map(asv => ({ ...asv }))],
                packages: [...appointment.appointmentPackages.map(ap => ({ ...ap }))]
            };

            // Determine last time to set the new time slot
            let newTime = null;
            let newStylistID = null;
            if (appointment.appointmentServices.length > 0) {
                const lastAppointmentService = appointment.appointmentServices[appointment.appointmentServices.length - 1];
                if (lastAppointmentService.time && lastAppointmentService.stylistUserID) {
                    newTime = moment(lastAppointmentService.time);
                    newTime.add(lastAppointmentService.durationMins, 'minutes');
                    newTime = newTime.format('HH:mm');
                    newStylistID = lastAppointmentService.stylistUserID;
                }
            }
            this.oldTime = newTime;

            this.appointmentID = id;
            this.setState({
                isLoading: false,
                appointments: newAppointments,
                selectedTime: newTime,
                selectedStylistID: newStylistID,
                mode: 'edit-schedule'
            });
        }
    }

    async loadCheckOut(id, isDeposit) {
        await this.loadBookingInfo();
        this.setAppointmentIDAndMode(id, (isDeposit ? 'add-deposit' : 'check-out'));
    }

    async startAppointmentPreBook() {
        // Save current appointment as parent appointment
        this.parentAppointment = this.state.appointments.find(a => a.appointmentID === this.appointmentID);
        this.appointmentID = -1;
        this.previousMode = this.state.mode;
        this.isChanged = false;
        GlobalStateService.setValue('unsavedChanges', false);

        // Switch to pre-book mode
        this.setState({
            mode: 'pre-book'
        });
    }

    async cancelPreBook() {
        const parentAppointment = this.parentAppointment;
        if (parentAppointment) {
            this.parentAppointment = null; // Forget pre-booking info

            // Navigate back to the original date
            // (Must be done before trying to load the old appointment)
            await this.selectDate({
                date: new Date(parentAppointment.date)
            });

            // Go back
            if (this.previousMode == 'booking-confirmed') {
                this.returnToBrowseMode();
            } else {
                await this.setAppointmentIDAndMode(parentAppointment.appointmentID, this.previousMode, (this.previousMode == 'check-out' ? 2 : null));
            }
        } else {
            this.returnToBrowseMode();
        }
    }

    navigate(delta) {
        this.selectDate({
            date: moment(this.state.diary.date).add(delta, 'days').toDate()
        });
    }

    goToRetailArea() {
        this.setState({
            mode: 'retail-menu'
        });
    }

    async confirmNavigateAway(selectedAppointmentID) {
        //added to restrict the user without saving the appointment
        if ((this.appointmentID === 0 && selectedAppointmentID > 0) && (this.state.mode == 'edit-schedule'
            && this.state.appointments.find(ap => ap.appointmentID === 0)?.customer != null)) {
            //continue ;
        }
        else if (this.appointmentID <= 0 || this.state.mode == 'browse' || this.state.mode == 'booking-menu') {
            return true;
        }
        else if (selectedAppointmentID > 0 && this.state.mode == 'client-record' && this.state.isClientRecordUpdated) {
            const confirm = await BootboxHelper.confirm(`Do you want to save notes before you exit?`);
            if (confirm) {
                await this.clientRecordRef.current.save();
            }
            this.setState({
                isClientRecordUpdated: false
            });
            return true;
        }

        if (this.isChanged) {
            const confirm = await BootboxHelper.confirm('If you navigate away, the changes you made to this appointment will be lost. Is that ok?');
            return confirm;
        }

        return true;
    }

    handleBlockedNavigation = async (nextLocation) => {
        const confirm = await BootboxHelper.confirm('If you navigate away, the changes you made to this appointment will be lost. Is that ok?');
        if (confirm) {
            this.isChanged = false;
            GlobalStateService.setValue('unsavedChanges', false);
            if (nextLocation.pathname == "/diary") {
                this.onReplayRoute();
            } else {
                this.props.history.push(nextLocation.pathname);
            }
        }
    };

    async showEditRotaDateModal(stylistUserID) {
        await this.editRotaDateModelRef.current.show({
            stylistUserID: stylistUserID,
            date: this.state.diary.date
        });
        this.load();
    }

    async checkForOnlineBookings() {
        const numOnlineBookings = await DiaryService.getNumOnlineBookings();
        const numPendingRefunds = await DiaryService.getNumPendingRefunds();
        this.setState({
            numOnlineBookings,
            numPendingRefunds
        });
    }

    async showSendSMSModal(customerID, appointmentID) {
        await this.sendSMSModalRef.current.show({
            customerID,
            appointmentID
        });
    }

    async showFormListModal(customerID = -1) {
        var appts = this.state.appointments;
        await this.formListModalRef.current.show({
            customerID,
            appts
        });
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Appointment / Services
    //--------------------------------------------------------------------------------------------------------------------

    async loadBookingInfo() {
        this.setState({
            isLoading: true
        });
        const bookingInfo = await DiaryService.getBookingInfo(); // TODO catch errors

        // Only service categories with services or packages in
        const serviceCategories = bookingInfo.serviceCategories.filter(sc =>
            bookingInfo.services.some(s => s.serviceCategoryIDs.findIndex(i => String(i) == String(sc.serviceCategoryID)) != -1 && !s.isPackageOnly) ||
            bookingInfo.packages.some(p => p.serviceCategoryIDs.findIndex(i => String(i) == String(sc.serviceCategoryID)) != -1)
        );

        this.pricing = bookingInfo.pricing;
        this.setState({
            serviceCategories: serviceCategories,
            services: bookingInfo.services,
            packages: bookingInfo.packages,
            isLoading: false
        });
    }

    listCalendarEvents() {
        let events = [];

        this.state.appointments.forEach(a => {
            a.appointmentServices.forEach(as => {
                // Ignore if there's no time or stylist
                if (!as.time || !as.stylistUserID) {
                    return;
                }

                // Build event
                const start = moment(as.time).toDate();
                const end = moment(start).add(as.durationMins, 'minutes').toDate();
                if (a.internalApptType) {
                    // Internal appt
                    events.push({
                        id: as.appointmentServiceID,
                        internalApptType: a.internalApptType,
                        appointmentID: a.appointmentID,
                        resourceId: as.stylistUserID,
                        start: start,
                        end: end,
                        durationMins: as.durationMins,
                        notes: a.notes
                    });
                } else {
                    const loginDetails = GlobalStateService.getValue('loginDetails');
                    const hideCustomerLastName = loginDetails.permissions['ClientCardHideClientLastNames'];

                    // Customer event
                    events.push({
                        id: as.appointmentServiceID,
                        appointmentID: a.appointmentID,
                        resourceId: as.stylistUserID,
                        customerID: (a.customer ? a.customer.customerID : null),
                        appointmentPackageID: as.appointmentPackageID,
                        start,
                        end,
                        hasUnreadComm: a.hasUnreadComm,
                        hasMobileNumber: a.hasMobileNumber,
                        serviceName: as.service.name,
                        status: a.status,
                        customerName: a.customer ? hideCustomerLastName ? a.customer.firstName : (TextHelpers.formatName(a.customer.firstName, a.customer.lastName) || a.customer.name) : '(No customer)',
                        customerPronouns: a.customer ? a.customer.pronouns : null,
                        customerFullName: (
                            a.customer ? (a.customer.customerID ? a.customer.fullName : '(New)')
                                : '(No Customer)'
                        )
                    });
                }
            });
        });

        // Workaround for a bug in React-big-calendar that causes the drag and drop to fail
        // If the events array ever goes empty
        if (this.state.stylists.length > 0 && events.length == 0 && (!this.state.selectedTime || !this.state.selectedStylistID)) {
            const start = DateHelpers.stripTime(this.state.diary.date);
            const end = moment(start).add(60, 'minute').toDate();

            events.push({
                id: 'Dummy',
                resourceId: this.state.stylists[0].userID,
                start: start,
                end: end,
                isBackgroundEvent: true
            });
        }

        // If there is a current time selected, show a placeholder for it too
        if (this.state.selectedTime && this.state.selectedStylistID) {
            const start = moment(moment(this.state.diary.date).format('YYYY-MM-DD') + ' ' + this.state.selectedTime).toDate();
            const end = moment(start).add(15, 'minute').toDate(); // TODO use the smallest increment

            events.push({
                id: 'SelectedTime',
                resourceId: this.state.selectedStylistID,
                start: start,
                end: end,
                isBackgroundEvent: true
            });
        }

        // Add background events for:
        // - days where the salon is closed
        // - any stylists not working today
        // - start and end of the day (outside of salon open hours)

        // Determine how many days to look ahead
        const date = moment(this.state.diary.date);
        let numDays = 0;
        switch (this.state.diary.view) {
            case 'day': numDays = 1; break;
            case 'week': numDays = 7; break;
        }

        // Enumerate dates in the range
        for (var i = 0; i < numDays; i++) {
            const dateISO = moment(date).format('YYYY-MM-DD');
            const dateInfo = this.state.dates[dateISO];

            if (!dateInfo) {
                continue;
            }

            let backgroundEventNum = 0;
            dateInfo.stylists.forEach(s => {
                const { statusByTime, numBlocks } = this.getStatusByTime(s, dateInfo);
                let mins = 0;
                let blockStartMins = 0;
                let blockStatusCode = null;
                for (let i = 0; i <= numBlocks; i++) { // <= to account for the extra one added
                    const status = statusByTime[mins];
                    const statusCode = (status ?
                        (status.workingType == 'notWorking' ? (status.isSalonOpen ? 'notWorking' : 'closed') : status.workingType) :
                        'end'
                    );

                    if (statusCode != blockStatusCode) {
                        if (blockStartMins != mins && blockStatusCode != 'working') {
                            // Get parameters for background event
                            const startTime = DateHelpers.numMinsToTime(blockStartMins);
                            if (mins == 1440) mins = 1439; // react-big-calendar doesn't display the event if it reaches 24hrs so set to 23:59
                            const endTime = DateHelpers.numMinsToTime(mins);
                            let text, className;
                            switch (blockStatusCode) {
                                case 'closed':
                                    className = 'rbc-salon-closed';
                                    text = 'Salon closed';
                                    break;
                                default:
                                    className = 'rbc-day-off';
                                    text = DiaryService.getWorkingTypeFriendly(blockStatusCode);
                                    break;
                            }


                            // Add event
                            events.push({
                                id: `bg-${backgroundEventNum++}`,
                                resourceId: s.userID,
                                start: moment(`${dateISO} ${startTime}`).toDate(),
                                end: moment(`${dateISO} ${endTime}`).toDate(),
                                isBackgroundEvent: true,
                                className,
                                text
                            });
                        }
                        blockStartMins = mins;
                        blockStatusCode = statusCode;
                    }

                    // Next block
                    mins += 5;
                }

            });

            date.add(1, 'days');
        }

        return events;
    }

    getServicePrice(serviceID, stylistUserID, pkg) {
        const servicePricing = this.pricing[serviceID];
        let price = null;

        // Get price for this service / stylist combo
        if (servicePricing) {
            const stylistPricing = servicePricing.stylists[stylistUserID];
            if (stylistPricing) {
                price = stylistPricing.price;
            } else {
                price = servicePricing.defaultPrice;
            }
        }

        // Apply package discount
        if (pkg && pkg.pricingType == 'discountPercentage') {
            price *= (1 - (Number(pkg.fixedPriceOrDiscountProportion) || 0));
            price = Math.round(price * 100) / 100; // Round to 2DP
        }

        return price;
    }

    getStylistServiceDuration(serviceID, stylistUserID) {
        const serviceDuration = this.pricing[serviceID];
        let stylistServiceDuration = null;

        // Get duration for this service/stylist combo
        if (serviceDuration) {
            const stylistSerDuration = serviceDuration.stylists[stylistUserID];
            if (stylistSerDuration) {
                stylistServiceDuration = stylistSerDuration.stylistDurationMins;
            }
        }
        return stylistServiceDuration;
    }

    processAppointmentServices(appointmentServices, date) {
        // Put in order of time
        appointmentServices = appointmentServices.sort((a, b) => a.time - b.time);

        // Make sure that time is in Date format (not string)
        appointmentServices.forEach(apptService => {
            if (apptService.time) {
                apptService.time = moment(apptService.time).toDate();
            }
        });

        // Create a lookup of all times occupied by appointment services, to check for overlaps
        const occupiedTimes = {};
        const { appointments } = this.state;
        for (let i = 0; i < appointments.length; i++) {
            const appt = appointments[i];

            if (appt.customer) {
                // Customer appt
                for (let j = 0; j < appt.appointmentServices.length; j++) {
                    const apptService = appt.appointmentServices[j];
                    let timePart = moment(apptService.time).format('HH:mm');
                    let timePartMins = DateHelpers.parseTimeToNumMins(timePart);
                    for (let k = 0; k < apptService.durationMins; k += 5) {
                        const key = `${timePart}-${apptService.stylistUserID}`;
                        if (!occupiedTimes[key]) {
                            occupiedTimes[key] = [];
                        }
                        occupiedTimes[key].push(`apptService-${apptService.appointmentServiceID}`);
                        timePartMins += 5;
                        timePart = DateHelpers.numMinsToTime(timePartMins);
                    }
                }
            } else {
                // Non customer appt
                let timePart = moment(appt.time).format('HH:mm');
                let timePartMins = DateHelpers.parseTimeToNumMins(timePart);
                for (let k = 0; k < appt.durationMins; k += 5) {
                    const key = `${timePart}-${appt.stylistUserID}`;
                    if (!occupiedTimes[key]) {
                        occupiedTimes[key] = [];
                    }
                    occupiedTimes[key].push(`appt-${appt.appointmentID}`);
                    timePartMins += 5;
                    timePart = DateHelpers.numMinsToTime(timePartMins);
                }
            }
        }

        // Sanity check
        var nextApptService = null;
        for (var i = 0; i < appointmentServices.length; i++) {
            var apptService = appointmentServices[i];

            apptService.warnings = [];

            // Does the service require a certain capability?
            if (apptService.stylistUserID && apptService.service && apptService.service.capabilityID) {
                const stylist = this.state.stylists.find(s => s.userID == apptService.stylistUserID);
                if (stylist) {
                    const hasCapability = stylist.capabilityIDs.findIndex(c => c == apptService.service.capabilityID) != -1;
                    if (!hasCapability) {
                        apptService.warnings.push(stylist.nickname + ' can\'t do ' + apptService.service.capabilityName);
                    }
                }
            }

            // Enough time between services?
            nextApptService = null;
            if (i < appointmentServices.length - 1) {
                nextApptService = appointmentServices[i + 1];
            }
            let cooldownPeriod = 0;
            const end = moment(apptService.time)
                .add(apptService.durationMins, 'minutes')
                .toDate();
            cooldownPeriod = DiaryService.getCooldownPeriod(apptService, nextApptService?.service);
            if (nextApptService && nextApptService.time) {
                const endPlusCooldown = moment(end)
                    .add(cooldownPeriod, 'minutes')
                    .toDate();
                if (nextApptService.time < endPlusCooldown && cooldownPeriod > 0) {
                    apptService.warnings.push('Leave at least ' + cooldownPeriod + ' minutes');
                }
            }

            // Get salon open/closed status
            const dateISO = moment(date).format('YYYY-MM-DD');
            const dateInfo = this.state.dates[dateISO];
            const statusByTimeCached = {};

            // Does this overlap with another appointment or fall outside of the working time of the stylist?
            if (apptService.stylistUserID) {
                let timePart = moment(apptService.time).format('HH:mm');
                let timePartMins = DateHelpers.parseTimeToNumMins(timePart);

                // Get status by time
                const stylist = dateInfo.stylists.find(s => s.userID == apptService.stylistUserID);
                let statusByTime = statusByTimeCached[apptService.stylistUserID];
                if (!statusByTime) {
                    const result = this.getStatusByTime(stylist, dateInfo);
                    statusByTime = result.statusByTime;
                    statusByTimeCached[apptService.stylistUserID] = statusByTime;
                }

                let hasWarnedAboutNotWorking = false;
                let hasWarnedAboutOverlap = false;
                for (let j = 0; j < apptService.durationMins + cooldownPeriod; j += 5) {
                    // Check for stylist not working
                    if (!hasWarnedAboutNotWorking) {
                        const status = statusByTime[timePartMins];
                        if (status && status.workingType != 'working') {
                            if (j >= apptService.durationMins) {
                                apptService.warnings.push('Leave at least ' + cooldownPeriod + ' minutes');
                            } else if (status.isSalonOpen) {
                                apptService.warnings.push(stylist.nickname + ' isn\'t working at this time');
                            } else {
                                apptService.warnings.push('The salon is closed at this time');
                            }
                            hasWarnedAboutNotWorking = true;
                        }
                    }

                    // Check for overlapping appt (for the service time itself, ignoring the cooldown period)
                    if (j < apptService.durationMins && !hasWarnedAboutOverlap) {
                        const key = `${timePart}-${apptService.stylistUserID}`;
                        if (occupiedTimes[key] && occupiedTimes[key].find(ot => ot != `apptService-${apptService.appointmentServiceID}`)) {
                            apptService.warnings.push('Whoops! This is overlapping');
                            hasWarnedAboutOverlap = true;
                        }
                    }

                    // Stop checking if no other errors to capture
                    if (hasWarnedAboutNotWorking && hasWarnedAboutOverlap) {
                        break;
                    }

                    timePartMins += 5;
                    timePart = DateHelpers.numMinsToTime(timePartMins);
                }
            }
        }

        return appointmentServices;
    }

    async updateAppointmentStatus(appointmentID, status) {
        if (appointmentID != this.appointmentID) {
            await this.returnToBrowseMode();
        }
        await this.updateAppointmentFields(this.appointmentID, {
            dateArrived: new Date(),
            status: status
        });

        this.isChanged = false;
        GlobalStateService.setValue('unsavedChanges', false);

        await DiaryService.updateStatus(appointmentID, status);
        this.load(null, true);
    }

    async confirmCancelOrDeleteAppt(appointmentID, type) {
        this.returnToBrowseMode();
        await this.cancelApptModalRef.current.show({
            type,
            appointmentID
        });
        this.load(null, true);
    }

    getStatusByTime(stylist, dateInfo) {
        // Build an array of statuses () keyed by time (# mins since midnight), split into diaryInterval chunks
        const statusByTime = {};
        let mins = 0;
        const numBlocks = Math.ceil(1440 / 5);
        for (let i = 0; i < numBlocks; i++) {
            const status = { workingType: 'notWorking', isSalonOpen: false };
            statusByTime[mins] = status;
            mins += 5;
        }

        // Add stylist working time (do this first as it supercedes whether or not the salon is open)
        // No rota information = stylist not working
        if (!stylist.rotaDayMulti || stylist.rotaDayMulti.length == 0) {
            stylist.rotaDayMulti = [{ workingType: 'notWorking' }];
        }

        // Enumerate each working type for this stylist/date
        for (let i = 0; i < stylist.rotaDayMulti.length; i++) {
            const rotaDay = stylist.rotaDayMulti[i];

            // Determine start and end time for this working type
            const startTime = rotaDay.startTime || (dateInfo.isOpen ? dateInfo.openingTime : '00:00');// || this.state.minTime;
            const endTime = rotaDay.endTime || (dateInfo.isOpen ? dateInfo.closingTime : '23:59');// || this.state.maxTime;

            // Populate the array
            const startTimeMins = Math.floor(DateHelpers.parseTimeToNumMins(startTime) / 5) * 5;
            const endTimeMins = Math.ceil(DateHelpers.parseTimeToNumMins(endTime) / 5 ) * 5;
            for (let j = startTimeMins; j < endTimeMins; j += 5) {
                if (!statusByTime[j]) {
                    statusByTime[j] = { isSalonOpen: true };
                }
                statusByTime[j].workingType = rotaDay.workingType;
            }
        }

        // Add salon opening times (if open)
        if (dateInfo.isOpen) {
            const openingTime = dateInfo.openingTime || this.state.minTime;
            const closingTime = dateInfo.closingTime || this.state.maxTime;
            const openingTimeMins = Math.floor(DateHelpers.parseTimeToNumMins(openingTime) / 5) * 5;
            const closingTimeMins = Math.ceil(DateHelpers.parseTimeToNumMins(closingTime) / 5) * 5;
            for (let i = openingTimeMins; i < closingTimeMins; i += 5) {
                if (!statusByTime[i]) {
                    statusByTime[i] = { };
                }
                statusByTime[i].isSalonOpen = true;
            }
        }
        statusByTime[1440] = null; // To easily create an event at the end of the traversal later

        return {
            statusByTime,
            numBlocks
        };
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Calendar event handler
    //--------------------------------------------------------------------------------------------------------------------

    async onEventDropOrResize(info) {
        // Correct highlighting in the service list
        $('.service-list li').removeClass('highlighted');

        const loginDetails = GlobalStateService.getValue('loginDetails');
        if (!loginDetails.permissions['DiaryEditAppointments']) {
            ReactTooltip.hide();
            return;
        }

        // Find appointment service
        const appointment = this.state.appointments.find(a => a.appointmentID === info.event.appointmentID);
        let appointmentServices = [...appointment.appointmentServices];
        const appointmentServiceIndex = appointmentServices.findIndex(as => as.appointmentServiceID == info.event.id);

        // Update start, duration, stylist, price
        const oldApptService = appointmentServices[appointmentServiceIndex];
        const apptService = {
            ...oldApptService,
            stylistUserID: info.resourceId,
            time: info.start,
            durationMins: moment(info.end).diff(info.start, 'minutes')
        };

        // Undo resizing calendar events smaller than diary interval due to bug with react-big-calendar
        if (apptService.durationMins === 0) {
            info.end = moment(info.start).add({ minutes: this.state.diaryInterval });
            apptService.durationMins = this.state.diaryInterval;
        }

        // Only update price if not part of a fixed-price package
        // and if the stylist has changed
        if (apptService.service && apptService.stylistUserID != oldApptService.stylistUserID) {
            let canUpdatePrice = true;
            let appointmentPackage = null;
            if (apptService.appointmentPackageID) {
                appointmentPackage = (appointment.appointmentPackages || []).find(ap => ap.appointmentPackageID == apptService.appointmentPackageID);
                if (appointmentPackage) {
                    canUpdatePrice = appointmentPackage.pricingType != 'fixed';
                }
            }
            if (canUpdatePrice) {
                apptService.total = this.getServicePrice(apptService.service.serviceID, info.resourceId, appointmentPackage?.package)
            }
            const newDuration = this.getStylistServiceDuration(apptService.service.serviceID, info.resourceId);
            if (newDuration) {
                apptService.durationMins = newDuration;
            }
        }
        appointmentServices[appointmentServiceIndex] = apptService;
        this.updateAppointmentFields(info.event.appointmentID, {
            appointmentServices,
            hasUnreadComm: false,
            status: (appointment.status == 'confirmed' ? 'unconfirmed' : appointment.status)
        });

        // Update on server
        if (appointment.appointmentID &&
            (appointment.appointmentID != this.appointmentID || this.state.mode != 'edit-schedule') &&
            (apptService.appointmentServiceID || appointment.internalApptType) &&
            (this.state.mode != 'change-date')
        ) {
            const { isStartTimeChanged } = await DiaryService.rescheduleAppointmentService(
                appointment.appointmentID,
                apptService.appointmentServiceID,
                apptService.stylistUserID,
                apptService.time,
                apptService.durationMins
            );

            this.isChanged = false;
            GlobalStateService.setValue('unsavedChanges', false);

            // Offer to notify client
            if (isStartTimeChanged) {
                const confirm = await BootboxHelper.confirm('Do you want to notify the client of the rescheduled appointment?');
                if (confirm) {
                    await DiaryService.triggerAppointmentRescheduledAutomation(appointment.appointmentID);
                }
            }
        }

        // Update dropdown on checkout if active
        if (this.state.mode == 'check-out' && this.checkOutRef.current) {
            this.checkOutRef.current.move(
                apptService.appointmentServiceID,
                apptService.stylistUserID,
                apptService.time
            );
        }

        // Hide tooltip
        ReactTooltip.hide();
    }

    async onEventClick(evt, e) {
        e.preventDefault();
        e.stopPropagation();

        if (evt.appointmentID != this.appointmentID && !this.state.isLoading) {

            if (!(await this.confirmNavigateAway(evt.appointmentID))) {
                return;
            }

            this.setAppointmentIDAndMode(evt.appointmentID, 'booking-menu');
        }
    }

    getIsEventEditable(evt) {
        return (evt.id != 'SelectedTime');
    }

    async changeDateTimeStylist(newDateTime, stylistUserID) {
        // Go through each appointmentService and stack them up
        const appointment = this.state.appointments.find(a => a.appointmentID === this.appointmentID);
        appointment.date = new Date(moment(newDateTime).format('YYYY-MM-DD'));
        for (let i = 0; i < appointment.appointmentServices.length; i++) {
            const apptService = appointment.appointmentServices[i];
            if (apptService.time) {
                const nextService = (i < appointment.appointmentServices - 1 ? appointment.appointmentServices[i + 1].service : null);
                const duration = apptService.durationMins + DiaryService.getCooldownPeriod(apptService, nextService);
                apptService.stylistUserID = stylistUserID;
                apptService.time = newDateTime;
                newDateTime = moment(newDateTime).add(duration, 'minutes').toDate();
            }
        }

        this.updateAppointmentFields(this.appointmentID, {
            appointmentServices: appointment.appointmentServices
        });
        this.isChanged = true;
        GlobalStateService.setValue('unsavedChanges', true);
        await this.loadBookingInfo();
        this.setState({
            mode: 'edit-schedule',
            selectedTime: moment(newDateTime).format('HH:mm'),
            selectedStylistID: stylistUserID
        });
    }

    async cancelDateChange() {
        this.selectDate({
            date: this.oldDate
        });
        this.setState({
            mode: 'edit-schedule'
        });
        await this.loadBookingInfo();
    }

    highlightEvent(id) {
        $('#Service-' + id).addClass('highlighted');
    }

    unhighlightEvent(id) {
        $('#Service-' + id).removeClass('highlighted');
    }

    async selectSlot(time, resourceID) {
        const loginDetails = GlobalStateService.getValue('loginDetails');

        if (this.state.mode == 'browse' || this.state.mode == 'edit-schedule' || this.state.mode == 'check-out' || this.state.mode == 'add-deposit' || this.state.mode == 'booking-menu' || this.state.mode == 'client-record') {
            if (!(await this.confirmNavigateAway(this.appointmentID))) {
                return;
            }

            if (this.appointmentID > 0) {
                await this.load(null, true, true);
            }
            await this.selectDate({
                date: moment(time).toDate()
            });
            $('.rbc-event').removeClass('highlighted');
            this.setState({
                selectedTime: moment(time).format('HH:mm'),
                selectedStylistID: resourceID
            });

            if (this.appointmentID > 0) {
                this.appointmentID = -1;
            }
            if (this.appointmentID == -1) {
                if (loginDetails.permissions['DiaryCreateAppointments'] || loginDetails.permissions['DiaryCreateInternalAppointments']) {
                    this.newAppointment(time, resourceID);
                } else {
                    this.setState({
                        mode: 'browse'
                    });
                }
            }

        } else if (this.state.mode == 'pre-book') {

            await this.selectDate({
                date: moment(time).toDate()
            });
            this.setState({
                selectedTime: moment(time).format('HH:mm'),
                selectedStylistID: resourceID
            });
            this.newAppointment(time, resourceID);

        } else if (this.state.mode == 'booking-confirmed') {
            this.setState({
                selectedTime: null,
                mode: 'browse'
            });
            this.appointmentID = -1;
        } else if (this.state.mode == 'change-date') {
            this.changeDateTimeStylist(time, resourceID);
        }
    }

    updateSlot(time) {
        this.setState({
            selectedTime: time
        });
    }

    async printApptPDF(apptID) {
        if (this.state.isPrinting) {
            return;
        }
        this.setState({ isPrinting: true });
        await ThermalPrinterService.printApptWithPDFFallback(apptID);
        this.setState({ isPrinting: false });
    }

    async printCustomerApptsPDF(customerID) {
        if (this.state.isPrinting) {
            return;
        }
        this.setState({ isPrinting: true });
        await ThermalPrinterService.printCustomerApptsWithPDFFallback(customerID, moment().format('YYYY-MM-DD'));
        this.setState({ isPrinting: false });
    }

    async printStylistApptsPDF(stylistUserID, date) {
        if (this.state.isPrinting) {
            return;
        }
        if (!date) {
            date = this.state.diary.date;
        }
        date = moment(date);
        this.setState({ isPrinting: true });
        if (stylistUserID) {
            await ThermalPrinterService.printStylistApptsWithPDFFallback(stylistUserID, moment(date).format('YYYY-MM-DD'));
        } else {
            await PrintService.printURL('/api/appointment/get-stylist-appts-pdf?date=' + moment(date).format('YYYY-MM-DD'));
        }
        this.setState({ isPrinting: false });
    }

    async showOnlineBookings() {
        await this.onlineBookingsModalRef.current.show({});
        this.load(null, true, true);
    }

    async showPendingRefunds() {
        await this.pendingRefundModalRef.current.show({});
        this.load(null, true, true);
    }

    async showWaitingList() {
        await this.waitingListModalRef.current.show({});
        this.load(null, true, true);
    }

    toggleHideStylistsNotWorking() {
        this.setState({
            hideStylistsNotIn: !this.state.hideStylistsNotIn
        }, () => {
            //this.load(null, true);
        });
    }

    showContextMenu(e, event) {
        const x = (e.touches ? e.touches[0].clientX : e.clientX);
        const y = (e.touches ? e.touches[0].clientY : e.clientY);

        if (this.isMobile) {
            x = 0;
            y = 0;
        }

        if (event.internalApptType) {

            showMenu({
                position: { x, y },
                target: e.target,
                id: 'internal-appt-context-menu',
                data: {
                    mode: this.state.mode,
                    appointmentID: event.appointmentID
                }
            });

        } else {

            showMenu({
                position: { x, y },
                target: e.target,
                id: 'appointment-context-menu',
                data: {
                    mode: this.state.mode,
                    appointmentID: event.appointmentID,
                    customerID: event.customerID,
                    hasMobileNumber: event.hasMobileNumber,
                    status: event.status,
                    hasAnyPayments: event.hasAnyPayments,
                    internalApptType: event.internalApptType
                }
            });

        }

    }

    async editWaitingAppt(waitingApptID) {
        await this.loadBookingInfo();
        await this.setState({ waitingApptID });
        this.setState({ mode: 'waiting-appt-editor' });
    }

    async bookWaitingAppt(waitingApptID) {
        await this.setState({ waitingApptID });
        this.newAppointment(null);
    }

    async loadedWaitingAppt() {
        const waitingApptID = this.state.waitingApptID;
        await this.setState({ oldWaitingApptID: waitingApptID });
    }

    //-------------------------------------------------------------------------------------------------------------------
    // Confirm link SMS
    //-------------------------------------------------------------------------------------------------------------------
    async sendConfirmLinkSMS(customerID, appointmentID, isDepositLink) {
        const linkSent = await DiaryService.sendConfirmLinkSMS(customerID, appointmentID, isDepositLink);
        if (linkSent) {
            BootboxHelper.alert('Confirm link SMS has been sent.');
        }
    }

    //---------------------------------------------------------------------------------------------------------------------
    // Appointment tags

    async showAppointmentTagsModal(appointmentID) {
        await this.appointmentTagsModalRef.current.show({            
            appointmentID
        });
    }
    //---------------------------------------------------------------------------------------------------------------------
    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        const {
            diary,
            hideStylistsNotIn,
            numOnlineBookings,
            numPendingRefunds
        } = this.state;
        const dateFormatted = moment(diary.date).format('dddd Do MMM YYYY');

        return (
            <InfoBar
                className="diary-info-bar"
                sticky={true}
                getContextMenu={() => <>
                    <MenuItem onClick={() => null} className="close">
                        <span className="fa fa-times"></span> Close menu
                    </MenuItem>
                    <MenuItem onClick={(e, data) => this.printStylistApptsPDF(null, null)} className="print-diary">
                        <span className="fa fa-print"></span>
                        Print diary
                    </MenuItem>
                    <MenuItem onClick={(e, data) => this.showOnlineBookings()}>
                        <span className="fa fa-wifi"></span>
                        Online bookings
                        {numOnlineBookings > 0 &&
                            <span className="menu-item-badge">{numOnlineBookings}</span>
                        }
                    </MenuItem>
                    <MenuItem onClick={(e, data) => this.showPendingRefunds()}>
                        <span className="fa fa-money-bill-transfer"></span>
                        Pending refunds
                        {numPendingRefunds > 0 &&
                            <span className="menu-item-badge">{numPendingRefunds}</span>
                        }
                    </MenuItem>
                    <MenuItem onClick={(e, data) => this.showWaitingList()}>
                        <span className="fa fa-list"></span>
                        Waiting List
                    </MenuItem>
                    <MenuItem onClick={(e, data) => this.showFormListModal()}>
                        <span className="fa fa-clipboard"></span>
                        Consultation Forms
                    </MenuItem>
                    <MenuItem onClick={(e, data) => this.toggleHideStylistsNotWorking()}>
                        <span className={hideStylistsNotIn ? 'far fa-check-square' : 'far fa-square'}></span>
                        Hide stylists not working
                    </MenuItem>
                </>}
                getContextMenuButtonBadge={() => numOnlineBookings || ''}
            >

                <div className="info-bar-panel-section">
                    <div className="button-group nav-date-buttons" role="group">
                        <button type="button" className="button" onClick={() => this.navigate(-7)} title="Move backward one week">
                            <span className="fa fa-chevron-left"></span>
                            <span className="fa fa-chevron-left"></span>
                        </button>
                        <button type="button" className="button" onClick={() => this.navigate(-1)} title="Move backward one day">
                            <span className="fa fa-chevron-left"></span>
                        </button>
                        <button type="button" className="button" onClick={() => this.navigate(+1)} title="Move forward one day">
                            <span className="fa fa-chevron-right"></span>
                        </button>
                        <button type="button" className="button" onClick={() => this.navigate(+7)} title="Move forward one week">
                            <span className="fa fa-chevron-right"></span>
                            <span className="fa fa-chevron-right"></span>
                        </button>
                    </div>
                </div>

                <div className="info-bar-panel-section">
                    <button className="button today-button" onClick={() => this.selectDate({ date: moment().toDate(), view: 'day', stylistID: null })}>
                        Today
                    </button>
                </div>

                <div className="info-bar-panel-section info-bar-panel-section-text">
                    {dateFormatted}
                </div>

            </InfoBar>
        );
    }

    renderMainContent() {
        const {
            diary,
            mode,
            isLoadingAppts,
            diaryInterval,
            minTime,
            maxTime
        } = this.state;
        const clientInfo = GlobalStateService.getValue('clientInfo');

        document.body.onmouseup = function () {
            document.body.classList.remove('disable-tooltips');
            document.body.classList.remove('dragging-appointment');
        };

        let minDateTime = (minTime ?
            moment.tz(moment(diary.date).format('YYYY-MM-DD') + 'T' + minTime, clientInfo.timeZoneLocation).toDate() :
            null
        );
        let maxDateTime = (maxTime ?
            moment.tz(moment(diary.date).format('YYYY-MM-DD') + 'T' + maxTime, clientInfo.timeZoneLocation).toDate() :
            null
        );
        const scrollToTime = this.isScrolled || moment(diary.date).format('YYYYMMDD') != moment().format('YYYYMMDD') ? null : new Date();

        // Render
        return (<>

            <div className="panel sticky-under-info-bar">

                <div className="panel-body">

                    <ErrorBoundary>

                        {isLoadingAppts ?
                            <Loader /> :
                            <Calendar
                                enableDragAndDrop={!this.isMobile}
                                className={'rbc-view-' + diary.view}
                                culture="en-gb"
                                views={['day', 'week']}
                                view={diary.view}
                                defaultView={diary.view}
                                date={diary.date}
                                defaultDate={diary.date}
                                min={minDateTime}
                                max={maxDateTime}
                                step={diaryInterval}
                                timeslots={1}
                                resourceIdAccessor="resourceId"
                                resourceTitleAccessor="resourceTitle"
                                scrollToTime={scrollToTime}
                                scrollToMiddle={true}
                                getDayVisible={(date, resource) => {
                                    if (!this.state.hideStylistsNotIn) {
                                        return true;
                                    }
                                    const dateKey = moment(date).format('YYYY-MM-DD');
                                    const dateInfo = this.state.dates[dateKey];
                                    if (dateInfo) {
                                        const stylist = dateInfo.stylists.find(s => s.userID == resource.resourceId);
                                        return stylist && stylist.rotaDayMulti;
                                    }
                                    return false;
                                }}
                                events={this.listCalendarEvents()}
                                resources={this.listCalendarResources()}
                                onNavigate={e => { /* NOP */ }}
                                onView={e => { /* NOP */ }}
                                onEventDrop={this.onEventDropOrResize}
                                onEventResize={this.onEventDropOrResize}
                                draggableAccessor={this.getIsEventEditable}
                                resizeableAccessor={this.getIsEventEditable}
                                onDragStart={e => {
                                    document.body.classList.add('disable-tooltips');
                                    document.body.classList.add('dragging-appointment');
                                }}
                                selectable
                                onSelectSlot={info => this.selectSlot(info.start, info.resourceId)}
                                longPressThreshold={250}

                                eventPropGetter={(event, start, end, isSelected) => {
                                    if (event.id == 'Dummy') {
                                        return { style: { display: 'none' } };
                                    } else if (event.id == 'SelectedTime') {
                                        return { className: 'current-time-selection' }
                                    }
                                    return {};
                                }}

                                components={{
                                    toolbar: () => (<></>),
                                    event: (info) => {
                                        // Don't render dummy
                                        if (info.event.id == 'Dummy') {
                                            return null;
                                        }

                                        // Selected time: render the time
                                        if (info.event.id == 'SelectedTime') {
                                            return (<>
                                                <span className="far fa-clock"></span>{' '}
                                                {this.state.selectedTime}
                                            </>);
                                        }

                                        // Background event: render text
                                        if (info.event.isBackgroundEvent) {
                                            return info.event.text || '';
                                        }

                                        // Determine classes
                                        const stylist = this.stylistsLookup[info.event.resourceId];
                                        const classes = ['rbc-event-inner'];
                                        if (this.appointmentID !== -1 && info.event.appointmentID != this.appointmentID) {
                                            classes.push('rbc-event-not-active');
                                        }
                                        if (info.event.hasWarnings) {
                                            classes.push('rbc-event-has-warnings');
                                        }
                                        if (info.event.status == 'provisional') {
                                            classes.push('rbc-event-provisional');
                                        }

                                        if (info.event.durationMins <= 15) {
                                            classes.push('rbc-event-tiny');
                                        } else if (info.event.durationMins <= 30) {
                                            classes.push('rbc-event-small');
                                        }
                                        if (info.event.appointmentPackageID) {
                                            classes.push('rbc-event-appt-package-' + info.event.appointmentPackageID);
                                        }

                                        // Internal appt
                                        if (info.event.internalApptType) {
                                            classes.push('rbc-event-internal-appt');

                                            const content = (
                                                <div
                                                    onClick={this.onEventClick.bind(this, info.event)}
                                                    id={'Event-' + info.event.id}
                                                    data-appointment-id={info.event.appointmentID}
                                                    className={classes.join(' ')}
                                                    onMouseEnter={e => {
                                                        if (!this.isMobile) {
                                                            this.apptTooltipRef.current.show({
                                                                apptID: info.event.appointmentID,
                                                                apptServiceID: info.event.id,
                                                                x: e.pageX,
                                                                y: e.pageY
                                                            });
                                                        }
                                                        this.highlightEvent(info.event.id)
                                                    }}
                                                    onMouseMove={e => {
                                                        if (!this.isMobile) {
                                                            this.apptTooltipRef.current.setPos(e.pageX, e.pageY);
                                                        }
                                                    }}
                                                    onMouseLeave={() => {
                                                        if (!this.isMobile) {
                                                            this.apptTooltipRef.current.hide();
                                                        }
                                                        this.unhighlightEvent(info.event.id)
                                                    }}
                                                    onTouchStart={e => this.handleEventTouch(e, info.event)}
                                                    onDoubleClick={e => this.showContextMenu(e, info.event)}
                                                >
                                                    <div className="calendar-event-time" style={info.event.isBackgroundEvent ? {} : {
                                                        color: info.event.internalApptType.diaryTextColour
                                                    }}>
                                                        {moment(info.event.start).format('HH:mm')}
                                                    </div>
                                                    <div className="calendar-event-service">{info.event.internalApptType.name}</div>
                                                    {info.event.notes &&
                                                        <div className="rbc-event-notes-icon">
                                                            <span className="fas fa-comment"></span>
                                                        </div>
                                                    }
                                                </div>
                                            );

                                            return (
                                                <ContextMenuTrigger
                                                    id="internal-appt-context-menu"
                                                    holdToDisplay={this.isMobile ? 500 : -1}
                                                    disableIfShiftIsPressed={true}
                                                    collect={() => ({
                                                        mode: mode,
                                                        appointmentID: info.event.appointmentID,
                                                        isInternal: true
                                                    })}
                                                >
                                                    {content}
                                                </ContextMenuTrigger>
                                            );

                                        } else {

                                            const content = (
                                                <div
                                                    id={'Event-' + info.event.id}
                                                    data-appointment-id={info.event.appointmentID}
                                                    className={classes.join(' ')}
                                                    onMouseEnter={e => {
                                                        if (!this.isMobile) {
                                                            this.apptTooltipRef.current.show({
                                                                apptID: info.event.appointmentID,
                                                                apptServiceID: info.event.id,
                                                                x: e.pageX,
                                                                y: e.pageY
                                                            });
                                                        }
                                                        this.highlightEvent(info.event.id)
                                                    }}
                                                    onMouseMove={e => {
                                                        if (!this.isMobile) {
                                                            this.apptTooltipRef.current.setPos(e.pageX, e.pageY);
                                                        }
                                                    }}
                                                    onMouseLeave={() => {
                                                        if (!this.isMobile) {
                                                            this.apptTooltipRef.current.hide();
                                                        }
                                                        this.unhighlightEvent(info.event.id)
                                                    }}
                                                    onClick={this.onEventClick.bind(this, info.event)}
                                                    onDoubleClick={e => this.showContextMenu(e, info.event)}
                                                >
                                                    <div className="calendar-event-stripe" style={{
                                                        backgroundColor: info.event.hasWarnings ? '#FF0000' : (stylist ? stylist.diaryColour : 'transparent')
                                                    }}></div>
                                                    <div className="calendar-event-time">
                                                        {moment(info.event.start).format('HH:mm')}
                                                    </div>
                                                    <div className="calendar-event-customer-name">{info.event.customerName}
                                                        {info.event.customerPronouns &&
                                                            <div className="calendar-event-customer-pronouns"> ({info.event.customerPronouns})</div>
                                                        }
                                                    </div>
                                                    <div className="calendar-event-service">{info.event.serviceName}</div>

                                                    {/* Status */}
                                                    <div
                                                        className={'rbc-event-status-icon ' + DiaryService.getAppointmentStatusClass(info.event)}
                                                    ></div>

                                                </div>
                                            );

                                            return (
                                                <ContextMenuTrigger
                                                    id="appointment-context-menu"
                                                    holdToDisplay={this.isMobile ? 500 : -1}
                                                    disableIfShiftIsPressed={true}
                                                    collect={async () => {
                                                        if (!this.isMobile) {
                                                            this.apptTooltipRef.current.hide();
                                                        }
                                                        return {
                                                            mode,
                                                            appointmentID: info.event.appointmentID,
                                                            customerID: info.event.customerID,
                                                            hasMobileNumber: info.event.hasMobileNumber,
                                                            hasAnyPayments: info.event.hasAnyPayments,
                                                            status: info.event.status,
                                                            isInternal: false,
                                                            hasDeposit: await DiaryService.checkApptHasDeposit(info.event.appointmentID),
                                                            hasTags: await DiaryService.checkApptHasTags(info.event.appointmentID)
                                                        };
                                                    }}
                                                >
                                                    {content}
                                                </ContextMenuTrigger>
                                            );
                                        }
                                    },
                                    dateHeader: (info) => {
                                        const style = {
                                            borderBottom: '4px solid ' + info.resource.diaryColour
                                        };
                                        return (
                                            <div
                                                style={style}
                                                className="rbc-stylist-header"
                                                onClick={e => this.printStylistApptsPDF(info.resource.resourceId, info.date)}
                                            >
                                                {info.label}
                                                <span className="fas fa-print"></span>
                                            </div>
                                        );
                                    },
                                    resourceHeader: (info) => {
                                        const style = {
                                            height: '100%',
                                            borderBottom: '4px solid ' + info.resource.diaryColour,
                                            margin: 'auto',
                                            marginBottom: '5px',
                                            display: 'inline-block',
                                            minWidth: '100px'
                                        };
                                        return (
                                            <ContextMenuTrigger
                                                id="stylist-context-menu"
                                                holdToDisplay={this.isMobile ? 500 : -1}
                                                disableIfShiftIsPressed={true}
                                                collect={() => ({
                                                    stylistUserID: info.resource.resourceId
                                                })}
                                            >
                                                <div
                                                    className="rbc-stylist-header"
                                                    style={style}
                                                    onMouseEnter={e => {
                                                        if (!this.isMobile) {
                                                            this.stylistTooltipRef.current.show({
                                                                userID: info.resource.resourceId,
                                                                date: this.state.diary.date,
                                                                x: e.pageX,
                                                                y: e.pageY
                                                            });
                                                        }
                                                    }}
                                                    onMouseMove={e => {
                                                        if (!this.isMobile) {
                                                            this.stylistTooltipRef.current.setPos(e.pageX, e.pageY);
                                                        }
                                                    }}
                                                    onMouseLeave={() => {
                                                        if (!this.isMobile) {
                                                            this.stylistTooltipRef.current.hide();
                                                        }
                                                    }}
                                                >
                                                    {info.label}
                                                </div>
                                            </ContextMenuTrigger>
                                        )
                                    }
                                }}
                            />
                        }

                    </ErrorBoundary>

                </div>

            </div>

            {this.renderExtras()}

        </>);
    }

    renderExtras() {

        return (<>

            <AppointmentContextMenu parent={this} />
            <InternalApptContextMenu parent={this} />
            <StylistContextMenu parent={this} />

            <EditRotaDateModal ref={this.editRotaDateModelRef} />
            <OnlineBookingsModal ref={this.onlineBookingsModalRef} />
            <WaitingListModal ref={this.waitingListModalRef}
                onEditClicked={this.editWaitingAppt}
                onBookClicked={this.bookWaitingAppt}
            />
            <SendSMSModal ref={this.sendSMSModalRef} />
            <CancelApptModal ref={this.cancelApptModalRef} />
            <FormListModal ref={this.formListModalRef} />
            <PendingRefundsModal ref={this.pendingRefundModalRef} />
            <AppointmentTagsModal ref={this.appointmentTagsModalRef} />

            <ApptTooltip
                ref={this.apptTooltipRef}
                diary={this.state.diary}
            />
            <StylistTooltip
                ref={this.stylistTooltipRef}
                diary={this.state.diary}
            />

            <ReactTooltip
                html={true}
                place="right"
                type="info"
                effect="float"
                delayShow={100}
                overridePosition={(pos) => {
                    if (pos.top < 75) pos.top = 75;
                    return pos;
                }}
            />

        </>);
    }

    renderSideContent() {
        const {
            appointments,
            diary,
            serviceCategories,
            services,
            packages,
            mode,
            stylists,
            selectedTime,
            selectedStylistID,
            diaryInterval,
            waitingApptID,
            oldWaitingApptID
        } = this.state;
        const appointment = appointments.find(a => a.appointmentID === this.appointmentID);

        if (this.state.isLoading) {
            return (<Loader />);
        }

        setTimeout(() => {
            this.checkOutStep = 0;
        });

        switch (mode) {
            case 'edit-schedule':
                if (!appointment) {
                    return (<Loader />);
                }
                return (
                    <AppointmentEditor
                        appointmentID={this.appointmentID}
                        date={diary.date}
                        stylists={stylists}
                        stylistsLookup={this.stylistsLookup}
                        serviceCategories={serviceCategories}
                        services={services}
                        packages={packages}
                        appointment={appointment}
                        selectedTime={selectedTime}
                        selectedStylistID={selectedStylistID}
                        diaryInterval={diaryInterval}
                        waitingApptID={waitingApptID}
                        oldWaitingApptID={oldWaitingApptID}

                        getServicePrice={this.getServicePrice}
                        getStylistServiceDuration={this.getStylistServiceDuration}
                        onChange={(field, value) => this.updateAppointmentFields(this.appointmentID, { [field]: value })}
                        onChangeMultiple={(values) => this.updateAppointmentFields(this.appointmentID, values)}
                        onCancelClicked={async () => {
                            await this.cancelAppointmentEdit();
                            //this.load(null, true, true);
                        }}
                        onSaveClicked={this.saveAppointmentSchedule}
                        onBlockBookingSaved={this.blockBookingSaved}
                        onChangeDateClicked={this.changeDate}
                        onSlotChange={this.updateSlot}
                        loadedWaitingAppt={this.loadedWaitingAppt}
                    />
                );
            case 'booking-confirmed':
                return (
                    <BookingConfirmed
                        isPreBooking={!!this.parentAppointment}
                        onContinueClicked={e => {
                            if (this.parentAppointment) {
                                this.cancelPreBook();
                            } else {
                                this.appointmentID = -1;
                                this.setState({
                                    selectedTime: null,
                                    mode: 'browse',
                                    checkOutStep: 3
                                });
                            }
                        }}
                        onBookAnotherClicked={e => this.startAppointmentPreBook()}
                    />
                );
            case 'booking-menu':
                if (!appointment) {
                    return (<Loader />);
                }
                return (
                    <BookingMenu
                        appointmentID={this.appointmentID}
                        onEditClicked={e => {
                            this.loadAppointmentSchedule(this.appointmentID);
                        }}
                        onConfirmClicked={e => {
                            if (appointment.status == 'provisional') {
                                this.updateAppointmentStatus(this.appointmentID, 'unconfirmed');
                            } else {
                                this.updateAppointmentStatus(this.appointmentID, 'confirmed');
                            }
                            this.isChanged = false;
                            this.returnToBrowseMode();
                        }}
                        onCheckOutClicked={e => {
                            this.loadCheckOut(this.appointmentID, false);
                        }}
                        onPreviousColourNotesClicked={e => {
                            this.setState({
                                mode: 'previous-colour-notes'
                            });
                        }}
                        onUpdateClientRecordClicked={e => {
                            this.setState({
                                mode: 'client-record'
                            });
                        }}
                        onPreviousAppointmentsClicked={e => {
                            this.setState({
                                mode: 'previous-appointments'
                            });
                        }}
                        onRefundClicked={e => {
                            this.setState({
                                mode: 'refund'
                            });
                        }}
                        onBackClicked={e => {
                            this.returnToBrowseMode();
                        }}
                        onRefreshDiary={e => this.load(null, true)}
                        onUpdateAppointmentFields={(values) => this.updateAppointmentFields(this.appointmentID, values)}
                    />
                );
            case 'check-out':
            case 'add-deposit':
                if (!appointment) {
                    return (<Loader />);
                }
                return (
                    <CheckOut
                        ref={this.checkOutRef}
                        appointmentID={this.appointmentID}
                        date={diary.date}
                        services={services}
                        packages={packages}
                        isAddingDeposit={mode == 'add-deposit'}
                        goToPayment={mode == 'check-out' && this.goToPayment}
                        getServicePrice={this.getServicePrice}
                        step={this.checkOutStep}
                        onChange={(field, value) => this.updateAppointmentFields(this.appointmentID, { [field]: value })}
                        onSave={e => {
                            this.isChanged = false;
                            GlobalStateService.setValue('unsavedChanges', false);
                        }}
                        onBackClicked={async e => {
                            if (!(await this.confirmNavigateAway())) {
                                return;
                            }
                            this.goToPayment = false;
                            this.cancelAppointmentEdit();
                            this.load(null, true, true);
                        }}
                        onCheckoutComplete={() => {
                            this.goToPayment = false;
                            this.appointmentID = -1;
                            this.cancelAppointmentEdit();
                            this.load(null, true, true);
                        }}
                        onPreBookClicked={e => this.startAppointmentPreBook()}
                    />
                );

            case 'client-record':

                if (!appointment) {
                    return (<Loader />);
                }
                return (
                    <ClientRecord
                        ref={this.clientRecordRef}
                        appointmentID={this.appointmentID}
                        onBackClicked={e => {
                            this.setState({
                                mode: 'booking-menu'
                            });
                            this.isChanged = false;
                            GlobalStateService.setValue('unsavedChanges', false);
                        }}
                        onUpdateClientRecord={value => {
                            this.setState({
                                isClientRecordUpdated: value
                            });
                            this.isChanged = true;
                            GlobalStateService.setValue('unsavedChanges', true);
                        }}
                    />
                );
                break;
            case 'previous-appointments':

                return (
                    <PreviousAppointments
                        appointmentID={this.appointmentID}
                        appointment={appointment}
                        hideColourNotes
                        hideProducts

                        onBackClicked={e => {
                            this.setState({
                                mode: 'booking-menu'
                            });
                            this.selectDate({ date: this.appointmentDate });
                        }}
                        onAppointmentSelected={appt => this.selectDate({ date: appt.date })}
                    />
                );
                break;

            case 'previous-colour-notes':

                return (
                    <PreviousAppointments
                        appointmentID={this.appointmentID}
                        appointment={appointment}
                        hideApptNotes

                        onBackClicked={e => {
                            this.setState({
                                mode: 'booking-menu'
                            });
                            this.selectDate({ date: this.appointmentDate });
                        }}
                    />
                );
                break;

            case 'refund':
                return (
                    <Refund
                        appointment={appointment}
                        onCompleted={e => {
                            this.setState({
                                mode: 'booking-menu'
                            });
                        }}
                        onBackClicked={e => {
                            this.setState({
                                mode: 'booking-menu'
                            });
                            this.selectDate({ date: this.appointmentDate });
                        }}
                    />
                );
                break;

            case 'retail-menu':
                return (
                    <RetailMenu
                        date={this.state.diary.date}
                        onBackClicked={e => {
                            this.setState({
                                mode: 'browse'
                            }, () => {
                                window.scrollTo(0, 0);
                            })
                        }}
                        onQuotationClicked={async () => {
                            await this.loadBookingInfo();
                            this.setState({ mode: 'quotation' })
                        }}
                        onWaitingApptClicked={async () => {
                            await this.loadBookingInfo();
                            await this.setState({ waitingApptID: 0 })
                            this.setState({ mode: 'waiting-appt-editor' })
                        }}
                    />
                );
            case 'quotation':
                return (
                    <Quotation
                        stylists={stylists}
                        stylistsLookup={this.stylistsLookup}
                        serviceCategories={serviceCategories}
                        services={services}
                        packages={packages}
                        getServicePrice={this.getServicePrice}
                        onBackClicked={e => this.setState({ mode: 'browse' })}
                    />
                );
            case 'waiting-appt-editor':
                return (
                    <WaitingApptEditor
                        waitingApptID={waitingApptID}
                        stylists={stylists}
                        stylistsLookup={this.stylistsLookup}
                        serviceCategories={serviceCategories}
                        services={services}
                        packages={packages}
                        getServicePrice={this.getServicePrice}
                        onBackClicked={e => this.setState({ mode: 'retail-menu' })}
                    />
                );
            case 'browse':
            case 'change-date':
            case 'pre-book':
            default:
                return (
                    <DiaryBrowser
                        mode={mode}
                        diary={diary}
                        stylists={stylists}
                        date={this.state.diary.date}
                        waitingApptID={waitingApptID}
                        onChange={value => {
                            //if (value.date) {
                            //    value.date = DateHelpers.convertToUTC(value.date);
                            //}
                            this.selectDate(value);
                        }}
                        onNewBookingClicked={e => {
                            this.setState({ waitingApptID: 0 })
                            this.newAppointment(null)
                        }}
                        onCancelChangeDateClicked={this.cancelDateChange}
                        onCancelPreBookClicked={this.cancelPreBook}
                        onRetailAreaClicked={this.goToRetailArea}
                    />
                );
        }
    }

    render() {
        const { mode } = this.state;

        setTimeout(() => {
            ReactTooltip.rebuild();
        }, 0);

        return (
            <div className="page-content">
                <div className="page-content-left">
                    {this.renderSideContent()}
                </div>
                <div className={'page-content-right ' + (mode == 'retail-menu' ? 'desktop-only' : '')}>
                    <div className="page-content-right-inner">
                        {this.renderInfoBar()}
                        {this.renderMainContent()}
                    </div>
                </div>
            </div>
        );
    }

};

export default withRouter(DiaryPage);