// Libs
import React from 'react';
import { withRouter } from 'react-router-dom';

// Services & Helpers
import TagService from 'services/TagService';
import DiaryService from 'services/DiaryService';
import FormHelper from 'helpers/FormHelper';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar from 'components/layout/InfoBar'
import Loader from 'components/reusable/Loader';

//-------------------------------------------------------------------------------------------------------------------

class SettingsTagEdit extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            tag: null
        };

        this.updateField = this.updateField.bind(this);

        const fields = {
            name: { label: 'Name', validation: { isRequired: true } },
            shortName: { label: 'Short Name', maxLength : '4', validation: { isRequired: true }  },
            showOnReports: { label: 'Show on reports', type: 'checkbox' },
            backgroundColourID: {
                label: 'Background colour',
                type: 'single-select',
                validation: { isRequired: true },
                getOptions: () => this.state.backgroundColours,
                getOptionStyle: (provided, info) => ({
                    ...provided,
                    borderLeft: '30px solid ' + info.data.colour
                }),
                getSingleValueStyle: (provided, info) => ({
                    ...provided,
                    borderLeft: '30px solid ' + info.data.colour,
                    paddingLeft: 10
                })
            },
            type: {
                label: 'Type',
                type: 'single-select',
                getOptions: () => [
                    { id: 0, name: 'Appointment' }
                ]
            }
        };
        fields.newNumRotas = { ...fields.numRotas };
        this.formHelper = new FormHelper({
            fields: fields,
            getValue: (fieldName, fieldInfo) => this.state.tag[fieldName],
            setValue: this.updateField,
            onValidate: (validation) => this.setState({ validation: validation })
        });
    }

    componentDidMount() {
        this.load();
    }

    //--------------------------------------------------------------------------------------------------------------------

    async load() {
        const tagID = Number(this.props.match.params.tagID) || 0;

        let tag;
        if (tagID) {
            const getTagResponse = await TagService.get(tagID);
            tag = getTagResponse;
        } else {
            // New
            tag = {
                showOnReports: true,
                type: 0
            };
        }

        // Load other entities        
        const backgroundColours = await DiaryService.listColours();       

        // Update UI
        this.setState({
            isLoading: false,
            tag,
            backgroundColours
        });
    }

    goBack() {
        this.props.history.push('/settings/tags');
    }

    async save() {
        const {
            validation,
            tag
        } = this.state;

        if (validation.isValid) {
            this.setState({ isLoading: true });
            try {
                await TagService.save(tag);
                this.goBack();
            } catch (e) {
                BootboxHelper.alert(e);
                this.setState({ isLoading: false });
            }
        } else {
            BootboxHelper.alert('Please correct the errors on the form first:<br/>&nbsp;' + this.formHelper.getValidationSummaryHTML());
        }
    }

    updateField(fieldName, value, fieldInfo) {
        const tag = { ...this.state.tag };
        if (fieldName == 'shortName') {
            value = (value || '').toUpperCase();
            value = value.replace(/[^a-z0-9\\-]/ig, '');
        }

        tag[fieldName] = value;
        this.setState({
            tag
        });
    }

    async confirmDelete() {
        const confirm = await BootboxHelper.confirm('Are you sure you want to delete this tag?');
        if (confirm) {
            this.delete();
        }
    }

    async delete() {
        await TagService.delete(this.state.tag.tagID);
        this.props.history.replace('/settings/tags');
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        const {
            tag
        } = this.state;

        return (
            <InfoBar className="settings-info-bar" containerClassName="floating-on-mobile" sticky={true}>

                <div className="info-bar-panel-section">

                    <button className="button" onClick={() => this.goBack()}>
                        <span className="fa fa-chevron-left"></span>{' '}
                        Back
                    </button>

                </div>

                <div className="info-bar-panel-section info-bar-panel-section-text desktop-only">
                    {tag.tagID ? (tag.shortName || <>&nbsp;</>) : 'New tag'}
                </div>

                <div className="info-bar-panel-section ml-auto">

                    {tag.tagID &&
                        <button className="button" style={{ marginRight: 10 }} onClick={() => this.confirmDelete()}>
                            <span className="fa fa-times"></span>{' '}
                            Delete
                        </button>
                    }

                    <button className="button" onClick={() => this.save()}>
                        <span className="fa fa-check"></span>{' '}
                        Save
                    </button>

                </div>

            </InfoBar>
        );
    }

    render() {
        const {
            isLoading,
            validation
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>
            {this.renderInfoBar()}

            <div className="settings-page-main">
                <div className="panel">

                    <div className="panel-body">
                        <fieldset>
                            {this.formHelper.renderFormGroups([
                                'name',
                                'shortName',
                                'backgroundColourID',
                                'showOnReports'
                            ], validation)}                           
                        </fieldset>                       
                    </div>
                </div>
            </div>
        </>);
    }

};

export default withRouter(SettingsTagEdit);