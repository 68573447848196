// Libs
import React from 'react';
import NumberFormat from 'react-number-format';

// Services & Helpers
import GlobalStateService from 'services/GlobalStateService';
import RetailService from 'services/RetailService';
import UserService from 'services/UserService';
import ProductService from 'services/ProductService';
import SalonService from 'services/SalonService';
import LoyaltySchemeService from 'services/LoyaltySchemeService';
import SearchService from 'services/SearchService';
import ThermalPrinterService from 'services/ThermalPrinterService';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import Loader from 'components/reusable/Loader';
import Search from 'components/reusable/Search';
import Money from 'components/reusable/Money';
import CustomerSelector from './CustomerSelector';
import BootboxHelper from 'helpers/BootboxHelper';

//-------------------------------------------------------------------------------------------------------------------

class RetailPurchasePanel extends FloomlyComponent {

	constructor(props) {
		super(props);

		this.searchRef = React.createRef();

		this.toggleEditingDiscount = this.toggleEditingDiscount.bind(this);

		this.loginDetails = GlobalStateService.getValue('loginDetails');

		let retailPurchase = props.retailPurchase;
		if (!retailPurchase) {
			retailPurchase = {
				saleUserID: this.loginDetails.user.userID,
				issuedByUserID: this.loginDetails.user.userID,
                stockItems: [],
                discountAmount: 0,
                discountReason: null,
                discountTotal: 0,
                subTotal: 0,
                discountTotal: 0,
                total: 0,
                emailReceipt: false,
                printReceipt: false,
				type: 'retail',
            };
		}

		this.state = {
			isLoading: true,
			retailPurchase,
			customer: props.customer,
			isAddingStockItem: false,
			isEditingDiscount: false,
		};

		if (this.props.onRetailPurchaseChanged) {
			this.props.onRetailPurchaseChanged(retailPurchase);
		}
	}

	componentDidMount() {
		this.load();
	}

	async load() {
		const loyaltyScheme = await LoyaltySchemeService.get();
		const users = await UserService.list();

		this.setState({
			loyaltyScheme,
			users,
			isLoading: false
		});

	}

	static canGoNext(retailPurchase) {
		return retailPurchase?.stockItems?.length ?? 0 > 0;
	}

	next() {
		if (!RetailPurchasePanel.canGoNext(this.state.retailPurchase)) {
			return;
		}
		
		this.props.onGoFwd(this.state.retailPurchase);
	}
	
	static canDelete(retailPurchase) {
		return true;// check for permission
	}

	async delete() {
		const {
			retailPurchase
		} = this.state;

        const confirm = await BootboxHelper.confirm('Are you sure you want to delete this ?');
		
        if (confirm) {
			this.setState({
				isLoading: true,
				mode: 'retail-purchase-complete',
				step: 1
			});
			
            // Do the delete
			const result = await RetailService.delete(retailPurchase.id);
			if (result) {
				this.props.onGoBack();
			}
		}
	}

	//--------------------------------------------------------------------------------------------------------------------
	// Retail purchase
	//--------------------------------------------------------------------------------------------------------------------

	setRetailPurchase(retailPurchase) {
		this.setState({ retailPurchase });
	}

	async selectRPStockItem(stockItem) {
		// Get more info about stock item
		stockItem = await ProductService.getStockItem(stockItem.id);

		// Add product
		const { retailPurchase } = this.state; 
		const stockItems = [...retailPurchase.stockItems];
		stockItems.push({
			...stockItem,
			stockItemID: stockItem.stockItemID,
			total: (stockItem.sellPrice || ''),
			isDiscounted: !!retailPurchase.discountType
		});
		
		this.updateRPFields({ stockItems });
	}

	updateRPTotals(retailPurchase) {
		retailPurchase.subTotal = 0;
		retailPurchase.total = 0;

		// Calculate purchase total
		retailPurchase.stockItems.forEach(si => {
			retailPurchase.subTotal += Number(si.total) || 0;
		});

		// Add discount
		retailPurchase.discountTotal = 0;
		switch (retailPurchase.discountType) {
			case 'amount':
				retailPurchase.discountTotal = -retailPurchase.discountAmount;
				break;
			case 'percentage':
				retailPurchase.stockItems.forEach(si => {
					if (si.isDiscounted) {
						retailPurchase.discountTotal -= Number(si.total * (retailPurchase.discountAmount || 0) / 100) || 0;
					}
				});
				break;
		}

		retailPurchase.total = retailPurchase.subTotal + retailPurchase.discountTotal;
	}

	async updateStockItemField(index, field, value) {
		const stockItems = [...this.state.retailPurchase.stockItems];
		stockItems[index][field] = value;
		this.updateRPFields({
			stockItems
		});
	}

	confirmRemoveStockItem(index) {
		// TODO show confirmation box
		this.removeStockItem(index);
	}

	removeStockItem(index) {
		const stockItems = [...this.state.retailPurchase.stockItems]
		stockItems.splice(index, 1);
		this.updateRPFields({
			stockItems
		});
	}

	toggleEditingDiscount() {
		this.setState({
			isEditingDiscount: !this.state.isEditingDiscount
		})

		if (!this.state.retailPurchase.discountType) {
            this.updateRPFields({
                discountType: 'percentage'
            });
        }
	}

	updateRPFields(values) {
		const retailPurchase = { ...this.state.retailPurchase };
		for (var field in values) {
			let value = values[field];
			switch (field) {
				case 'discountType':
					if (value === 'percentage' || value === 'amount') {
						if (retailPurchase.stockItems.every(si => !si.isDiscounted)) {
							retailPurchase.stockItems.forEach(si => si.isDiscounted = true);
						}
					} else if (!value) {
						retailPurchase.discountTotal = 0;
						retailPurchase.discountAmount = 0;
						retailPurchase.discountReason = null;
					}
					break;
				
				case 'discountAmount':
					if (value < 0) {
						value = -value;
					}
					break;
			}
			retailPurchase[field] = value;
		}

		this.updateRPTotals(retailPurchase);
		this.setState({ retailPurchase });
		if (this.props.onRetailPurchaseChanged) {
			this.props.onRetailPurchaseChanged(retailPurchase);
		}
	}

	async printRPReceipt(id) {
		const { isPrintingReceipt } = this.state;
		if (isPrintingReceipt) return;
		this.setState({ isPrintingReceipt: true });
		await ThermalPrinterService.printRetailPurchaseReceiptWithPDFFallback(id);
		this.setState({ isPrintingReceipt: false });
	}

	async saveRP() {
		const {
			retailPurchase
		} = this.state;

		this.setState({ isLoading: true });

		// Save
		const id = await RetailService.save(retailPurchase);

		// Print
		if (retailPurchase.printReceipt) {
			this.printRPReceipt(id);
		}

		// Update UI
		this.setState({
			isLoading: false,
			mode: 'retail-purchase-complete',
			step: 1
		});

		// Automatically go back
		window.setTimeout(() => {
			if (this.isUnmounted) return;
			this.props.onGoBack();
		}, 3 * 1000);
	}

	//--------------------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------------------

	customerSelected(customer) {
		this.updateRPFields({
			customerID: customer ? customer.id : null,
			customer
		});
		this.setState({
			customer,
			isAddingStockItem: this.state.retailPurchase.stockItems.length === 0,
		});
		this.props.onCustomerSelected(customer);
	}

	//--------------------------------------------------------------------------------------------------------------------
	// Render
	//--------------------------------------------------------------------------------------------------------------------

    renderRPDiscount() {
        const {
            isEditingDiscount,
            retailPurchase
        } = this.state;
        const currency = SalonService.getCurrentCurrency();

        return (<>
            <li className="non-selectable">

                <span className="service-list-icon">
                    <span className="fas fa-arrow-down"></span>
                </span>

                <span className="service-list-name">
                    Discount
                </span>

                <span className="service-list-icon">

                    <button className="button" onClick={this.toggleEditingDiscount}>
                        <span className={'fa ' + (isEditingDiscount ? 'fa-arrow-left' : 'fa-pencil-alt')}></span>
                    </button>

                </span>

                <span className="service-list-price">

                    <Money amount={-retailPurchase.discountTotal} />

                </span>

            </li>

            {isEditingDiscount && <>
                <li className="non-selectable discount-row-1">

                    <span className="service-list-icon">

                    </span>

                    <span className="service-list-name">

                        <select value={retailPurchase.discountType || ''} onChange={e => this.updateRPFields({ discountType: e.target.value })}>
                            <option value="">(Select)</option>
                            <option value="amount">Fixed</option>
                            <option value="percentage">Percent</option>
                        </select>
                    </span>

					<span className="service-list-price">
						{retailPurchase.discountType === 'amount' &&
                            <NumberFormat
                                thousandSeparator={currency.thousandSeparator}
                                decimalSeparator={currency.decimalSeparator}
								prefix={currency.symbol}
                                placeholder={currency.symbol}
                                className="has-prefix"
                                value={retailPurchase.discountAmount || ''}
                                onValueChange={e => {
                                    this.updateRPFields({ discountAmount: e.value });
                                }}
                            />
                        }
						{retailPurchase.discountType === 'percentage' &&
                            <NumberFormat
                                thousandSeparator={currency.thousandSeparator}
                                decimalSeparator={currency.decimalSeparator}
								suffix="%"
                                className="has-suffix"
                                placeholder="%"
                                value={retailPurchase.discountAmount || ''}
                                onValueChange={e => {
                                    this.updateRPFields({ discountAmount: e.value });
                                }}
                            />
                        }
					</span>

                </li>

				{(retailPurchase.discountType === 'percentage' || retailPurchase.discountType === 'amount') && retailPurchase.stockItems.map((stockItem, index) =>
                    <li key={index} className="non-selectable discount-row-1">
                        <span className="service-list-icon"></span>

                        <span className="service-list-name">
                            {stockItem.product.name}
                        </span>

                        <span className="service-list-price">
                            <input type="checkbox" checked={stockItem.isDiscounted} onChange={e => this.updateStockItemField(index, 'isDiscounted', e.target.checked)} />
                        </span>
                    </li>
                )}

                <li className="non-selectable discount-row-2">

                    <span className="service-list-icon">

                    </span>

                    <span className="service-list-name">

                        <input
                            type="text"
                            value={retailPurchase.discountReason || ''}
                            onChange={e => this.updateRPFields({ discountReason: e.target.value })}
                            placeholder="Reason for discount"
                        />

                    </span>

                </li>

            </>}
        </>);
    }

    renderRPLoyaltyScheme() {
        const {
            loyaltyScheme,
            retailPurchase,
            customer
        } = this.state;

        if (!loyaltyScheme || !loyaltyScheme.isActive) {
            return null;
        }

		const pointsEarned = LoyaltySchemeService.getPointsEarned(loyaltyScheme, null, retailPurchase.stockItems, retailPurchase.discountTotal);
        if (pointsEarned <= 0) {
            return null;
        }

        return (
            <div className="panel check-out-loyalty-scheme-panel">
                <div className="panel-header">
					Loyalty scheme
                </div>
                <div className="panel-body">
					{/*DISC TOTAL: {retailPurchase.discountTotal} <hr/>*/}
                    {customer ? <>
                        {customer.name} will earn up to <span>{pointsEarned}</span>{' '}
                        loyalty point{pointsEarned == 1 ? '' : 's'} for this purchase.
                    </> : <>
                        There is no customer selected. They are missing out on <span>{pointsEarned}</span>{' '}
                        loyalty point{pointsEarned == 1 ? '' : 's'}!
                    </>}
                </div>
            </div>
        );
    }

	render() {
		const {
			retailPurchase,
			isAddingStockItem,
			users
		} = this.state;

		if (!users) {
			return (<Loader />);
		}

		setTimeout(() => {
			this.autoFocusProductIndex = -1;
		}, 0);

		return (<>

			{!this.props.isStandalone && 
			<button className="button button-secondary button-small" onClick={_ => this.props.onGoBack()}>
				<span className="fas fa-arrow-left"></span>{' '}
				Back
			</button>}

			<CustomerSelector 
				customer={this.state.customer}
				autoFocus={true}
				onCustomerSelected={customer => this.customerSelected(customer)}
				isFor={this.props.isRefund}
				/>

			<div className="panel retail-purchase">

				<div className="panel-body">

					<div className="service-list-container">

						<ul className="list service-list">
							{retailPurchase.stockItems.map((stockItem, index) =>
								<li key={index} className="non-selectable">

									<span className="service-list-icon">
										<span className="fas fa-cash-register"></span>
									</span>

									<span className="service-list-name">
										{stockItem.product.name}
									</span>

									<span className="service-list-price">

										<input
											type="number"
											value={stockItem.total}
											onChange={e => this.updateStockItemField(index, 'total', Number(e.target.value) || '')}
										/>

									</span>

									<span className="floating-controls">

										<button tabIndex="-1" className="button customer-summary-change-button" onClick={e => this.confirmRemoveStockItem(index)}>
											<span className="fa fa-times"></span>
										</button>

									</span>

								</li>
							)}
						</ul>

						{isAddingStockItem &&
							<div className="product-search search-box-absolute">
								<Search
									ref={this.searchRef}
									className="search-box"
									autoFocus={true}
									placeholder="Search for a product..."
									onBlur={e => this.setState({ isAddingStockItem: false })}
									search={async (query, setResults, maxResults, state) => {
										const results = await SearchService.search(query, ['StockItem'], { filters: { stockUsage: 'Retail' }, maxResults, state });
										setResults(results);
										if (results.length === 1 && results[0].stockBarcodes === query) {
											this.selectRPStockItem(results[0]);
											this.searchRef.current.clearSearch();
											this.searchRef.current.focus();
										}
									}}
									renderResult={(result, index, info) =>
										<div key={index} className="search-result list-item" onMouseDown={e => {
											this.selectRPStockItem(result);
											info.clearSearch();
											info.focus();
										}}>
											{/*
                                            <div className="list-item-name">
                                                {result.name}
                                            </div>
                                            <div className="list-item-price">
                                                <Money amount={result.sellPrice || ''} />
                                            </div>
                                            */}
											<div className="list-item-name" style={{ flex: 1 }}>
												{result.name}
												<div style={{ display: 'flex' }}>
													{!!result.size && result.size != '0' ? result.size : null}
													<div className="list-item-price">
														<Money amount={Number(result.sellPrice) || 0} />
													</div>
												</div>
											</div>
										</div>
									}
								/>
							</div>
						}

						{!isAddingStockItem &&
							<button className="button button-tertiary search-box-button" onClick={e => this.setState({ isAddingStockItem: true })}>
								<span className="fa fa-plus"></span>{' '}
								Add a product
							</button>
						}

					</div>

					{this.props.isRefund && <>
						<label>Originally sold by</label>
						<select className='single-select'
							value={retailPurchase.originallySoldByUserID}
							onChange={e => this.updateRPFields({
								originallySoldByUserID: Number(e.target.value) || null
							})}
						>
							<option value="">(Select)</option>
							{users.map(u =>
								<option key={u.userID} value={u.userID}>
									{u.nickname}
								</option>
							)}
						</select>
					</>}

					{!this.props.isRefund && <>
						<label>Sold by</label>
						<select className='single-select'
							value={this.props.isRefund ? retailPurchase.issuedByUserID : retailPurchase.saleUserID}
							onChange={e => this.updateRPFields({
								saleUserID: Number(e.target.value) || null,
								issuedByUserID: Number(e.target.value) || null
							})}
						>
							<option value="">(Select)</option>
							{users.map(u =>
								<option key={u.userID} value={u.userID}>
									{u.nickname}
								</option>
							)}
						</select>
					</>}

					<ul className="list service-list">

						{/* Sub-Total */}
						<li className="non-selectable">

							<span className="service-list-icon">
								<span className="fas fa-cash-register"></span>
							</span>

							<span className="service-list-name">
								Sub-Total
							</span>

							<span className="service-list-price">
								<Money amount={retailPurchase.subTotal} />
							</span>

						</li>

						{this.renderRPDiscount()}

						{/* Total */}
						<li className="non-selectable total-row line-above">

							<span className="service-list-icon">
								<span className="fas fa-money-bill"></span>
							</span>

							<span className="service-list-name">
								{this.props.isRefund ? 'Total refund' : 'Total' }
							</span>

							<span className="service-list-price">
								<Money amount={retailPurchase.total} />
							</span>

						</li>

					</ul>

				</div>

			</div>

			{!this.props.isRefund && this.renderRPLoyaltyScheme()}

			{!this.props.isStandalone && 
			<div className="button-stack">

				<button className={"button button-primary button-small" + (retailPurchase.stockItems.length > 0 ? "" : " disabled")} onClick={_ => this.next()}>
					{this.props.isRefund ? 'Continue refund ' : 'Continue '}
					<i className="fas fa-arrow-right"></i>
				</button>

				<button className="button button-tertiary button-small" onClick={_ => this.props.onGoBack()}>
					<i className="fas fa-arrow-left"></i>{' '}
					{this.props.isRefund ? "Cancel refund" : "Cancel purchase"}
				</button>

			</div>}
		</>);

	}
}

export default RetailPurchasePanel;