// Libs
import React from 'react';
import { withRouter } from 'react-router-dom';
import * as $ from 'jquery';

// Services & Helpers
import DiaryService       from 'services/DiaryService';
import FormHelper         from 'helpers/FormHelper';
import GlobalStateService from 'services/GlobalStateService';
import ThermalPrinterService from 'services/ThermalPrinterService';

// Components
import FloomlyComponent       from 'components/FloomlyComponent';
import Loader             from 'components/reusable/Loader';
import CustomerSummary    from 'components/reusable/CustomerSummary';
import AppointmentSummary from 'components/reusable/AppointmentSummary';

//-------------------------------------------------------------------------------------------------------------------

class BookingMenu extends FloomlyComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isPrintingReceipt: false,
            appointment: null,
            areOtherAppointmentsLoading: true,
            allAppointments: null,
            nextAppointment: null,
        };

        this.viewCustomer = this.viewCustomer.bind(this);
        this.printReceipt = this.printReceipt.bind(this);
        this.updateField = this.updateField.bind(this);
        this.saveAppointmentNotes = this.saveAppointmentNotes.bind(this);

        this.formHelper = new FormHelper({
            fields: {
                notes: {
                    type: 'multiline-text',
                    rows: 4
                }
            },
            getValue: (fieldName) => {
                return this.state.appointment[fieldName];
            },
            setValue: this.updateField,
            onBlur: this.saveAppointmentNotes
        });
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.appointmentID != prevProps.appointmentID) {
            this.load();
        }
    }

    updateField(fieldName, value, fieldInfo) {
        const appointment = { ...this.state.appointment };
        appointment[fieldName] = value;
        this.setState({ appointment: appointment });
    }
    
    async saveAppointmentNotes(fieldName, value) {
        const appointment = { ...this.state.appointment };
        appointment[fieldName] = value;
        await this.props.onUpdateAppointmentFields({
            [fieldName]: value
        });
        DiaryService.saveNotes(appointment);
        //this.props.onRefreshDiary();
    }

    async load() {
        setTimeout(() => {
            $('.rbc-event').removeClass('highlighted');
            $('[data-appointment-id=' + this.props.appointmentID + ']').closest('.rbc-event').addClass('highlighted');
        }, 0);

        // Load this appointment
        this.setState({
            isLoading: true,
            areOtherAppointmentsLoading: true
        });
        const appointment = await DiaryService.loadAppointmentSummary(this.props.appointmentID);
        this.setStateAsync({
            isLoading: false,
            appointment
        });

        // Then load next & previous appointments
        if (appointment.customer) {
            const nextAppointments = await DiaryService.loadNextApointments(appointment.customer.customerID, appointment.date, 1);
            const nextAppointment = (nextAppointments.length > 0 ? nextAppointments[0] : null);
            const allAppointments = await DiaryService.loadAllAppointments(appointment.customer.customerID);
            var appointmentIndex = allAppointments.findIndex(appt => (appt.appointmentID == appointment.appointmentID)) - 1;
            if (appointmentIndex == -1) {
                appointmentIndex = 0;
            }

            // Update UI again
            this.setState({
                areOtherAppointmentsLoading: false,
                allAppointments,
                appointmentIndex,
                nextAppointment
            });
        } else {
            this.setState({
                areOtherAppointmentsLoading: false
            });
        }
    }

    navigatePreviousAppt(delta) {
        const {
            allAppointments,
            appointmentIndex
        } = this.state;
        if (appointmentIndex + delta < allAppointments.length && appointmentIndex + delta >= 0) {
            this.setState({
                appointmentIndex: appointmentIndex + delta
            });
        }
    }

    renderOtherAppointments() {
        const {
            areOtherAppointmentsLoading,
            allAppointments,
            appointmentIndex,
            nextAppointment
        } = this.state;

        if (areOtherAppointmentsLoading) {
            return (<Loader />);
        }

        const indexedAppointment = (allAppointments && allAppointments.length > 0 ? allAppointments[appointmentIndex] : null);

        return (<>

            {!!allAppointments &&
                <div className="panel">
                    <div className="panel-body previous-appointment-panel">
                        <div className="panel-header">
                            <button className="button" disabled={appointmentIndex == 0} style={{ float: 'left' }} onClick={e => this.navigatePreviousAppt(-1)}>
                                <span className="fa fa-chevron-left"></span>
                            </button>
                            Notes
                            <button className="button" disabled={appointmentIndex >= allAppointments.length - 1} onClick={e => this.navigatePreviousAppt(1)}>
                                <span className="fa fa-chevron-right"></span>
                            </button>
                        </div>
                        <AppointmentSummary appointment={indexedAppointment} />
                    </div>
                </div>
            }

            {!!nextAppointment &&
                <div className="panel">
                    <div className="panel-body previous-appointment-panel">
                        <div className="panel-header">
                            Next appointment
                        </div>
                        <AppointmentSummary
                            appointment={nextAppointment}
                            hideColourNotes
                            hideApptNotes
                            hideProducts
                        />
                    </div>
                </div>
            }

            {/*
            {(!!previousAppointment || !!nextAppointment) && <>
                <button className="button button-primary button-small" onClick={this.props.onPreviousAppointmentsClicked}>
                    <span className="far fa-calendar-alt"></span>{' '}
                    Full List
                </button>
            </>}
            */}
        </>);
    }

    viewCustomer() {
        this.props.history.push('/customer/' + this.state.appointment.customer.customerID);
    }

    async printReceipt() {
        const { isPrintingReceipt, appointment } = this.state;
        if (isPrintingReceipt) return;
        this.setState({ isPrintingReceipt: true });
        await ThermalPrinterService.printApptReceiptWithPDFFallback(appointment.appointmentID);
        this.setState({ isPrintingReceipt: false });
    }

    render() {
        const {
            isLoading,
            isPrintingReceipt,
            appointment
        } = this.state;
        const loginDetails = GlobalStateService.getValue('loginDetails');

        if (isLoading) {
            return (<Loader />);
        }

        if (appointment.customer) {
            return (<>

                <CustomerSummary
                    customerID={appointment.customer ? appointment.customer.customerID : null}
                    onViewInfoClicked={this.viewCustomer}
                >
                    <AppointmentSummary appointment={appointment} isMini={true} />
                </CustomerSummary>

                <div className="button-stack">

                    {appointment.status == 'provisional' &&
                        <button className="button button-primary button-small" onClick={this.props.onConfirmClicked} disabled={!loginDetails.permissions['DiaryConfirmOnlineBookings']}>
                            <span className="fa fa-thumbs-up"></span>{' '}
                            Confirm Booking
                        </button>
                    }

                    {/*
                    <button className="button button-primary button-small" onClick={this.props.onPreviousColourNotesClicked}>
                        <span className="fa fa-clipboard-list"></span>{' '}
                        Previous Colour Notes
                    </button>
                    */}

                    {appointment.status != 'provisional' &&
                        <button className="button button-primary button-small" onClick={this.props.onCheckOutClicked} disabled={!loginDetails.permissions['DiaryCheckOutAppointments']}>
                            <span className="fa fa-check"></span>{' '}
                            Check Out
                        </button>
                    }

                    {appointment.status != 'checkedOut' &&
                        <button className="button button-primary button-small" onClick={this.props.onEditClicked} disabled={!loginDetails.permissions['DiaryEditAppointments']}>
                            <span className="fas fa-pencil-alt"></span>{' '}
                            Edit Services
                        </button>
                    }

                    {appointment.status != 'provisional' &&
                        <button className="button button-primary button-small" onClick={this.props.onUpdateClientRecordClicked} disabled={!loginDetails.permissions['DiaryUpdateClientRecord']}>
                            <span className="far fa-file-alt"></span>{' '}
                            Add Colour/Appt Notes
                        </button>
                    }

                    {appointment.status == 'checkedOut' && <>
                        <button className="button button-primary button-small" onClick={this.printReceipt}>
                            {!isPrintingReceipt && <>
                                <span className="fa fa-print"></span>{' '}
                                Print Receipt
                            </>}
                            {isPrintingReceipt && <>
                                <Loader isInline={true} />{' '}
                                Printing...
                            </>}
                        </button>

                        <button className="button button-primary button-small" onClick={this.props.onRefundClicked}>
                            <span className="far fa-sad-tear"></span>{' '}
                            Refund
                        </button>

                    </>}

                </div>

                {this.renderOtherAppointments()}

                <button className="button button-secondary button-small" onClick={this.props.onBackClicked}>
                    <span className="fas fa-arrow-left"></span>{' '}
                    Back to Diary
                </button>

            </>);
        } else {
            return (<>

                <div className="panel internal-appt-summary-panel">
                    <div className="panel-header">
                        {appointment.internalApptType.name}
                    </div>
                    <div className="panel-body">

                        <div className="panel-body-inner">

                            <AppointmentSummary appointment={appointment} isMini={true} />

                        </div>

                        <div className="panel-body-extras">

                            <div className="panel-info-header">Notes</div>

                            {this.formHelper.renderFormGroups([
                                'notes'
                            ])}

                        </div>

                    </div>

                </div>

                <button className="button button-secondary button-small" onClick={this.props.onBackClicked}>
                    <span className="fas fa-arrow-left"></span>{' '}
                    Back to Diary
                </button>

            </>);
        }
    }
}

export default withRouter(BookingMenu);