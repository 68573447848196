// Libs
import React from 'react';
import { withRouter } from 'react-router-dom';

// Services & Helpers
import TagService from 'services/TagService';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar      from 'components/layout/InfoBar'
import Loader       from 'components/reusable/Loader';
import SuperTable   from 'components/reusable/SuperTable';

//-------------------------------------------------------------------------------------------------------------------

class SettingsTagList extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            tags: []
        };
    }

    componentDidMount() {
        this.load();
    }

    //--------------------------------------------------------------------------------------------------------------------

    async load() {
        const tags = await TagService.list() ?? [];
        
        this.setState({
            isLoading: false, 
            tags
        });
    }

    edit(id) {
        this.props.history.push('/settings/tags/' + id);
    }

    add() {
        this.props.history.push('/settings/tags/new');
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        return (
            <InfoBar className="settings-info-bar" containerClassName="floating-on-mobile" sticky={true}>

                <div className="info-bar-panel-section info-bar-panel-section-text desktop-only">
                    Tags
                </div>
                <div className="info-bar-panel-section ml-auto">

                    <button className="button" onClick={() => this.add()}>
                        <span className="fa fa-plus"></span>{' '}
                        Add New
                    </button>

                </div>

            </InfoBar>
        );
    }

    render() {
        const {
            isLoading,
            tags
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>
            {this.renderInfoBar()}

            <div className="settings-page-main">
                <div className="panel">
                    <div className="panel-body">

                        <SuperTable
                            className="settings-table"
                            rows={tags}
                            keyAccessor={tag => tag.tagID}
                            cellPropsAccessor={(colInfo, tag, index) => {
                                if (colInfo.id == 'backgroundColour') {
                                    return {
                                        style: {
                                            width: 30,
                                            backgroundColor: (tag ? tag.backgroundColour : undefined)
                                        }
                                    };
                                }
                            }}
                            cols={{
                                backgroundColour: {
                                    getValue: () => null
                                },
                                name: { label: 'Name' },
                                shortName: { label: 'Short Name' }
                            }}
                            onClick={(tag, e) => this.edit(tag.tagID)}
                        />
                        
                    </div>
                </div>

            </div>

        </>);
    }

};

export default withRouter(SettingsTagList);