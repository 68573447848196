import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';

import Loader from 'components/reusable/Loader';
import FormHelper from 'helpers/FormHelper';
import TagService from 'services/TagService';

export default class AppointmentTagsModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        };
        this.updateField = this.updateField.bind(this);
        this.formHelper = new FormHelper({
            fields: {
                appointmentTags: {
                    label: 'Please select the appointment tags',
                    type: 'multi-select',
                    blankText: '(No tags)',
                    creatable: {
                        formatCreateLabel: (name) => 'Add tag: ' + name,
                        onCreateOption: (option) => {
                            return new Promise((resolve) => {
                                const apptTypeTags = [...this.state.apptTypeTags];
                                const newOption = {
                                    id: `new${apptTypeTags.length}-${option.name}`,
                                    name: option.name
                                };
                                apptTypeTags.push(newOption);
                                this.setState({
                                    apptTypeTags
                                }, () => {
                                    resolve(newOption);
                                });
                            });
                        }
                    },
                    getOptions: () => this.state.apptTypeTags.map(at => ({
                        id: at.tagID || at.id,
                        name: at.name
                    }))
                }
            },

            getValue: (fieldName, fieldInfo) => this.state.appointmentTags,
            setValue: this.updateField

        });
    }

    show(opts) {
        return new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.setState({
                isLoading: true,
                isOpen: true,
                appointmentID: opts.appointmentID
            }, () => {
                this.load();
            })
        })
    }

    hide() {
        this.setState({
            isOpen: false
        });
    }

    updateField(fieldName, value, fieldInfo) {
        let { appointmentTags } = this.state;
        appointmentTags = appointmentTags || [];
        appointmentTags = value;
        this.setState({
            appointmentTags
        })
    }

    async load() {
        const appointmentTags = await TagService.getApptTags(this.state.appointmentID);
        const apptTypeTags = await TagService.getApptTypeTags();
        this.setState({
            appointmentTags,
            apptTypeTags,
            isLoading: false
        });
    }

    async saveAndClose() {
        const { appointmentID, appointmentTags } = this.state;
        await TagService.saveApptTags({
            appointmentID,
            apptTagIDs : appointmentTags
        });
        this.setState({ isOpen: false });
        this.resolve();
    }


    render() {
        if (!this.state.isOpen) {
            return null;
        }


        return (<>
            
            <Modal
                show
                className="waiting-list-modal"
                onHide={e => this.hide()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit tags</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.renderInner()}
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="button button-primary mr-auto" onClick={e => this.hide()}>
                        Close
                    </button>

                    <button type="button" className="button button-primary ml-auto" onClick={e => this.saveAndClose()}>
                        Save
                    </button>
                </Modal.Footer>
            </Modal>
        </>);
    }

    renderInner() {
        const {
            isLoading,
            
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            {this.formHelper.renderFormGroups([
                'appointmentTags'
            ])}

        </>);
    }
}