// Libs
import React from 'react';
import { Link, withRouter } from 'react-router-dom';

// Services & Helpers
import ServiceService from 'services/ServiceService';
import CapabilityService from 'services/CapabilityService';
import ServiceCategoryService from 'services/ServiceCategoryService';
import TaxRateService from 'services/TaxRateService';
import BusinessCategoryService from 'services/BusinessCategoryService';
import ServiceBandService from 'services/ServiceBandService';
import FormHelper from 'helpers/FormHelper';
import TextHelpers from 'helpers/TextHelpers';
import LoyaltySchemeService from 'services/LoyaltySchemeService';
import SalonService from 'services/SalonService';
import BootboxHelper from 'helpers/BootboxHelper';
import DiaryService from 'services/DiaryService';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar from 'components/layout/InfoBar'
import Loader from 'components/reusable/Loader';
import SuperTable from 'components/reusable/SuperTable';
import NumberFormat from 'react-number-format';

//-------------------------------------------------------------------------------------------------------------------

class SettingsServiceEdit extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            service: null
        };

        this.updateField = this.updateField.bind(this);

        this.formHelper = new FormHelper({
            fields: {
                name: { label: 'Name' },
                translatedName: { label: 'Arabic Translation' },
                description: { label: 'Description (this will show on your Online Booking)', type: 'multiline-text' },
                price: { label: 'Standard Price', type: 'currency' },
                businessCategoryID: {
                    label: 'Business Category',
                    type: 'single-select',
                    blankText: '(None)',
                    getOptions: () => this.state.businessCategories.map(bc => ({
                        id: bc.businessCategoryID || bc.id,
                        name: bc.name
                    }))
                },
                serviceCategoryIDs: {
                    label: 'Categories',
                    type: 'multi-select',
                    blankText: '(No categories)',
                    creatable: {
                        formatCreateLabel: (name) => 'Add category: ' + name,
                        onCreateOption: (option) => {
                            return new Promise((resolve) => {
                                const serviceCategories = [...this.state.serviceCategories];
                                const newOption = {
                                    id: `new${serviceCategories.length}-${option.name}`,
                                    name: option.name
                                };
                                serviceCategories.push(newOption);
                                this.setState({
                                    serviceCategories
                                }, () => {
                                    resolve(newOption);
                                });
                            });
                        }
                    },
                    getOptions: () => this.state.serviceCategories.map(sc => ({
                        id: sc.serviceCategoryID || sc.id, // TODO fix this
                        name: sc.name
                    }))
                },
                taxRateID: {
                    label: 'Tax Rate',
                    type: 'single-select',
                    blankText: '(No Tax Rate)',
                    getOptions: () => this.state.taxRates
                },
                capabilityID: {
                    label: 'Capability',
                    type: 'single-select',
                    blankText: '(None)',
                    getOptions: () => this.state.capabilities.map(c => ({
                        id: c.capabilityID,
                        name: c.name
                    }))
                },
                behaviourType: {
                    label: 'Behaviour',
                    type: 'single-select',
                    blankText: '(None)',
                    getOptions: () => [
                        { id: 'cut', name: 'Cut' },
                        { id: 'colour', name: 'Colour' },
                        { id: 'treatment', name: 'Treatment' },
                        { id: 'cutColour', name: 'Cut & Colour' },
                        { id: 'cutTreatment', name: 'Cut & Treatment' },
                        { id: 'colourTreatment', name: 'Colour & Treatment' },
                        { id: 'cutColourTreatment', name: 'Cut, Colour & Treatment' },
                        { id: 'toner', name: 'Toner' },
                        { id: 'perming', name: 'Perming' },
                        { id: 'straightening', name: 'Straightening' },
                        { id: 'consultation', name: 'Consultation' },
                        { id: 'patchTest', name: 'Patch Test' }
                    ]
                },
                isPriceOnConsultation: { label: 'Price on consultation', type: 'checkbox' },
                requiresPatchTest: { label: 'Patch test needed', type: 'checkbox' },
                numLoyaltyPointsAccrued: { label: 'Override number of loyalty points', type: 'number' },
                ignoreAverageBill: { label: 'Not counted towards average bill', type: 'checkbox' },
                isAvailableOnlineBooking: { label: 'Available for Online Booking', type: 'checkbox' },
                isPackageOnly: { label: 'Service is only available as part of a package', type: 'checkbox' },
                isAddedAutomatically: { label: 'Add this service automatically during checkout', type: 'checkbox' },
                takeDeposit: { label: 'Take deposit when booked online', type: 'checkbox' },
                overrideDeposit: { label: 'Override deposit amount', type: 'currency' },
                mustPayUpfront: { label: 'Must pay whole amount upfront', type: 'checkbox' }
            },
            getValue: (fieldName, fieldInfo) => this.state.service[fieldName],
            setValue: this.updateField
        });
    }

    componentDidMount() {
        this.load();
    }

    //--------------------------------------------------------------------------------------------------------------------

    async load() {
        const serviceID = Number(this.props.match.params.serviceID) || 0;
        const loyaltyScheme = await LoyaltySchemeService.get();
        const businessCategories = await BusinessCategoryService.list();

        const getDiarySettingsResponse = await DiaryService.getSettings();
        const diarySettings = getDiarySettingsResponse;

        if (!diarySettings.salonPeriod) {
            diarySettings.salonPeriod = {
                diaryInterval: 15
            };
        }

        let service, detailedInfo;
        if (serviceID) {
            const getServiceResponse = await ServiceService.get(serviceID);
            service = getServiceResponse;

            // Load detailed info
            detailedInfo = await ServiceService.getDetailedInfo(serviceID);

        } else {
            const taxRates = await TaxRateService.list();
            const defaultTaxRate = taxRates.find(tr => tr.isDefaultServices);
            service = {
                durationMins: diarySettings.salonPeriod.diaryInterval,
                isAvailableOnlineBooking: true,
                taxRateID: (defaultTaxRate ? defaultTaxRate.id : null)
            };

            // Get default business category
            const defaultBusinessCategory = businessCategories.find(bc => bc.isDefaultForServices);
            if (defaultBusinessCategory) {
                service.businessCategoryID = defaultBusinessCategory.businessCategoryID;
            }

            // Create lookups for stylist and service band
            detailedInfo = {
                byServiceBand: []
            };
            const serviceBands = await ServiceBandService.list();
            serviceBands.forEach(sb => {
                detailedInfo.byServiceBand.push({
                    serviceBandID: sb.serviceBandID,
                    name: sb.name,
                    price: null,
                    durationMins: null
                });
            });
        }

        // Add detailed info to service
        service.byServiceBand = {};
        detailedInfo.byServiceBand.forEach(sb => {
            service.byServiceBand[sb.serviceBandID] = sb;
        });

        // Load related entities
        const serviceCategories = await ServiceCategoryService.listSummary();
        const taxRates = await TaxRateService.list();
        const capabilities = await CapabilityService.list();

        // Update UI
        this.setState({
            isLoading: false,
            service,
            detailedInfo,
            serviceCategories,
            businessCategories,
            taxRates,
            capabilities,
            loyaltyScheme,
            diarySettings
        });
    }

    goBack() {
        this.props.history.push('/settings/services');
    }

    async save() {
        this.setState({ isLoading: true });
        const service = { ...this.state.service };
        try {
            await ServiceService.save(service);
            this.goBack();
        } catch (error) {
            BootboxHelper.alert(error);
        } finally {
            this.setState({ isLoading: false });
        }
    }

    async confirmDelete() {
        const confirm = await BootboxHelper.confirm('Are you sure you want to delete this service?');
        if (confirm) {
            this.delete();
        }
    }

    async delete() {
        try {
            await ServiceService.delete(this.state.service.serviceID);
            this.props.history.replace('/settings/services');
        } catch (e) {
            BootboxHelper.alert(e);
        }
    }

    updateField(fieldName, value, fieldInfo) {
        const service = { ...this.state.service };
        const diarySettings = this.state.diarySettings.salonPeriod;

        switch (fieldName) {
            case 'behaviourType':
                service.mustBeFirst = false;
                service.noCooldownIfOtherColour = false;
                service.requiresPatchTest = false;

                switch (value) {
                    case 'colour':
                    case 'toner':
                        service.mustBeFirst = true;
                        service.noCooldownIfOtherColour = true;
                        service.requiresPatchTest = true;
                        break;
                }
                break;
            case 'businessCategoryID':
                if (value) {
                    const businessCategory = this.state.businessCategories.find(bc => bc.businessCategoryID == value);
                    if (businessCategory && businessCategory.defaultTaxRate) {
                        service.taxRateID = businessCategory.defaultTaxRate.id;
                    }
                }
                break;
            case 'durationMins': {
                if (value <= 0) {
                    value = 5;//diarySettings.diaryInterval
                }
                break;
            }
            case 'cooldownPeriodMins':
                if (value < 0) {
                    value = 0;
                }
                break;
        }
        service[fieldName] = value;
        this.setState({
            service: service
        });
    }

    updateDetailedInfo(type, id, field, value) {
        const service = { ...this.state.service };
        service[type] = { ...service[type] };
        if (field == 'price') {
            value = (value === '' ? null : Number(value));
        }
        else if (field == 'durationMins') {
            if (value < 0) return;
        }
        service[type][id][field] = value;
        this.setState({
            service: service
        });
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        const {
            service
        } = this.state;

        return (
            <InfoBar className="settings-info-bar" containerClassName="floating-on-mobile" sticky={true}>

                <div className="info-bar-panel-section">

                    <button className="button" onClick={() => this.goBack()}>
                        <span className="fa fa-chevron-left"></span>{' '}
                        Back
                    </button>

                </div>

                <div className="info-bar-panel-section info-bar-panel-section-text desktop-only">
                    {service.serviceID ? service.name : 'New service'}
                </div>

                <div className="info-bar-panel-section ml-auto" style={{ whiteSpace: 'nowrap' }}>

                    {service.serviceID &&
                        <button className="button" style={{ marginRight: 10 }} onClick={() => this.confirmDelete()}>
                            <span className="fa fa-times"></span>{' '}
                            Delete
                        </button>
                    }

                    <button className="button" onClick={() => this.save()}>
                        <span className="fa fa-check"></span>{' '}
                        Save
                    </button>

                </div>

            </InfoBar>
        );
    }

    renderPricingByServiceBand() {
        const {
            service,
            detailedInfo,
            diarySettings
        } = this.state;

        if (service.isPriceOnConsultation) {
            return null;
        }

        const currency = SalonService.getCurrentCurrency();

        return (

            <SuperTable
                className="settings-table"
                rows={detailedInfo.byServiceBand}
                keyAccessor={serviceBand => serviceBand.serviceBandID}
                cols={{
                    name: { label: 'Service Band' },
                    price: {
                        label: 'Price',
                        renderCell: (colInfo, serviceBand) => <td style={{ width: '140px' }}>
                            <NumberFormat
                                thousandSeparator={currency.thousandSeparator}
                                decimalSeparator={currency.decimalSeparator}
                                prefix={currency.symbol}
                                className="has-prefix"
                                placeholder={'Standard Price (' + TextHelpers.formatCurrencyNew(service.price, { includeSymbol: true }) + ')'}
                                value={
                                    service.byServiceBand[serviceBand.serviceBandID].price === 0 ? '0' :
                                        (service.byServiceBand[serviceBand.serviceBandID].price || '')
                                }
                                onValueChange={(values) => this.updateDetailedInfo('byServiceBand', serviceBand.serviceBandID, 'price', values.value)}
                            />
                        </td>
                    },
                    durationMins: {
                        label: 'Duration',
                        //step: diarySettings.salonPeriod.diaryInterval,
                        step:"5",
                        min: "0",
                        getValue: (colInfo, serviceBand) =>
                            <>
                                <button type="button" className="button" onClick={e => this.updateDetailedInfo('byServiceBand', serviceBand.serviceBandID, 'durationMins', (service.byServiceBand[serviceBand.serviceBandID].durationMins || 0) - Number(colInfo.col.step))}>
                                    <span className="fa fa-angles-left"></span>
                                </button>
                                <input
                                    type="number"
                                    step={diarySettings.salonPeriod.diaryInterval}
                                    min="0"
                                    className="duration-mins-column"
                                    disabled
                                    value={serviceBand.durationMins || ''}
                                    onChange={e => this.updateDetailedInfo('byServiceBand', serviceBand.serviceBandID, 'durationMins', e.target.value)}
                                />
                                <button type="button" className="button" onClick={e => this.updateDetailedInfo('byServiceBand', serviceBand.serviceBandID, 'durationMins', (service.byServiceBand[serviceBand.serviceBandID].durationMins || 0) + Number(colInfo.col.step))}>
                                    <span className="fa fa-angles-right"></span>
                                </button>
                            </>

                    },
                }}
            />

        );
    }

    render() {
        const {
            isLoading,
            service,
            loyaltyScheme,
            diarySettings
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>
            {this.renderInfoBar()}

            <div className="settings-page-main">
                <div className="panel">
                    <div className="panel-body">

                        {this.formHelper.renderFormGroups([
                            'name',
                        ])}
                        {diarySettings.timeZoneLocation == "Asia/Dubai" && <>
                            {this.formHelper.renderFormGroups([
                                'translatedName',
                            ])}
                        </>}
                        {this.formHelper.renderFormGroups([
                            'description'
                        ])}
                        {/*Will increase by diary Interval in the future from 5 to (diarySettings.salonPeriod.diaryInterval) */}
                        <div className="form-group">
                            <label>Standard duration</label>
                            <button type="button" className="button" onClick={e => this.updateField('durationMins', (service.durationMins || 0) - (5))}>
                                <span className="fa fa-angles-left"></span>
                            </button>
                            <input
                                type="number"
                                step="5"
                                min="0"
                                className="duration-text"
                                disabled
                                value={service.durationMins || ''}
                            />
                            <button type="button" className="button" onClick={e => this.updateField('durationMins', (service.durationMins || 0) + (5))}>
                                <span className="fa fa-angles-right"></span>
                            </button>
                        </div>
                        <div className="form-group">
                            <label>Development time</label>
                            <button type="button" className="button" onClick={e => this.updateField('cooldownPeriodMins', (service.cooldownPeriodMins || 0) - (5))}>
                                <span className="fa fa-angles-left"></span>
                            </button>
                            <input
                                type="number"
                                step="5"
                                min="0"
                                className="duration-text"
                                disabled
                                value={service.cooldownPeriodMins || ''}
                            />
                            <button type="button" className="button" onClick={e => this.updateField('cooldownPeriodMins', (service.cooldownPeriodMins || 0) + (5))}>
                                <span className="fa fa-angles-right"></span>
                            </button>
                        </div>
                        {this.formHelper.renderFormGroups([
                            'businessCategoryID',
                            'taxRateID',
                            'serviceCategoryIDs',
                            'behaviourType',
                            'capabilityID',
                            'isPriceOnConsultation',
                            (!service.isPriceOnConsultation && 'price'),
                            'requiresPatchTest',
                            'ignoreAverageBill',
                            (loyaltyScheme && 'numLoyaltyPointsAccrued'),
                            'isPackageOnly',
                            'isAddedAutomatically',
                            'isAvailableOnlineBooking',
                            (service.isAvailableOnlineBooking && 'takeDeposit'),
                            (service.isAvailableOnlineBooking && service.takeDeposit && !service.isPriceOnConsultation &&
                                'mustPayUpfront'
                            ),
                            (service.isAvailableOnlineBooking && service.takeDeposit && (!service.mustPayUpfront || service.isPriceOnConsultation) &&
                                'overrideDeposit'
                            )
                        ])}

                        {this.renderPricingByServiceBand()}

                    </div>
                </div>

            </div>

        </>);
    }

};

export default withRouter(SettingsServiceEdit);