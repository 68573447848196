// Libs
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import NumberFormat from 'react-number-format';

// Services & Helpers
import CustomerService from 'services/CustomerService';
import SalonService from 'services/SalonService';
import DiaryService from 'services/DiaryService';
import GlobalStateService from 'services/GlobalStateService';
import EvoService from 'services/EvoService';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import Loader from 'components/reusable/Loader';
import Money from 'components/reusable/Money';
import DatePicker from 'components/reusable/DatePicker';
import PosTerminalPanel from 'components/pages/diary/checkout/PosTerminalPanel'; 

//-------------------------------------------------------------------------------------------------------------------

export default class CustomerAccountHistoryModal extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            step : 'Adding transaction'
        };
    }

    show(opts) {
        return new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.onUpdate = opts.onUpdate;
            this.opts = opts;
            this.setState({
                isOpen: true,
                isLoading: true,
                customerAcctTrans: null,
                isAdding: false,
                customerID: opts.customerID
            }, () => {
                this.cancelNewTransaction();
                this.load();
            });
        });
    }
    
    async hide() {
        var confirm = true;
        if (this.state.isAdding) {
            confirm = await BootboxHelper.confirm('If you navigate away, the changes you have made will be lost. Is that ok ?')
        }
        if (confirm) {
            this.setState({
                isOpen: false
            });
        }
    }

    async load() {
        const { customerID } = this.state;
        const customer = await CustomerService.getSummary(customerID);
        const customerAcctTrans = await CustomerService.listAccountTransactions(customerID);
        this.clientInfo = GlobalStateService.getValue('clientInfo');
        let paymentMethods = await DiaryService.loadPaymentMethods();
        const cardPM = paymentMethods.find(pm => pm.code == 'Card');
        let savedCard = await CustomerService.getSavedCard(customerID) || null;
        if (savedCard) {
            savedCard = savedCard.paymentProvider == this.clientInfo.paymentProvider && savedCard.consentToSaveCard ? savedCard : null;
        }

        paymentMethods = paymentMethods.filter(pm => {
            switch (pm.code) {
                case 'OnAccount':
                case 'Account':
                case 'Voucher':
                case 'LoyaltyPoints':
                    return false;
            }
            return true;
        });
        this.setState({
            isLoading: false,
            paymentMethods,
            customer,
            customerAcctTrans,
            paymentProvider: this.clientInfo.paymentProvider,
            savedCard,
            cardPM
        });
    }

    updateNewTransactionField(fieldName, value) {
        const newTransaction = { ...this.state.newTransaction };
        newTransaction[fieldName] = value;
        this.setState({
            newTransaction
        });
    }

    cancelNewTransaction() {
        const now = moment();
        const datePart = now.format('YYYY-MM-DD');
        const hoursPart = now.format('HH');
        let minsPart = Math.floor(now.format('mm') / 5) * 5;
        if (minsPart < 10) minsPart = `0${minsPart}`
        const dateTime = moment(`${datePart}T${hoursPart}:${minsPart}`).toDate();

        this.setState({
            newTransaction: {
                customerID: this.state.customerID,
                date: dateTime,
                description: '',
                isNegative: false,
                amount: ''
            },
            isAdding: false
        });
    }

    async saveNewTransaction() {
        const { newTransaction, cardPM, paymentProvider, step, savedCard } = this.state;
        newTransaction.savedCard = savedCard;

        if (step == 'Terminal payment') {
            if (newTransaction.paymentDeviceId <= 0) {
                BootboxHelper.alert('Please make sure to select the payment device.');
                return;
            }

            let response = await DiaryService.sendToCardMachine(newTransaction.amount, newTransaction);
            BootboxHelper.alert('Please wait while we process your payment <br/> Please do not refresh or close this screen');

            this.setState({ isLoading: true });
            this.checkTransactionCompleteInterval = setInterval(() => {
                this.checkForTransactionDetails(response.TransactionId, response.PaymentDeviceId);
            }, 1000);

            //check if anything needs to be completed on Pos Device
            this.checkIfPosPendingTransactionTimeout = setTimeout(function () {
                BootboxHelper.alert('Pos is busy. Please complete the transaction on the terminal');
            }, 50000);

            this.checkIfPosIncompleteTransactionTimeout = setTimeout(() => {
                this.checkForIncompleteTransaction(response.TransactionId, response.PaymentDeviceId);
            }, 100000);
        }

        const isTerminalPayment = newTransaction.amount >= 0 && newTransaction.paymentMethodID == cardPM.paymentMethodID && !newTransaction.isSavedCard && !newTransaction.txnReference;

        if (isTerminalPayment && paymentProvider == 'evo') {
            this.setState({ step: 'Terminal payment' });
        } else {
            try {
                this.setState({ isLoading: true });
                await CustomerService.addAccountTransaction(this.state.newTransaction);
                this.load();
                this.cancelNewTransaction();
                //this.hide();
                if (this.onUpdate) {
                    this.onUpdate();
                }
            }
            catch (error) {
                BootboxHelper.alert(error);
                this.setState({ isLoading: false });
                return;
            }
        }
    }

    async confirmDelete(id) {
        const confirm = await BootboxHelper.confirm('Are you sure you want to delete this account transaction? This will affect the customer\'s overall account balance.');
        if (confirm) {
            this.setState({ isLoading: true });

            // Do the delete and reload
            await CustomerService.deleteAccountTransaction(id);
            this.load();
            if (this.onUpdate) {
                this.onUpdate();
            }
        }
    }

    updateButtonText() {
        const { newTransaction, savedCard, paymentProvider, cardPM } = this.state;
        const isTerminalPayment = !newTransaction.isSavedCard;
        if (cardPM && savedCard) {
            if (!isTerminalPayment)
                return 'Process payment';
            else if (paymentProvider == 'evo')
                return 'Send to card machine';
            else {
                return 'Save';
            }
        }
        else if (cardPM && isTerminalPayment && paymentProvider == 'evo') {
            return 'Send to card machine';
        }
        else {
            return 'Save';
        }
    }

    canContinue() {
        if (this.state.newTransaction.paymentDeviceId > 0)
            return true;
        else
            return false;
    }

    canSave() {
        if (Number(this.state.newTransaction.amount) > 0) {
            return true;
        }
        else {
            return false;
        }
    }

    goBack() {
        this.setState({
            step: 'Adding transaction'
        });
    }

    async checkForTransactionDetails(transactionId, paymentDeviceId) {
        const transactionResponse = await DiaryService.getTransactionDetails(transactionId, paymentDeviceId);
        if (transactionResponse == EvoService.RESPONSE_CANCELLED || transactionResponse == EvoService.RESPONSE_REFUSED || transactionResponse == EvoService.RESPONSE_SUCCESS) {
            clearTimeout(this.checkIfPosPendingTransactionTimeout);
            clearInterval(this.checkTransactionCompleteInterval);
            clearTimeout(this.checkIfPosIncompleteTransactionTimeout);
            if (transactionResponse == EvoService.RESPONSE_SUCCESS) {
                let newTransaction = this.state.newTransaction;
                if (!newTransaction.txnReference) {
                newTransaction.txnReference = transactionId;
                this.setState({
                    step: 0,
                    isLoading: false
                });
                this.saveNewTransaction();
            }
            }
            else if (transactionResponse == EvoService.RESPONSE_CANCELLED) {
                BootboxHelper.alert("Card transaction cancelled.Please try again.");
                this.setState({
                    step: 'Adding transaction',
                    isLoading: false
                });
            }
            else if (transactionResponse == EvoService.RESPONSE_REFUSED) {
                BootboxHelper.alert("Card payment declined. Please try with other payment method.");
                this.setState({
                    step: 'Adding transaction',
                    isLoading: false
                });
            }
        }
    }

    async checkForIncompleteTransaction(transactionId, paymentDeviceId) {
        const transactionResponse = await DiaryService.getTransactionDetails(transactionId, paymentDeviceId);
        if (transactionResponse == 'Fetching Details') {
            BootboxHelper.alert('Please make sure to select the correct payment device.');
            clearTimeout(this.checkIfPosPendingTransactionTimeout);
            clearInterval(this.checkTransactionCompleteInterval);
            clearTimeout(this.checkIfPosIncompleteTransactionTimeout);
            this.goBack();
        }
    }

    render() {
        const {
            isOpen,
            isLoading
        } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal
                className="customer-account-history-modal"
                show={isOpen}
                onHide={e => this.hide()}
            >
                {isLoading ?
                    <Modal.Body><Loader /></Modal.Body> :
                    this.renderInner()
                }
            </Modal>
        );
    }

    renderInner() {
        const {
            customer,
            customerAcctTrans,
            newTransaction,
            paymentMethods,
            isAdding,
            savedCard,
            step
        } = this.state;
        const currency = SalonService.getCurrentCurrency();
        const paymentMethod = paymentMethods.find(pm => pm.paymentMethodID == newTransaction.paymentMethodID);

        return (<>
            <Modal.Header closeButton>
                <Modal.Title>
                    Account History

                    {customer.accountBalance != 0 &&
                        <div className="modal-subtitle">
                            Account Balance: <Money amount={customer.accountBalance} />
                        </div>
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!isAdding &&
                    <button type="button" className="button button-primary button-small" style={{ marginBottom: 25 }} onClick={() => this.setState({ isAdding: true })}>
                        Add a new transaction
                    </button>
                }



                <ul className={`fv2-thing-list ${isAdding ? '' : 'border-on-bottom'} mb-3`}>

                    {isAdding && step == 'Adding transaction' &&
                        <li className="thing">

                            <div className="thing-side" style={{ width: '200px' }}>


                                <div className="thing-detail">

                                    <select
                                        value={newTransaction.isNegative? 'Out' : 'In'}
                                        onChange={e => this.updateNewTransactionField('isNegative', e.target.value == 'Out')}
                                    >
                                        <option value="In">In</option>
                                        <option value="Out">Out</option>
                                    </select>

                                </div>

                                <div className={`thing-icon bordered fa-solid ${newTransaction.isNegative ? 'fa-arrow-left red' : 'fa-arrow-right green'}`} />

                            </div>

                            <div className="thing-main">

                                <div className="mb-1">

                                    <DatePicker
                                        selected={newTransaction.date || ''}
                                        onChange={value => this.updateNewTransactionField('date', value)}
                                        showTimeSelect
                                        timeIntervals={5}
                                        dateFormat="dd/MM/yyyy hh:mm aa"
                                    />

                                </div>

                                <input
                                    className="w-100 mb-1"
                                    type="text"
                                    value={newTransaction.description}
                                    placeholder="Description"
                                    onChange={e => this.updateNewTransactionField('description', e.target.value)}
                                />

                                <NumberFormat
                                    thousandSeparator={currency.thousandSeparator}
                                    decimalSeparator={currency.decimalSeparator}
                                    prefix={currency.symbol}
                                    className="has-prefix w-100 mb-1"
                                    placeholder="Amount"
                                    value={newTransaction.amount != 0 ? Math.abs(newTransaction.amount) : ''}
                                    onValueChange={(values) => {
                                        const val = Math.abs(Number(values.value));
                                        if (val != Math.abs(newTransaction.amount)) {
                                            this.updateNewTransactionField('amount', val);
                                        }
                                    }}
                                />

                                <select
                                    value={newTransaction.paymentMethodID || ''}
                                    onChange={e => this.updateNewTransactionField('paymentMethodID', e.target.value)}
                                >
                                    <option value="">(Select)</option>
                                    {paymentMethods.map(pm =>
                                        <option key={pm.paymentMethodID} value={pm.paymentMethodID}>
                                            {pm.shortName}
                                        </option>
                                    )}
                                </select>
                                {paymentMethod && paymentMethod.code === 'Card' && savedCard &&
                                    <div class="mb-1" style={{ marginLeft: 10 }}>
                                        <span className="service-list-name">
                                            <input
                                                id="saved-card"
                                                class="saved-card"
                                            type="checkbox"
                                            checked={newTransaction.isSavedCard}
                                            onChange={e => this.updateNewTransactionField('isSavedCard', !newTransaction.isSavedCard)}
                                            />
                                            <span><label htmlFor="saved-card">Charge Card ending in {savedCard.last4Digits}</label></span>
                                        </span>
                                        </div>
                                }
                                <div class="thing-main">
                                    <button className={'button button-primary button-small ms-1 ' + (this.canSave() ? '' : 'disabled')} style={{ marginTop: 10 }} onClick={e => this.saveNewTransaction()}>
                                    {this.updateButtonText()}
                                    </button>
                                </div>

                            </div>

                            

                            <div className="thing-buttons-icons">

                                <button type="button" className="button button-primary" onClick={() => this.cancelNewTransaction()}>
                                    <span className="fa fa-times"></span>
                                </button>

                            </div>

                        </li>
                    }
                    {step == 'Terminal payment' && <> {this.renderPosTerminal()}</>}
                    {customerAcctTrans.map(cat =>
                        <li className="thing" key={cat.id}>

                            <div className="thing-side">

                                <div className={`thing-icon bordered ${cat.amount > 0 ? 'green' : 'red'} fa-solid ${cat.amount > 0 ? 'fa-arrow-right' : 'fa-arrow-left'}`} />

                                <div className="thing-detail">

                                    {cat.amount > 0 ? 'In' : 'Out'}

                                </div>

                            </div>

                            <div className="thing-main">

                                {!!cat.date &&
                                    <div className="thing-detail heading">
                                        {moment(cat.date).format('DD/MM/YYYY HH:mm')}
                                    </div>
                                }

                                {!!cat.description &&
                                    <div className="thing-detail">
                                        {cat.description}
                                    </div>
                                }

                                <div className="thing-detail">

                                    <Money amount={Math.abs(cat.amount)} />
                                    {!!cat.paymentMethodName && <> by </>}
                                    {cat.paymentMethodName}

                                </div>

                                {!!cat.appointmentID &&
                                    <div className="thing-detail">

                                        <Link className="button button-secondary button-small" to={'/diary/' + moment(cat.date).format('YYYY-MM-DD')}>
                                            View in Diary
                                        </Link>

                                    </div>
                                }

                            </div>

                            <div className="thing-buttons-icons">

                                <button type="button" className="button button-primary" onClick={() => this.confirmDelete(cat.id)}>
                                    <span className="fa fa-times"></span>
                                </button>

                            </div>
                            
                        </li>
                    )}
                </ul>

               

            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-primary" onClick={e => this.hide()}>
                    Close
                </button>
            </Modal.Footer>
        </>);
    }

    renderPosTerminal() {
        let {
            newTransaction
        } = this.state;
        return (<>
            <PosTerminalPanel
                checkOut={newTransaction}
                onCheckOutChanged={checkOut => this.setState({ newTransaction: checkOut })}
                onChange={(name, value) => { }}
                isCustomerAcctTransaction={true}
            />
            <div className="button-stack">
                <button className={'button button-primary button-small ' + (this.canContinue() ? '' : 'disabled')} style={{ marginTop: 10}} onClick={e => this.saveNewTransaction()}>
                    <i className="fas fa-check"></i>{' '}
                    Send to payment device
                </button>

                <button className="button button-tertiary button-small" style={{ marginTop: 10, float : 'right' }} onClick={e => this.goBack()}>
                    <i className="fas fa-arrow-left"></i>{' '}
                    Back
                </button>

            </div>
        </>)
    }
}
