// Libs
import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';

// Services & Helpers
import TaxRateService from 'services/TaxRateService';
import CompanyService from 'services/CompanyService';
import FormHelper from 'helpers/FormHelper';
import BootboxHelper from 'helpers/BootboxHelper';
import GlobalStateService from 'services/GlobalStateService';

// Components
import Loader from 'components/reusable/Loader';

//-------------------------------------------------------------------------------------------------------------------

export default class ProductSettingsModal extends React.Component {

    constructor(props) {
        super(props);

        this.loginDetails = GlobalStateService.getValue('loginDetails');
        this.canEdit = this.loginDetails.permissions['StockroomEditStockManager'];

        this.updateField = this.updateField.bind(this);

        this.state = {
            isLoading: true
        };

        this.formHelper = new FormHelper({
            fields: {
                translatedName: {
                    label: 'Arabic Translation',
                    type: 'text',
                    value: this.state.translatedName,
                    isDisabled: !this.canEdit
                },
                taxRateID: {
                    label: 'Tax Rate',
                    type: 'single-select',
                    blankText: '(Select)',
                    getOptions: () => this.state.taxRates,
                    isDisabled: !this.canEdit
                },
                supplierCompanyID: {
                    label: 'Supplier',
                    type: 'single-select',
                    blankText: '(Select)',
                    getOptions: () => this.state.companies,
                    isDisabled: !this.canEdit
                },
                packSize: {
                    label: 'Pack Size',
                    type: 'text',
                    value: this.state.packSize,
                    isDisabled: !this.canEdit
                },
                description: {
                    label: 'Description',
                    type: 'multiline-text',
                    isDisabled: !this.canEdit
                }
            },
            getValue: (fieldName, fieldInfo) => this.state.product[fieldName],
            setValue: this.updateField
        });
    }

    show(opts) {
        return new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.setState({
                isOpen: true,
                isLoading: true,
                product: { ...opts.product },
                stockItem: { ...opts.stockItem }
            }, () => {
                this.load();
            });
        });
    }

    hide() {
        this.setState({
            isOpen: false
        });
    }

    async load() {
        const taxRates = await TaxRateService.list(this.state.product.taxRateID);
        const companies = await CompanyService.list();
        const packSize = this.state.stockItem.packSize;
        const translatedName = this.state.product.translatedName;
        this.state.product["packSize"] = packSize;

        const client = GlobalStateService.getValue('clientInfo');
        const timezone = client.timeZoneLocation;

        this.setState({
            isLoading: false,
            taxRates,
            companies,
            packSize,
            translatedName,
            timezone
        });
    }

    saveAndClose() {
        this.hide();
        this.resolve({
            action: 'save',
            product: this.state.product
        });
    }

    updateField(fieldName, value, fieldInfo) {
        const product = { ...this.state.product };
        const stockItem = { ...this.state.stockItem };
        if (fieldName == 'packSize') {
            stockItem[fieldName] = value;
            this.setState({
                stockItem
            });
        }
        product[fieldName] = value;
        this.setState({ product, stockItem });
    }

    async confirmDelete() {
        const confirm = await BootboxHelper.confirm('Are you sure you want to delete this product?');
        if (confirm) {
            this.hide();
            this.resolve({
                action: 'delete'
            });
        }
    }

    //----------------------------------------------------------------------------

    render() {
        const {
            isOpen,
            isLoading
        } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal
                className="product-settings-modal"
                show={isOpen}
                onHide={e => this.hide()}
            >
                {isLoading ?
                    <Modal.Body><Loader /></Modal.Body> :
                    this.renderInner()
                }
            </Modal>
        );
    }

    renderInner() {
        const {
            product,
            timezone
        } = this.state;

        return (<>
            <Modal.Header closeButton>
                <Modal.Title>
                    {product.name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {timezone == "Asia/Dubai" && <>
                    {this.formHelper.renderFormGroups([
                        'translatedName',
                    ])}
                </>}
                {this.formHelper.renderFormGroups([
                    'taxRateID',
                    'supplierCompanyID',
                    'packSize',
                    'description',
                ])}

            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="button button-secondary" onClick={e => this.hide()}>
                    Close
                </button>
                <button type="button" className="button button-tertiary mr-auto ml-auto" disabled={!this.canEdit} onClick={e => this.confirmDelete()}>
                    Delete
                </button>
                <button type="button" className="button button-primary" disabled={!this.canEdit} onClick={e => this.saveAndClose()}>
                    Save
                </button>
            </Modal.Footer>
        </>);
    }

}
